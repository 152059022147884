import { UserCountryEnum } from '../../utils/enums';
import { otherClaimIdHandler } from '../../utils/helper';
import { TPatient } from '../../utils/model';

export const otmConfig = () => ({
  editPatientProfileInfo_otm: true,
  inviteOrAddHcp_otm: true,
  editOrPrescribeExerciseVideos_otm: true,
  addVideosToTeamVideoBank_otm: true,
  createTeam_otm: false,
});
export const hcpConfig = () => ({
  editPatientProfileInfo_hcp: true,
  inviteOrAddHcp_hcp: true,
  editOrPrescribeExerciseVideos_hcp: true,
  addVideosToTeamVideoBank_hcp: true,
});

export const additionalFieldsAdmin = () => [
  { key: 'otm', otm: Object.keys(otmConfig()) },
  { key: 'hcp', hcp: Object.keys(hcpConfig()) },
];

export const initValuesUSProfile = (patientCountry: string, patient: TPatient): object => {
  if (patientCountry === UserCountryEnum.US) {
    return {
      typeOfClaim: patient?.patientInsurance?.claim
        ? {
            value: patient?.patientInsurance.claim.id,
            label: patient?.patientInsurance.claim.name,
          }
        : '',
      insuredIdNumber: patient?.patientInsurance?.insuredIdNumber || '',
      policyGroup: patient?.patientInsurance?.policyGroup || '',
      otherClaimId: otherClaimIdHandler(patient?.patientInsurance?.otherClaimId) || '',
      insurancePlanName: patient?.patientInsurance?.insurancePlanName || '',
    };
  }
  return {};
};
