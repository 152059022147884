import React, { ReactElement } from 'react';
import { Pagination } from 'antd';
import { useFormik } from 'formik';

import styles from './styles.module.css';
import { Button, Modal } from '../../../common';
import { spinner } from '../../../common/Loader';
import useChat from '../../../hooks/useChat';
import { CHANNEL_TYPE } from '../../../utils/enums';
import { CloseBtn } from '../../../common/Button/Button';
import SearchClient from '../../Search/SearchClient';
import { CustomCheckbox } from '../../../common/Input';
import { ListHcpType } from '../../../pages/Messenger/types';
import { useSocket } from '../../../ SocketContext';

const SearchHcpForm = ({
  t,
  _oncloseAddNewChannelModal,
  search,
  changeSearch,
  list,
  listLoading,
  current,
  pageSize,
  total,
  onChangePagination,
  type,
  activeRoomId,
}: any): ReactElement => {
  const select = t?.common.select;
  const search_placeholder = t?.modal.search_placeholder;
  const not_found = t?.modal.not_found;
  const skip_for_now = t?.chat?.skip_for_now || 'Skip for now';
  const search_hcp = t?.chat?.search_hcp || 'Search Healthcare Professionals';

  const { socket }: any = useSocket();
  const { createRoom } = useChat(socket);

  const initialValues: any = {
    channelName: '',
    checkedCandidat: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (requestData: any) => {
      let channelName = '';
      if (type === CHANNEL_TYPE.GROUP) {
        channelName = requestData.channelName;
      } else {
        channelName = `${requestData.checkedCandidat.firstName} ${requestData.checkedCandidat.lastName}`;
      }
      const request = {
        userId: requestData.checkedCandidat.id,
        name: channelName,
        type,
      };
      createRoom(request, activeRoomId);
    },
  });
  const { values } = formik;

  // Selecting user to connect
  const onUpdateCheckbox = (e: any, candidat: any): void => {
    const { checked } = e.target;
    if (checked) {
      formik.setFieldValue('checkedCandidat', candidat);
      return;
    }
    formik.setFieldValue('checkedCandidat', null);
  };

  return (
    <Modal onClose={_oncloseAddNewChannelModal} style={styles.modal}>
      {/* Header */}
      <div className={styles['msg__modal-header']}>
        <CloseBtn close={_oncloseAddNewChannelModal} />
        <div className={styles['msg__modal-title']}>{search_hcp}</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        {/* SEARCH */}
        <SearchClient
          inputId="searchMember"
          name="search"
          placeholder={search_placeholder}
          inputValue={search}
          changeFilter={changeSearch}
        />

        {/* List */}
        {listLoading && spinner}
        <ul className={styles['msg__modal-list_container']}>
          {search && !listLoading && list.length === 0 && (
            <div className={styles.notfound}>
              <div>{not_found}</div>
            </div>
          )}
          {search &&
            !listLoading &&
            list.length > 0 &&
            list.map((item: ListHcpType, i: number) => (
              <li className={styles.listHcp} key={`list${item.id}`}>
                <div className={styles.listHcp__checkbox}>
                  <CustomCheckbox
                    htmlId={`member${i}`}
                    name="checkbox"
                    checked={values.checkedCandidat?.id === item.id}
                    onChangeMethod={(e: any): void => onUpdateCheckbox(e, item)}
                    isTouched={false}
                    hasErrors={false}
                  />
                </div>
                <div className={styles.listHcp_content}>
                  <div className={styles.fullName__container}>
                    <div className={styles.hcpName}>{`${item.firstName}, ${item.lastName}`}</div>
                    <div className={styles.hcpProfession}>{item.profession}</div>
                  </div>
                  <div className={styles.hcpHospitalName}>{item.hospital}</div>
                </div>
              </li>
            ))}
        </ul>

        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={current}
            pageSize={pageSize}
            total={total}
            onChange={onChangePagination}
          />
        </div>
        <Button
          buttonType="submit"
          buttonName={select}
          disabledButton={!formik.dirty || !values.checkedCandidat}
          buttonClass={styles.btn}
        />
        <div onClick={_oncloseAddNewChannelModal} aria-hidden className={styles.btn__skip}>
          {skip_for_now}
        </div>
      </form>
    </Modal>
  );
};

export default SearchHcpForm;
