import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from '../styles.module.css';
import { GetState, getCurrenLang, getHcpName, getMessengerState } from '../../../../redux/selector';
import { SendIcon } from '../../../../theme/icons';
import useChat from '../../../../hooks/useChat';
import { MessengerState } from '../../../../redux/messenger/types';
import { useSocket } from '../../../../ SocketContext';
// import useStore from 'hooks/useStore';
// import { nanoid } from 'nanoid';
// import { FiSend } from 'react-icons/fi';
// import EmojiMart from './EmojiMart/EmojiMart';
// import FileInput from './FileInput/FileInput';
// import Recorder from './Recorder/Recorder';

const MessageInput = ({ dataQa }: any) => {
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const write_message = t?.chat?.write_message || 'Write message';
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoomId } = messengerState;
  // const state = useStore((state) => state);
  // const { file, setFile, showPreview, setShowPreview, showEmoji, setShowEmoji } = state;
  const [text, setText] = useState('');
  // const [submitDisabled, setSubmitDisabled] = useState(true);
  // const inputRef: any = useRef();
  const { socket }: any = useSocket();
  const { sendMessage } = useChat(socket);
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));
  const { firstName, lastName, id: hcpId, email } = currentUser;
  // const textareaRef: any = useRef();
  // useEffect(() => {
  //   setSubmitDisabled(!text.trim() && !file);
  // }, [text, file]);
  // useEffect(() => {
  //   setShowPreview(file);
  // }, [file, setShowPreview]);
  const _onSubmit = (): void => {
    const trimText = text ? text.trim() : text;
    if (!trimText) return;
    const message: any = {
      roomId: activeRoomId,
      hcpId,
      userName: `${firstName} ${lastName}`,
      text: trimText,
    };
    sendMessage(message);
    // if (showEmoji) {
    //   setShowEmoji(false);
    // }
    setText('');
    // setFile(null);
  };
  // Onsubmit
  document.onkeyup = (event: any): void => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      _onSubmit();
    }
  };
  // Handler for text input
  const handleChange = (e: any): void => {
    // if (textareaRef.current) {
    //   textareaRef.current.style.height = 'auto';
    //   textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    // }
    setText(e.target.value);
  };
  return (
    <form className={styles.msgInput__form}>
      {/* <EmojiMart setText={setText} messageInput={inputRef.current} />
      <FileInput />
      <Recorder /> */}
      <textarea
        // ref={textareaRef}
        id="Send message"
        name="SendMessage"
        value={text}
        placeholder={`${write_message}...`}
        onChange={handleChange}
        className={styles.msgInput__textarea}
        data-qa={dataQa}
      />
      <div
        className={cx({
          [styles.msgInput__sendIcon]: true,
          [styles['msgInput__sendIcon--disabled']]: !text,
        })}
        onClick={_onSubmit}
        aria-hidden
        // ref={inputRef}
      >
        <SendIcon />
      </div>
    </form>
  );
};
export default MessageInput;
