import React from 'react';
import { Modal } from '../Modal';
import styles from './styles.module.css';

interface PopupProps {
  title: string;
  content: string;
  buttonName: string;
  onClosePopup: () => void;
}
interface PopupWithTwoButtonsProps {
  title: string;
  content: string;
  confirmButtonName: string;
  closeButtonName: string;
  onConfirm: () => void;
  onClosePopup: () => void;
}

export const Popup = ({
  title,
  content,
  buttonName,
  onClosePopup,
}: PopupProps): JSX.Element => (
  <Modal onClose={onClosePopup} style={styles.popup__container}>
    <div className={styles['popup__content-box']}>
      <div className={styles.popup__title}>{title}</div>
      {content ? (
        <div className={styles.popup__content}>
          {content}
        </div>
      )
        : (<></>)}
    </div>
    <div
      onClick={onClosePopup}
      className={styles.popup__btn}
      role="presentation"
    >
      {buttonName}
    </div>
  </Modal>
);

export const PopupWithTwoButtons = ({
  title,
  content,
  confirmButtonName,
  closeButtonName,
  onConfirm,
  onClosePopup,
}: PopupWithTwoButtonsProps): JSX.Element => (
  <Modal onClose={onClosePopup} style={styles.popup__container}>
    <div className={styles['popup__content-box']}>
      <div className={styles.popup__title}>{title}</div>
      {content ? (
        <div className={styles.popup__content}>
          {content}
        </div>
      )
        : (<></>)}
    </div>
    <div
      onClick={onConfirm}
      className={styles.popup__btn}
      role="presentation"
    >
      {confirmButtonName}
    </div>
    <div
      onClick={onClosePopup}
      className={styles.popup__btn}
      role="presentation"
    >
      {closeButtonName}
    </div>
  </Modal>
);
