import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';

// Query
const queryGetAllHelpSubjects = loader('./gql/queryGetAllHelpSubjects.graphql');

export const useGetSubjects = () => {
  const { loading, error, data } = useQuery(queryGetAllHelpSubjects);

  const allHelpSubjects = data ? data.getAllHelpSubjects : [];

  return {
    allHelpSubjects,
    errorAllHelpSubjects: error,
    loadingAllHelpSubjects: loading,
  };
};

// Mutation
const mutationCreateHcpHelp = loader('./gql/mutationCreateHcpHelp.graphql');

// Send HCPs feedback by email to admin
export const useCreateHcpHelp = (): any => {
  const [
    _createHcpHelp,
    { data, errorText, loading },
  ] = useMutationRequest<{createHcpHelp: boolean}>(
    mutationCreateHcpHelp, {},
  );

  return {
    _createHcpHelp,
    createdEmail: data && data.createHcpHelp,
    emailerror: errorText,
    emailLoading: loading,
  };
};
