import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import style from './styles.module.css';
import { Button, Loading, MainTitle } from '../../common';
import { Options } from '../../utils/model';
import { useSetHcpNotificationTimer } from '../../graphql/hcp';
import { getScheduleNumberOptions, getUnitOptions } from '../../utils/share/options';
import { validationRemoveUnreadNotif } from '../../utils/validators';
import { RemoveUreadNotifData } from './types';
import { GetState, getCurrenLang } from '../../redux/selector';
import { setOpenSettingsData } from '../../redux/emailNotifications';
import { SelectFloatLabel } from '../../common/Input/Select';
import { SuccessNotifModal } from '../../common/NotificationModal';
import { PopupWithTwoButtons } from '../../common/Popup';

export const RemoveUnreadNotificationsSettings = ({ data, updateData }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const ok = t?.common.ok;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const number = t?.common.number;
  const unit = t?.common.unit;
  const settings_updated = t?.notifications.settings_updated;
  const remove_unread_notif_older = t?.hcp.manage.remove_unread_notif_older;
  const remove_unread_notif_older_then =
    remove_unread_notif_older?.replace('<period>', '').replace('.', '') || '';
  // const remove_unread_notif = t?.hcp.manage.remove_unread_notif;

  // Endpoints
  const {
    _setHcpNotifTimer,
    setHcpNotifTimerLoading,
    setHcpNotifTimerError,
    setHcpNotifTimerData,
  } = useSetHcpNotificationTimer();

  // Variables
  const id = data?.id;
  const dispatch = useDispatch();
  const numberOptions = useMemo(() => getScheduleNumberOptions(), []);
  const unitOptions = useMemo(
    () => (t ? getUnitOptions(t.dashboard.hcp.profile_patient.completion_and_steps) : []),
    [t],
  );

  // Local state
  const [isUpdatedSuccess, setUpdatedSuccess] = useState(false);
  const [isExitWarn, setExitWarn] = useState(false);

  // If set Hcp Email Notification success show popup
  useEffect(() => {
    if (setHcpNotifTimerData) {
      setUpdatedSuccess(() => true);
    }
  }, [setHcpNotifTimerData]);

  // If set Hcp Email Notification error show message
  useEffect(() => {
    if (setHcpNotifTimerError) {
      toast.error(setHcpNotifTimerError);
    }
  }, [setHcpNotifTimerError]);

  const initValues = useMemo(
    () => ({
      id: data?.id || null,
      isRemoveUnreadNotif: data?.isRemoveUnreadNotif || false,
      amount: data?.amount ? numberOptions[data.amount - 1] : null,
      timePeriod: data?.timePeriod ? unitOptions[data.timePeriod - 1] : null,
    }),
    [t, data],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: () => validationRemoveUnreadNotif(t),
    onSubmit: (val: any) => {
      const request: RemoveUreadNotifData = {
        notifData: {
          id: val.id,
          isRemoveUnreadNotif: true,
          amount: +val.amount.value,
          timePeriod: +val.timePeriod?.value,
        },
      };
      _setHcpNotifTimer(request);
    },
  });
  const { values, errors, touched } = formik;

  // Select handler
  const changePeriod = (name: string, value: Options): void => {
    formik.setFieldValue(name, value);
  };

  const closeSettings = (): void => {
    dispatch(setOpenSettingsData(undefined));
  };

  // Cancel changes and clouse settings
  const onCancel = (): void => {
    if (formik.dirty) {
      setExitWarn(() => true);
      return;
    }
    closeSettings();
    // formik.resetForm();
  };

  // Clouse success modal window
  const onClouseSuccessModal = (): void => {
    updateData();
    setUpdatedSuccess(() => false);
    closeSettings();
  };

  // Confirm exit from settings
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    // formik.resetForm();
    closeSettings();
  };

  // Close Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  // JSX
  const loadingJSX = setHcpNotifTimerLoading && <Loading />;

  return (
    <>
      {loadingJSX}
      <div className={style['email-notif__title-wrapper--bg']}>
        <MainTitle title={data?.title} />
      </div>
      <div className={style['email-notif__subtitle']}>{remove_unread_notif_older_then}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={style['email-notif__fields-container']}>
          <div className={style['email-notif__field--38']}>
            <SelectFloatLabel
              inputId="amountId"
              name="amount"
              placeholder={number}
              onChange={changePeriod}
              options={numberOptions}
              hasErrors={errors.amount}
              isTouched={touched.amount}
              selected={values.amount}
              inputValue={values.amount}
              type="rewards"
            />
          </div>
          <div className={style['email-notif__field--60']}>
            <SelectFloatLabel
              inputId="timePeriodId"
              name="timePeriod"
              placeholder={unit}
              onChange={changePeriod}
              options={unitOptions}
              hasErrors={errors.timePeriod}
              isTouched={touched.timePeriod}
              selected={values.timePeriod}
              inputValue={values.timePeriod}
              type="rewards"
            />
          </div>
        </div>

        <div className={style['email-notif__btns-container']}>
          <Button
            buttonName={cancel}
            buttonType="button"
            buttonClass={style['email-notif__btn']}
            buttonMethod={onCancel}
          />
          <Button
            buttonName={save}
            buttonType="submit"
            buttonClass={style['email-notif__btn']}
            disabledButton={!formik.dirty}
          />
        </div>
      </form>

      {/* Popups */}
      {isUpdatedSuccess && (
        <SuccessNotifModal
          onClose={onClouseSuccessModal}
          btnName={ok}
          description={settings_updated}
        />
      )}

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};
