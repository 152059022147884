import React, { useEffect } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';
import { storage } from '../../utils';
import { sideBarKey } from '../../utils/variables';
import { ArrowLeft, Logo, LogoOtm, CrossIcon } from '../../theme/icons';
import { getMenuPosition, GetState } from '../../redux/selector';
import { setCollapsed, toggleMenu } from '../../redux/common';
import Menu from './Menu';

const SideBar = ({ successPath }: any): JSX.Element => {
  const collapsed = useSelector((state: GetState) => state.common.collapsed);
  const isShowMenu: any = useSelector<any>((state) => getMenuPosition(state));

  const dispatch = useDispatch();

  useEffect(
    () => (): void => {
      dispatch(toggleMenu(false));
    },
    [],
  );

  // Close menu
  const closeMenu = (): void => {
    dispatch(toggleMenu(false));
  };

  // Collapse or show sidebar
  const toggleCollapsed = (): void => {
    dispatch(setCollapsed(!collapsed));
    storage.save(sideBarKey, collapsed ? '0' : '1');
  };

  return (
    <>
      {/* Full screen */}
      <div className={cx({ [styles['side-bar__container']]: true, [styles.collapsed]: collapsed })}>
        {/* Arrow */}
        <div onClick={toggleCollapsed} className={styles.collapse} aria-hidden>
          <ArrowLeft />
        </div>

        {/* Logo */}
        <NavLink
          to={successPath}
          exact
          activeStyle={{ pointerEvents: 'none' }}
          isActive={(_, location) => location.pathname === successPath}
        >
          <div className={styles.logo}>{collapsed ? <Logo /> : <LogoOtm />}</div>
        </NavLink>

        {/* Menu */}
        <ul className={styles.menu}>
          <Menu isResponsive={false} />
        </ul>
      </div>

      {/* Adaptive screen */}
      <div
        className={cx({
          [styles.backDrop]: true,
          [styles['menu__container--open']]: isShowMenu,
        })}
        onClick={closeMenu}
        aria-hidden
      />
      <div
        className={cx({
          [styles.menu__container]: true,
          [styles['menu__container--open']]: isShowMenu,
        })}
      >
        {/* Menu header */}
        <div className={styles.menu__header}>
          <div className={styles.menu__logo}>
            <Logo />
          </div>

          <div className="btn-close" onClick={closeMenu} aria-hidden>
            <CrossIcon />
          </div>
        </div>

        {/* Items */}
        <ul className={styles.menu}>
          <Menu isResponsive />
        </ul>
      </div>
    </>
  );
};

export default SideBar;
