import React from 'react';

import styles from '../styles.module.css';
import { LeaveChannelIcon } from '../../../../theme/icons';
import { ChannelPreferencesProps, ContentConfigType } from '../../../../pages/Messenger/types';

const ChannelPreferences = ({ title, contentConfig }: ChannelPreferencesProps): JSX.Element => (
  <div className={styles.pref__container}>
    <div className={styles.pref__title}>{title}</div>
    {contentConfig.map((content: ContentConfigType, i: number) => (
      <div
        className={styles.pref__row}
        key={`${String(i)}${content.description}`}
        onClick={content.action}
        aria-hidden
      >
        <LeaveChannelIcon />
        <div className={styles['pref__row-title']}>{content.description}</div>
      </div>
    ))}
  </div>
);

export default ChannelPreferences;
