import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../styles.module.css';
import AddNewItem from '../../../SideBar/AddNewItem';
import { getCurrenLang, getMessengerState, GetState } from '../../../../redux/selector';
import { MessengerState } from '../../../../redux/messenger/types';
import AddMembers from '../AddMembers/AddMembers';
import { setOpenAddMembersModal, setTypeChannelAddMembers } from '../../../../redux/messenger';
import { initialPage } from '../../../../utils/helper';
import { useHcpListForSearchHcp } from '../../../../graphql/hcp';
import useDebounce from '../../../../hooks/useDebounce';
import { RoomMemberType } from '../../../../pages/Messenger/types';
import { MemberItem } from '../../ChannelItem/ChannelItem';
import { CHANNEL_TYPE } from '../../../../utils/enums';

const ManageMembers = (): JSX.Element => {
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const members = t?.chat?.members || 'Members';
  const no_members = t?.chat?.no_members || 'No members';
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { roomMembers, isAddMembersModal } = messengerState;
  const isAddNewMembersModalOpen = isAddMembersModal.isOpen;
  const isAddNewMembersModalChannelType = isAddMembersModal.type;

  // Endpoints
  const {
    _getHcpListForSearchHcp,
    hcpListLoading,
    hcpList,
    totalLength,
    dataHcp,
  } = useHcpListForSearchHcp();

  const dispatch = useDispatch();

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [itemsTotal, setItemsTotal] = useState(0);

  useEffect(() => {
    if (!hcpListLoading) {
      if (totalLength) {
        setItemsTotal(totalLength);
      }
    }
  }, [dataHcp]);

  // Clear state after success created channel
  useEffect(() => {
    if (!isAddMembersModal) {
      setItemsTotal(0);
      setCurrentPage(initialPage);
      setSearchQuery('');
      setSearch('');
    }
  }, [isAddMembersModal]);

  // Query get hcp list for search
  const getList = (page: number, searchValue: string): void => {
    _getHcpListForSearchHcp({
      variables: {
        listProps: {
          page,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: 'hcp.firstName',
            searchValue,
          },
          {
            searchField: 'hcp.lastName',
            searchValue,
          },
          {
            searchField: 'hcp.email',
            searchValue,
          },
        ],
      },
    });
  };

  // Move to corespond page
  const onChangePagination = (page: number): void => {
    getList(page, searchQuery);
    setCurrentPage(page);
  };

  // Update HCP list after change search
  useEffect(() => {
    if (searchQuery) {
      getList(initialPage, searchQuery);
    }
  }, [searchQuery]);

  const debounceValue = useDebounce(search, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setSearchQuery(debounceValue);
    } else {
      setSearchQuery('');
    }
  }, [debounceValue]);

  // Search member
  const changeSearch = ({ target: { value } }: any): void => {
    setSearch(value);
  };

  const openAddMemberModal = (): void => {
    dispatch(setOpenAddMembersModal(true));
    dispatch(setTypeChannelAddMembers(CHANNEL_TYPE.GROUP));
  };

  const noMembers = roomMembers?.length === 0 && (
    <div className={styles.pref__nomembers}>{no_members}</div>
  );

  return (
    <div className={styles.pref__container}>
      <AddNewItem isShowLabel title={members} action={(): void => openAddMemberModal()} />
      {noMembers}
      {!noMembers &&
        roomMembers.map((member: RoomMemberType) => (
          <MemberItem key={`${member.hcpId}${member.name}`} member={member} />
        ))}
      {/* Popups */}
      {isAddNewMembersModalOpen && isAddNewMembersModalChannelType === CHANNEL_TYPE.GROUP && (
        <AddMembers
          search={search}
          changeSearch={changeSearch}
          list={hcpList}
          listLoading={hcpListLoading}
          current={currentPage}
          pageSize={pageSize}
          total={itemsTotal}
          onChangePagination={onChangePagination}
        />
      )}
    </div>
  );
};

export default ManageMembers;
