// import socket from '../socket';
import {
  AddMembersToRoomReqType,
  AddMemberToRoomReqType,
  ChangeActiveRoom,
  CreateRoomReq,
  Message,
} from '../pages/Messenger/types';

const useChat = (socket: any) => {
  // Methods
  // Create new room
  const createRoom = (candidat: CreateRoomReq, activeRoomId: number): void => {
    console.log('***useChat_emit_createRoom_candidat=', candidat, 'activeRoomId=', activeRoomId);
    if (socket) {
      socket.emit('createRoom', candidat);
      if (activeRoomId) {
        console.log('***useChat_emit_leaveRoom_activeRoomId=', activeRoomId);
        // socket.emit('leaveRoom', activeRoomId);
      }
    }
  };
  // Leave room
  const leaveChannel = (roomId: number): void => {
    console.log('***useChat_emit_leaveChannel=', roomId);
    if (socket) {
      socket.emit('leaveChannel', roomId);
    }
  };
  // Сlose Conversation
  const closeConversation = (roomId: number): void => {
    console.log('***useChat_emit_closeConversation=', roomId);
    if (socket) {
      socket.emit('closeConversation', roomId);
    }
  };
  // Join room
  // const joinRoom = (roomId: number): void => {
  //   console.log('***useChat_emit_joinRoom=', roomId);
  //   socket.emit('joinRoom', roomId);
  // };
  // Leave room
  // const leaveRoom = (roomId: number): void => {
  //   console.log('***useChat_emit_leaveRoom=', roomId);
  //   socket.emit('leaveRoom', roomId);
  // };
  // Change active room
  const changeActiveRoom = (data: ChangeActiveRoom): void => {
    // console.log('***useChat_emit_changeRoom=', roomId, activeRoomId);
    // if (activeRoomId !== roomId && !!activeRoomId) {
    //   console.log('***useChat_emit_changeRoom_leaveRoom=', roomId, activeRoomId);
    //   socket.emit('leaveRoom', activeRoomId);
    // }
    // console.log('***useChat_emit_changeRoom_joinRoom=', roomId, activeRoomId);
    // socket.emit('joinRoom', roomId);
    console.log('***useChat_emit_ChangeActiveRoom=', data);
    if (socket) {
      socket.emit('changeActiveRoom', data);
    }
  };
  // Add member to room
  const addMemberToRoom = (requestData: AddMemberToRoomReqType): void => {
    console.log('***useChat_emit_addMemberRoom=', requestData);
    socket.emit('addMemberRoom', requestData);
  };
  // Add members to room
  const addMembersToRoom = (requestData: AddMembersToRoomReqType): void => {
    console.log('***useChat_emit_addMembersRoom=', requestData);
    if (socket) {
      socket.emit('addMembersRoom', requestData);
    }
  };
  // Remove member from channel
  const removeMemberFromRoom = (requestData: AddMemberToRoomReqType): void => {
    console.log('***useChat_emit_removeMemberRoom=', requestData);
    if (socket) {
      socket.emit('removeMemberRoom', requestData);
    }
  };
  const sendMessage = (message: Message): void => {
    console.log('***useChat_emit_sendMessage=', message);
    if (socket) {
      socket.emit('sendMessage', message);
    }
  };
  // Remove message
  const removeMessage = (message: Message): void => {
    console.log('***useChat_emit_removeMessage=', message);
    if (socket) {
      socket.emit('removeMessage', message);
    }
  };
  // Edit message
  const updateMessage = (message: Message): void => {
    console.log('***useChat_emit_updateMessage=', message);
    if (socket) {
      socket.emit('updateMessage', message);
    }
  };
  const onConnect = (): void => {
    console.log('***useChat_onConnect=');
    if (socket) {
      socket.connect();
    }
  };
  const onDisconnect = (): void => {
    console.log('***useChat_onDisconnect=');
    if (socket) {
      socket.disconnect();
    }
  };
  return {
    sendMessage,
    removeMessage,
    createRoom,
    changeActiveRoom,
    leaveChannel,
    // joinRoom,
    addMemberToRoom,
    addMembersToRoom,
    // leaveRoom,
    updateMessage,
    onConnect,
    onDisconnect,
    removeMemberFromRoom,
    closeConversation,
  };
};
export default useChat;
