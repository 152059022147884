// Core
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
// Components
import App from './App';
import { Loading } from './common/Loader';
import { theme } from './hooks/useStyles';
import { client } from './utils/init/client';
import { store } from './redux/store';
import { SocketProvider } from './ SocketContext';

render(
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <SocketProvider>
              <App />
            </SocketProvider>
          </ApolloProvider>
        </MuiThemeProvider>
      </Provider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root'),
);
