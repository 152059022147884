import { combineReducers } from 'redux';

// Type
const GET_LOCALIZATION_FILE = 'get/localization_file';

interface LocalizationFillAction {
  type: typeof GET_LOCALIZATION_FILE;
  payload: object;
}

type ActionTypes = LocalizationFillAction;

// Action creator
export const setLang = (data: object): LocalizationFillAction => ({
  type: GET_LOCALIZATION_FILE,
  payload: data,
});

// Reducer
export const localLangReduser = (
  state: object | null = null, { type, payload }: ActionTypes,
): object | null => {
  switch (type) {
    case GET_LOCALIZATION_FILE:
      return payload;
    default:
      return state;
  }
};

// Reducer combiner
export default combineReducers({
  local: localLangReduser,
});
