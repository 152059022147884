import React from 'react';
import { Tooltip } from 'antd';

import styles from './styles.module.css';
import { AddNewItemrops } from './types';

const AddNewItem = ({ isShowLabel, title, action }: AddNewItemrops): JSX.Element => (
  <Tooltip placement="right" title={!isShowLabel ? title : ''}>
    <div className={styles.side_bar_li}>
      <div className={styles.side_bar_div} onClick={action} aria-hidden>
        {isShowLabel && <div className={styles.side_bar_channel_title}>{title}</div>}
        <div className={styles.side_bar_add_channel}>+</div>
      </div>
    </div>
  </Tooltip>
);

export default AddNewItem;
