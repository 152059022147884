// Core
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import Loader from 'react-loaders';
// Instruments
import { loaderElement } from '../../utils/helper';
import 'loaders.css';
import './style.css';

export const Loading: FC = () => {
  const loaderJSX = (
    <div className="backDrop">
      <div className="modal">
        <Loader type="ball-spin-fade-loader" active />
      </div>
    </div>
  );
  return createPortal(loaderJSX, loaderElement);
};

export const spinner = (
  <div className="spinner_container">
    <Loader type="ball-spin-fade-loader" active />
  </div>
);

export const SpinnerWithContent = ({ content }: {content: string}): JSX.Element => (
  <div className="spinner-with-info__container">
    <Loader type="ball-spin-fade-loader" active />
    <div className="spinner-with-info__description">
      {content}
    </div>
  </div>
);
