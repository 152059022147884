import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';
import { Button, Loading } from '../../common';
import {
  CustomCheckbox,
  InputFloatLabel,
  InputTypeFile,
  TextareaFeildFloatLabel,
} from '../../common/Input';
import { Attachment } from '../../common/Attachments';
import { deepCopyObj, uploadFiles } from '../../utils/helper';
import { Popup, PopupWithTwoButtons } from '../../common/Popup';
import { validationOrganisationRewards } from '../../utils/validators';
import { storage } from '../../utils';
import { useRemoveBrandPic, useSaveHcpHospitalWeekReward } from '../../graphql/rewards';
import {
  GetState,
  getCurrenLang,
  getRewardList,
  getRewardSettingsData,
} from '../../redux/selector';
import { setOpenRewardSettingData } from '../../redux/rewards';
import { DataRewardType, InitErrType, RewardsOptionType } from './types';
import { SelectFloatLabel } from '../../common/Input/Select';
import { SuccessNotifModal } from '../../common/NotificationModal';

const RewardSetting = ({ updateForm }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const save = t?.common.save;
  const cancel = t?.common.cancel;
  const wrong_format = t?.help.wrong_format;
  const large_file = t?.dashboard.hcp.profile_patient.large_file;
  const uploading_file_error = t?.dashboard.hcp.profile_patient.uploading_file_error;
  const add_file = t?.dashboard.hcp.profile_patient.add_file;
  const name_of_reward = t?.hcp.rewards.name_of_reward;
  const at_sign_up = t?.hcp.rewards.at_sign_up;
  const description_reward = t?.hcp.rewards.description_reward;
  const image_reward = t?.hcp.rewards.image_reward;
  const week_t = t?.dashboard.hcp.profile_patient.completion_and_steps.week;
  const copy_reward = t?.hcp.rewards.copy_reward;
  const period_saved = t?.hcp.rewards.period_saved;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const brand_approval = t?.hcp.rewards.brand_approval;

  const initErr = {
    error: false,
    format: false,
    heavy: false,
  };

  // Redax
  const rewardList = useSelector((state: GetState) => getRewardList(state));
  const isOpenSettigsData = useSelector((state: GetState) => getRewardSettingsData(state));

  const dispatch = useDispatch();
  const large_file_norm = large_file?.replace('<size>', '5MB');
  // const periodName = index === 0 ? at_sign_up : `${week_t || ''} ${index}`;
  // const isEdit = !!isOpenSettigsData?.name;
  const currentIndex = isOpenSettigsData?.index ? isOpenSettigsData?.index : 0;
  const user = storage.get('user');
  const { token } = user;

  // Local state
  const [isModalOpen, setModal] = useState(false);
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [errorMessage, setErrorMessage] = useState<InitErrType>(deepCopyObj(initErr));
  const [loadingToAWS, setLoadingToAWS] = useState(false);
  const [isExitWarn, setExitWarn] = useState(false);

  // Endpoints
  const { _saveWeek, weekSaved, saveWeekError, saveWeekLoading } = useSaveHcpHospitalWeekReward();
  const { _removeBrand, removeBrandError, removeBrandLoading } = useRemoveBrandPic();

  const allowedFileTypes = '.jpeg, .png, .jpg';
  // Maximum file size for logo (5Mb)
  const filesSizeLimit = 5242880;

  const initialValues: any = {
    name: isOpenSettigsData ? isOpenSettigsData.name : '',
    description: isOpenSettigsData ? isOpenSettigsData.description : '',
    file: '',
    fileName: isOpenSettigsData ? isOpenSettigsData.fileName : '',
    preview: isOpenSettigsData ? isOpenSettigsData.fileLink : '',
    copyReward: null,
    fileUuid: isOpenSettigsData ? isOpenSettigsData.fileUuid : '',
    brandApproval: false,
  };

  const savePeriod = async (params: any): Promise<void> => {
    const { name, description, fileName, fileUuid, file, copyReward } = params;
    let uuId = fileUuid || '';
    if (!uuId) {
      // Download logo to AWS
      try {
        setLoadingToAWS(() => true);
        const urlPic = 'pictures/upload-custom-brand-pic';
        const response: {
          attachmentPic: any[];
          attachmentVideo: any[];
          isLoadingErr: boolean;
        } = await uploadFiles([params.file], urlPic, '', token, false);
        if (!response.isLoadingErr) {
          uuId = response.attachmentPic[0].picUuid;
        } else {
          toast.error(uploading_file_error);
          setLoadingToAWS(() => false);
          return;
        }
      } catch (error) {
        setLoadingToAWS(() => false);
        toast.error(uploading_file_error);
        return;
      }
    }

    // If delete old file(logo brand) and upload new file
    if (isOpenSettigsData?.fileUuid && file && !copyReward && rewardList && rewardList.length > 1) {
      // Check if there is a logo with the same uuId
      // If there is, we won’t delete it from AWS.
      const filtredIdenticalFileUuId = rewardList.filter(
        (item: DataRewardType) => item.fileUuid === isOpenSettigsData.fileUuid,
      );
      // If there is only one file in DB with this fileUuid, then delete it from AWS
      if (filtredIdenticalFileUuId?.length === 1 && isOpenSettigsData.fileUuid !== uuId) {
        // Delete from AWS
        const { data: brandRemoved } = await _removeBrand({
          variables: {
            pictureUuidKey: isOpenSettigsData.fileUuid,
          },
        });
        if (!brandRemoved?.removeCustomBrandPic) {
          // If the removal is unsuccessful, stop the update data
          return;
        }
      }
    }

    const request = {
      week: currentIndex,
      name: name.trim(),
      description: description.trim(),
      fileName,
      fileUuid: uuId,
    };
    setLoadingToAWS(() => false);
    _saveWeek({
      variables: {
        hospitalData: request,
      },
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationOrganisationRewards(t),
    onSubmit: (value: any): any => savePeriod(value),
  });

  const { values, errors, touched } = formik;
  const options: RewardsOptionType = useMemo(() => {
    if (rewardList && rewardList.length > 1) {
      const endOfIteration = currentIndex;
      const computedOptions: any = [];
      for (let i = 0; i < endOfIteration; i += 1) {
        const item = {
          value: i,
          label: i === 0 ? at_sign_up : `${week_t} ${i}`,
        };
        computedOptions.push(item);
      }
      return computedOptions;
    }
    return [];
  }, [rewardList, t]);

  // Close form and clear settings state
  const closeSettings = (): void => {
    dispatch(setOpenRewardSettingData(null));
  };

  // Cancel changes and clouse settings
  const onCancel = (): void => {
    if (formik.dirty) {
      setExitWarn(() => true);
      return;
    }
    formik.resetForm();
    closeSettings();
  };

  // Clouse modal window
  const onCloseModal = (): void => {
    setModal(() => false);
    closeSettings();
    updateForm();
  };

  // Confirm exit from settings
  const onConfirmExit = async (): Promise<void> => {
    setExitWarn(() => false);
    formik.resetForm();
    closeSettings();
  };

  // Close Exit warning popup
  const onCloseExitWarn = (): void => {
    setExitWarn(() => false);
  };

  // If the period was saved successfully, display a message
  useEffect(() => {
    if (weekSaved) {
      setModal(() => true);
    }
  }, [weekSaved]);

  // If save not success, display an error
  useEffect(() => {
    if (saveWeekError) {
      toast.error(saveWeekError);
    }
  }, [saveWeekError]);

  // If remove brand from AWS not success, display an error
  useEffect(() => {
    if (removeBrandError) {
      toast.error(removeBrandError);
    }
  }, [removeBrandError]);

  // Check the incoming file for the correct format, size and upload to AWS
  const onCheckIncomingFiles = async (e: any): Promise<void> => {
    setFileInputKey(Date.now());
    let wrongFormat = false;
    let newFile = null;
    let prew = '';
    let fileNameOrigin = '';
    try {
      const f = e.target.files;
      const filesArray = Array.from(f);
      if (filesArray.length) {
        const file: any = filesArray[0];
        const { type } = file;
        fileNameOrigin = file.name;
        const img = new Image();
        prew = window.URL.createObjectURL(file);
        img.src = prew;
        // await img.decode();
        // const { width, height } = img;
        if (!type) {
          wrongFormat = true;
        } else {
          newFile = file;
        }
      }
      if (wrongFormat) {
        window.URL.revokeObjectURL(prew);
        setErrorMessage({
          error: true,
          format: true,
          heavy: false,
        });
      } else if (newFile.size > filesSizeLimit) {
        window.URL.revokeObjectURL(prew);
        setErrorMessage({
          error: true,
          format: false,
          heavy: true,
        });
      } else {
        formik.setFieldValue('file', newFile);
        formik.setFieldValue('fileName', fileNameOrigin);
        formik.setFieldValue('preview', prew);
      }
    } catch (error) {
      toast.error(uploading_file_error);
    }
  };

  // Delete file from local state
  const deleteAttachments = (): void => {
    formik.setFieldValue('file', '');
    formik.setFieldValue('preview', '');
    formik.setFieldValue('fileName', '');
    formik.setFieldValue('fileUuid', '');
  };

  // Copy reward to current period
  const copyReward = (name: string, value: RewardsOptionType): void => {
    if (rewardList) {
      const copyPeriod = rewardList[value.value];
      formik.setFieldValue('name', copyPeriod.name);
      formik.setFieldValue('description', copyPeriod.description);
      formik.setFieldValue('fileName', copyPeriod.fileName);
      formik.setFieldValue('file', '');
      formik.setFieldValue('preview', copyPeriod.fileLink);
      formik.setFieldValue('fileUuid', copyPeriod.fileUuid);
      formik.setFieldValue(name, value);
    }
  };

  // JSX
  const loadingJSX = (saveWeekLoading || loadingToAWS || removeBrandLoading) && <Loading />;

  return (
    <>
      {loadingJSX}
      <form onSubmit={formik.handleSubmit}>
        {currentIndex > 0 && (
          <SelectFloatLabel
            inputId="copyRewardId"
            name="copyReward"
            placeholder={copy_reward}
            onChange={copyReward}
            options={options}
            hasErrors={false}
            isTouched={false}
            selected={values.copyReward}
            inputValue={values.copyReward}
            type="rewards"
          />
        )}

        <InputFloatLabel
          inputId="RewardNameId"
          inputName="name"
          inputType="text"
          placeholder={name_of_reward}
          hasErrors={errors.name}
          isTouched={touched.name}
          inputValue={values.name}
          onChangeMethod={formik.handleChange}
          isRedStar
        />

        <TextareaFeildFloatLabel
          id={`RewardsDescr${currentIndex}`}
          inputName="description"
          placeholder={description_reward}
          inputValue={values.description}
          hasErrors={errors.description}
          isTouched={touched.description}
          onChecngeMethod={formik.handleChange}
          rows={3}
          isRedStar
        />

        {values.preview && (
          <>
            <div className={styles['reward__attachment-label']}>
              {`${image_reward} ${allowedFileTypes}*`}
            </div>
            <Attachment
              id={`RewardLogo${currentIndex}`}
              title={values.fileName || values.file.name}
              index={currentIndex}
              deleteAttachments={deleteAttachments}
            />
          </>
        )}
        {!values.preview && (
          <InputTypeFile
            id={`inputFile${String(currentIndex)}`}
            inputName="preview"
            labelName={`${image_reward} ${allowedFileTypes}`}
            hasErrors={errors.preview}
            isTouched={touched.preview}
            onChecngeMethod={(e: any): Promise<void> => onCheckIncomingFiles(e)}
            placeholder={add_file}
            fileInputKey={fileInputKey}
            accept={allowedFileTypes}
          />
        )}
        <div className={styles['reward__brand-approval']}>
          <CustomCheckbox
            htmlId="brandApproval"
            name="brandApproval"
            checked={values.brandApproval}
            onChangeMethod={formik.handleChange}
            isTouched={touched.brandApproval}
            hasErrors={errors.brandApproval}
            bigSize
          />
          <div className={styles.reward_admin__checkbox_name}>{brand_approval}</div>
        </div>
        <div className={styles['reward__btn--container']}>
          <Button
            buttonClass={styles.reward__btn}
            buttonType="button"
            buttonName={cancel}
            buttonMethod={onCancel}
            disabledButton={!!loadingJSX}
          />
          <Button
            buttonClass={styles.reward__btn}
            buttonType="submit"
            buttonName={save}
            disabledButton={!formik.dirty || !!loadingJSX || !values.brandApproval}
          />
        </div>
      </form>

      {/* Pop up */}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.heavy ? large_file_norm : wrong_format}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(deepCopyObj(initErr))}
        />
      )}

      {/* Success save period modal */}
      {isModalOpen && (
        <SuccessNotifModal onClose={onCloseModal} btnName={ok} description={period_saved} />
      )}

      {/* Warning popup if has not saved changes */}
      {isExitWarn && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirmExit}
          onClosePopup={onCloseExitWarn}
        />
      )}
    </>
  );
};

export default RewardSetting;
