import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { ExecutionResult } from 'graphql';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import {
  ChangeEmailNotifDataType,
  NotifDataChangeNotifType,
  NotifDataType,
  RemoveUreadNotifData,
  HcpAllEmailNotifType,
} from '../../components/EmailNotifications/types';
import useHandleGqlError from '../../hooks/useHandleGqlError';
import {
  setNotifToMeData,
  setNotifToMeError,
  setNotifToMeLoading,
} from '../../redux/emailNotifications';

// Mutations
const gqlSharingTeamVideobank = loader('./gql/mutationSharingTeamVideobank.graphql');
const gqlSetHcpInformedAboutSharing = loader('./gql/mutationSetHcpInformedAboutSharing.graphql');
const gqlSetHcpEmailNotifications = loader('./gql/mutationSetHcpEmailNotifications.graphql');
const gqlChangeHcpEmailNotifStatus = loader('./gql/mutationChangeHcpEmailNotifStatus.graphql');
const gqlChangeisNotifSending = loader('./gql/mutationChangeIsNotifSending.graphql');
const gqlSetHcpNotificationToPatient = loader('./gql/mutationSetHcpNotificationToPatient.graphql');
const mutationSetHcpNotificationTimer = loader('./gql/mutationSetHcpNotificationTimer.graphql');
const mutationChangeRemoveUnreadNotif = loader('./gql/mutationChangeRemoveUnreadNotif.graphql');

// Queries
const getHcpListForSearchHcp = loader('./gql/queryGetHcpListForSearchHcp.graphql');
const getHcpsFromAnotherHospitals = loader('./gql/queryGetHcpsFromAnotherHospitals.graphql');
const getSharingNotif = loader('./gql/queryGetSharingNotification.graphql');
const getCurrentHcpNotificationToPatient = loader(
  './gql/queryGetCurrentHcpNotificationToPatient.graphql',
);

// Get list HCP for search in patient profile
export const useHcpListForSearchHcp = () => {
  const [_getHcpListForSearchHcp, { loading, error, data }] = useLazyQuery(getHcpListForSearchHcp, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const hcps = data && data.getHcpListForSearchHcp;

  const hcpList = hcps ? hcps.list : [];
  const total = hcps && hcps.listLength;
  const totalPages = hcps && hcps.totalPages;
  const totalLength = hcps && hcps.totalItems;

  return {
    hcpList,
    total,
    totalPages,
    totalLength,
    _getHcpListForSearchHcp,
    hcpListError: error,
    hcpListLoading: loading,
    dataHcp: data,
  };
};

// Get list of HCPs from another Hospitals
export const useGetHcpsFromAnotherHospitals = (): any => {
  const [_getForeignHcpsList, { loading, error, data }] = useLazyQuery(
    getHcpsFromAnotherHospitals,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const hcps = data && data.getHcpsFromAnotherHospitals;

  const foreignHcpList = hcps ? hcps.list : [];
  const totalForeignHcp = hcps && hcps.listLength;
  const totalPagesForeignHcp = hcps && hcps.totalPages;
  const totalItemsForeignHcp = hcps && hcps.totalItems;

  return {
    _getForeignHcpsList,
    foreignHcpList,
    totalForeignHcp,
    totalPagesForeignHcp,
    totalItemsForeignHcp,
    foreignHcpError: error?.graphQLErrors?.[0]?.message || error,
    foreignHcpLoading: loading,
  };
};

// Get notification if another HCP shared her/his team video bank
export const useGetSharingNotification = (): any => {
  const [_getSharingNotification, { loading, error, data }] = useLazyQuery(getSharingNotif, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  return {
    _getSharingNotification,
    dataSharingNotif: data && data.getSharingNotification,
    sharingNotifError: error?.graphQLErrors?.[0]?.message || error,
    sharingNotifLoading: loading,
  };
};

// Share the team video bank of your hospital to another hospital(HCP)
export const useSharingTeamVideobank = () => {
  const [shareVideobank, { data, errorText, loading }] = useMutationRequest<{
    sharingTeamVideobank: boolean;
  }>(gqlSharingTeamVideobank, {});

  const _shareVideobank = (
    hcpId: number,
  ): Promise<
    ExecutionResult<{
      sharingTeamVideobank: boolean;
    }>
  > => shareVideobank({ variables: { hcpId } });

  return {
    _shareVideobank,
    isSharingSuccess: data && data.sharingTeamVideobank,
    shareLoading: loading,
    shareError: errorText,
  };
};

// Set the flag that the HCP is informed about the shared video bank from another hospital
export const useSetHcpInformedAboutSharing = () => {
  const [setHcpInformedAboutShare, { data, errorText, loading }] = useMutationRequest<{
    setHcpInformedAboutSharing: boolean;
  }>(gqlSetHcpInformedAboutSharing, {});

  const _setHcpInformedAboutSharing = (
    sharingId: number,
  ): Promise<
    ExecutionResult<{
      setHcpInformedAboutSharing: boolean;
    }>
  > => setHcpInformedAboutShare({ variables: { sharingId } });

  return {
    _setHcpInformedAboutSharing,
    setHcpInformedLoading: loading,
    setHcpInformedError: errorText,
    isSetHcpInformed: data && data.setHcpInformedAboutSharing,
  };
};

// Set HCP Email Notifications for Exercises completed, Step count and Pain sections
export const useSetHcpEmailNotifications = () => {
  const [setHcpEmailNotifications, { data, errorText, loading }] = useMutationRequest<{
    setHcpEmailNotifications: boolean;
  }>(gqlSetHcpEmailNotifications, {});

  const _setHcpEmailNotif = (
    emailNotifData: HcpAllEmailNotifType[],
  ): Promise<
    ExecutionResult<{
      setHcpEmailNotifications: boolean;
    }>
  > => setHcpEmailNotifications({ variables: { emailNotifData } });

  return {
    _setHcpEmailNotif,
    setHcpEmailNotifLoading: loading,
    setHcpEmailNotifError: errorText,
    isSetHcpEmailNotif: data && data.setHcpEmailNotifications,
  };
};

// Change status current notification (only for togler)
// for Exercises completed, Step count and Pain sections
export const useChangeHcpEmailNotification = () => {
  const [changeHcpEmailNotifStatus, { data, errorText, loading }] = useMutationRequest<{
    changeHcpEmailNotification: boolean;
  }>(gqlChangeHcpEmailNotifStatus, {});

  const _changeHcpEmailNotifStatus = (
    emailNotifData: ChangeEmailNotifDataType,
  ): Promise<
    ExecutionResult<{
      changeHcpEmailNotification: boolean;
    }>
  > => changeHcpEmailNotifStatus({ variables: { emailNotifData } });

  return {
    _changeHcpEmailNotifStatus,
    changeHcpEmailNotifStatusLoading: loading,
    changeHcpEmailNotifStatusError: errorText,
    isStatusChanged: data && data.changeHcpEmailNotification,
  };
};

// Get notification settings getCurrentHcpNotificationToPatient
export const useGetCurrentHcpNotificationToPatient = () => {
  const [_getCurrentHcpNotifToPatient, { loading, error, data }] = useLazyQuery(
    getCurrentHcpNotificationToPatient,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  const dispatch = useDispatch();
  const errorText = useHandleGqlError(error);
  let response: any;

  useEffect(() => {
    if (data) {
      response = data.getCurrentHcpNotificationToPatient;
      dispatch(setNotifToMeData(response));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setNotifToMeLoading(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(setNotifToMeError(errorText));
  }, [error]);

  return {
    _getCurrentHcpNotifToPatient,
    dataNotifToPatient: response,
    notifToPatientError: errorText,
    notifToPatientLoading: loading,
  };
};

// Change status Notification my Patients (Manage notifications)
export const useChangeIsNotifSendingToPatients = () => {
  const [_onChangeIsNotifSendingToPatients, { data, errorText, loading }] = useMutationRequest<{
    changeisNotifSending: boolean;
  }>(gqlChangeisNotifSending, { fetchPolicy: 'no-cache' });
  const _changeIsNotifSendingToPatients = (request: NotifDataChangeNotifType): void => {
    _onChangeIsNotifSendingToPatients({
      variables: request,
    });
  };

  return {
    _changeIsNotifSendingToPatients,
    changedIsNotifSendingToPatients: data && data.changeisNotifSending,
    changeIsNotifSendingToPatientsError: errorText,
    changeIsNotifSendingToPatientsLoading: loading,
  };
};

// Change status Notification my Patients (Manage notifications)
export const useSetHcpNotificationToPatient = () => {
  const [_onSetHcpNotifToPatient, { data, errorText, loading }] = useMutationRequest<{
    setHcpNotificationToPatient: boolean;
  }>(gqlSetHcpNotificationToPatient, { fetchPolicy: 'no-cache' });
  const _setHcpNotifToPatient = (request: NotifDataType): void => {
    _onSetHcpNotifToPatient({
      variables: request,
    });
  };

  return {
    _setHcpNotifToPatient,
    setHcpNotifToPatientData: data && data.setHcpNotificationToPatient,
    setHcpNotifToPatientError: errorText,
    setHcpNotifToPatientLoading: loading,
  };
};

// Automatically remove unread notifications (Manage notifications)
export const useSetHcpNotificationTimer = () => {
  const [_onSetHcpNotifTimer, { data, errorText, loading }] = useMutationRequest<{
    setHcpNotificationTimer: boolean;
  }>(mutationSetHcpNotificationTimer, { fetchPolicy: 'no-cache' });
  const _setHcpNotifTimer = (request: RemoveUreadNotifData): void => {
    _onSetHcpNotifTimer({
      variables: request,
    });
  };

  return {
    _setHcpNotifTimer,
    setHcpNotifTimerData: data && data.setHcpNotificationTimer,
    setHcpNotifTimerError: errorText,
    setHcpNotifTimerLoading: loading,
  };
};

// Change status remove Notifications (Manage notifications)
export const useChangeRemoveUnreadNotif = () => {
  const [_onChangeRemoveUnreadNotif, { data, errorText, loading }] = useMutationRequest<{
    changeRemoveUnreadNotif: boolean;
  }>(mutationChangeRemoveUnreadNotif, { fetchPolicy: 'no-cache' });
  const _changeRemoveUnreadNotif = (request: { solution: boolean }): void => {
    _onChangeRemoveUnreadNotif({
      variables: request,
    });
  };

  return {
    _changeRemoveUnreadNotif,
    changeRemoveUnreadNotifData: data && data.changeRemoveUnreadNotif,
    changeRemoveUnreadNotifError: errorText,
    changeRemoveUnreadNotifLoading: loading,
  };
};
