import React, { ReactElement, useEffect } from 'react';
import styles from './styles.module.css';
import { useQueryPhotoPic, useQueryThumbnail } from '../../../graphql/videoBank';
import { EXERCISE_TYPE } from '../../../utils/enums';
import { ExercisePhotos, FolderThumbnailProps } from './types';
import { numberOfThumbnails } from './initForm';

export const SliderElement = ({ photoUuid }: {photoUuid: string}): ReactElement => {
  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  useEffect(() => {
    if (photoUuid) {
      _getPhotoPic({
        variables: {
          pictureUuid: photoUuid,
        },
      });
    }
  }, []);

  return (
    <img src={`data:image/jpeg;charset=utf-8;base64,${photoPic}`} alt="icon" />
  );
};

export const FolderThumbnail = ({ exercise }: FolderThumbnailProps): ReactElement => {
  // get link from AWS by uuid
  const { _getThumbnailByName, thumbnail } = useQueryThumbnail();
  // get photo picture from AWS by Uuid
  const { _getPhotoPic, photoPic } = useQueryPhotoPic();

  const { type, thumbnailName, exercisePhotos } = exercise;

  useEffect(() => {
    if (type === EXERCISE_TYPE.VIDEO) {
      _getThumbnailByName({
        variables: {
          thumbnailKey: thumbnailName,
        },
      });
    }
    if (type === EXERCISE_TYPE.PHOTO && exercisePhotos?.length) {
      _getPhotoPic({
        variables: {
          pictureUuid: exercisePhotos[0].fileUuid,
        },
      });
    }
  }, []);

  return (
    <div className={styles['video-folder__thambnail']}>
      {thumbnail
        ? <img src={thumbnail} alt="icon" />
        : <img src={`data:image/jpeg;charset=utf-8;base64,${photoPic}`} alt="icon" />}
    </div>
  );
};

export const getThumbnails = (exercises: any): JSX.Element[] => {
  const length = exercises?.length;
  if (length > 0) {
    const jsx: any = [];
    const limit = length >= numberOfThumbnails ? numberOfThumbnails : length;
    for (let i = 0; i < limit; i += 1) {
      jsx.push(
        <FolderThumbnail
          key={`folderThumbnail${i}`}
          exercise={exercises[i]}
        />,
      );
    }
    return jsx;
  }
  return [<></>];
};

export const getPreviews = (exercisePhotos: ExercisePhotos[]): JSX.Element[] => {
  const normaliseData = exercisePhotos.map((photo: ExercisePhotos, i: number) => (
    { type: EXERCISE_TYPE.PHOTO, thumbnailName: null, exercisePhotos: [exercisePhotos[i]] }
  ));
  const result = getThumbnails(normaliseData);
  return result;
};
