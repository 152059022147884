import {
  GET_ALL_PATIENT_QUESTIONNAIRES,
  GET_ALL_QUESTIONNAIRES,
  GET_LOADING_QUESTIONNAIRES,
  GET_LOADING_ALL_QUESTIONNAIRES,
  GET_PAIN_DETECT_PICS,
  LOADING_PAIN_DETECT_PICS,
  EQ_LEGEND_VALUE,
  GET_AVAILABLE_PATIENT_QUESTIONNAIRES,
  LOADING_AVAILABLE_PATIENT_QUESTIONNAIRES,
} from './constants';
import {
  ActionTypes,
  AllQuestionnairesLoading,
  AllQuestionnairesType,
  EqLegendType,
  PainDetectPicsLoading,
  PainDetectPicsType,
  QuestionnaireState,
  QuestionnairesData,
  QuestionnairesLoading,
  QuestionnairesType,
} from './types';

// Action creator
export const setPatientAllQuestionnaires = (data: QuestionnairesData): QuestionnairesType => ({
  type: GET_ALL_PATIENT_QUESTIONNAIRES,
  payload: data,
});
export const setLoadingQuestionnaires = (
  data: boolean,
): QuestionnairesLoading => ({
  type: GET_LOADING_QUESTIONNAIRES,
  payload: data,
});
export const setAllQuestionnaires = (data: QuestionnairesData): AllQuestionnairesType => ({
  type: GET_ALL_QUESTIONNAIRES,
  payload: data,
});
export const setLoadingAllQuestionnaires = (data: boolean): AllQuestionnairesLoading => ({
  type: GET_LOADING_ALL_QUESTIONNAIRES,
  payload: data,
});
export const setPainDetectPics = (data: QuestionnairesData): PainDetectPicsType => ({
  type: GET_PAIN_DETECT_PICS,
  payload: data,
});
export const setLoadingPics = (data: boolean): PainDetectPicsLoading => ({
  type: LOADING_PAIN_DETECT_PICS,
  payload: data,
});
export const setEqLegendValue = (data: any): EqLegendType => ({
  type: EQ_LEGEND_VALUE,
  payload: data,
});
export const setPatientAvailableQuestionnaires = (
  data: QuestionnairesData,
): QuestionnairesType => ({
  type: GET_AVAILABLE_PATIENT_QUESTIONNAIRES,
  payload: data,
});
export const setLoadingPatientAvailableQuestionnaires = (
  data: boolean,
): AllQuestionnairesLoading => ({
  type: LOADING_AVAILABLE_PATIENT_QUESTIONNAIRES,
  payload: data,
});

// Reducer
const init: QuestionnaireState = {
  allPatientQuestionnaires: [],
  loadingQuestionnaires: false,
  errorQuestionnaires: false,
  questionnairesList: {
    allQuestionnaires: [],
    loadingAllQuestionnaires: false,
  },
  painDetectPics: {
    pics: [],
    loadingPics: false,
  },
  eqLegendValue: [],
  availablePatientQuestionnaires: [],
  loadingAvailablePatientQuestionnaires: false,
};

export const promsReduser = (state = init, action: ActionTypes): any => {
  switch (action.type) {
    case GET_ALL_PATIENT_QUESTIONNAIRES:
      return {
        ...state,
        allPatientQuestionnaires: action.payload,
      };
    case GET_LOADING_QUESTIONNAIRES:
      return {
        ...state,
        loadingQuestionnaires: action.payload,
      };
    case GET_ALL_QUESTIONNAIRES:
      return {
        ...state,
        questionnairesList: {
          ...state.questionnairesList,
          allQuestionnaires: action.payload,
        },
      };
    case GET_LOADING_ALL_QUESTIONNAIRES:
      return {
        ...state,
        questionnairesList: {
          ...state.questionnairesList,
          loadingAllQuestionnaires: action.payload,
        },
      };
    case GET_PAIN_DETECT_PICS:
      return {
        ...state,
        painDetectPics: {
          ...state.painDetectPics,
          pics: action.payload,
        },
      };
    case LOADING_PAIN_DETECT_PICS:
      return {
        ...state,
        painDetectPics: {
          ...state.painDetectPics,
          loadingPics: action.payload,
        },
      };
    case EQ_LEGEND_VALUE:
      return {
        ...state,
        eqLegendValue: action.payload,
      };
    case GET_AVAILABLE_PATIENT_QUESTIONNAIRES:
      return {
        ...state,
        availablePatientQuestionnaires: action.payload,
      };
    case LOADING_AVAILABLE_PATIENT_QUESTIONNAIRES:
      return {
        ...state,
        loadingAvailablePatientQuestionnaires: action.payload,
      };
    default:
      return state;
  }
};
