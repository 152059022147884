// Core
import React, { ReactElement } from 'react';
// Instruments
import styles from './styles.module.css';
import { PropTypes } from './types';

const SectionTitle = ({ title }: PropTypes): ReactElement => (
  <div className={styles.title}>{title && title}</div>
);

export default SectionTitle;
