import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
// import { GiSpeaker } from 'react-icons/gi';
// import { useSpeechSynthesis } from 'react-speech-kit';
import moment from 'moment';
import cx from 'classnames';

import styles from '../styles.module.css';
import { CloseIcon, DeleteIcon, MessageEdit } from '../../../../theme/icons';
import { GetState, getCurrenLang, getHcpName } from '../../../../redux/selector';
import useCheckLocale from '../../../../hooks/useCheckLocale';
import useChat from '../../../../hooks/useChat';
import { Modal } from '../../../../common';
import { ButtonsBlock } from '../../../../common/Button/Button';
import { deepCopyObj } from '../../../../utils/helper';
import { MessageItemProps } from '../../../../pages/Messenger/types';
import { useSocket } from '../../../../ SocketContext';
import ItemAvatar from '../../ItemAvatar/ItemAvatar';
import { CHANNEL_TYPE } from '../../../../utils/enums';

const MessageItem = ({
  message,
  setEditMode,
  previousAuthorIsSame,
}: MessageItemProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t && t.common.cancel;
  const save = t?.common.save;
  const edit_post = t?.chat?.edit_post || 'Edit Post';
  const message_t = t?.chat?.message || 'Message';
  const edited = t?.chat?.edited || 'Edited';
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));
  const { id: userId } = currentUser;
  const [text, setText] = useState('');
  const [isEditMessage, setEditMessage] = useState(false);
  const { socket }: any = useSocket();
  const { removeMessage, updateMessage } = useChat(socket);
  const locale = useCheckLocale();
  const { lng, lacaleArr } = locale;
  moment.locale(lng, lacaleArr);
  // const { speak, voices } = useSpeechSynthesis();
  // const lang = document.documentElement.lang || 'en';
  // const voice = voices.find((v) => v.lang.includes(lang) && v.name.includes('Google'));
  // let element;
  // const { messageType, textOrPathToFile } = message;
  // const pathToFile = `${SERVER_URI}/files${textOrPathToFile}`;
  // switch (messageType) {
  //   case 'text':
  //     element = (
  //       <>
  //         {/* <button className="btn" onClick={() => speak({ text: textOrPathToFile, voice })}>
  //           <GiSpeaker className="icon speak" />
  //         </button> */}
  //         <div>{textOrPathToFile}</div>
  //       </>
  //     );
  //     break;
  //   // case 'image':
  //   //   element = <img src={pathToFile} alt="" />;
  //   //   break;
  //   // case 'audio':
  //   //   element = <audio src={pathToFile} controls></audio>;
  //   //   break;
  //   // case 'video':
  //   //   element = <video src={pathToFile} controls></video>;
  //   //   break;
  //   default:
  //     element = '';
  // }
  // Close modal
  const onclose = (): void => {
    setEditMessage(false);
    setText(() => '');
  };
  // Update  message
  const onSubmitEditedMsg = (): void => {
    if (text) {
      const newText = text.trim();
      if (newText !== message.text) {
        const req = deepCopyObj(message);
        req.text = newText;
        updateMessage(req);
      }
    }
    onclose();
  };
  // Open modal Edit message
  const editMessage = (): void => {
    setEditMessage(true);
    setEditMode(true);
    setText(() => message.text);
  };

  const isMyMessage = userId === message.hcpId;

  return (
    <li
      className={cx({
        [styles.message__item]: true,
        [styles['message__item--my']]: isMyMessage,
        [styles['message__item--foreign']]: !isMyMessage,
        [styles['message__item--previousAuthorIsSame']]: previousAuthorIsSame,
      })}
    >
      {/* Avatar */}
      <div className={styles['message__avatar-cont']}>
        {!previousAuthorIsSame && (
          <ItemAvatar
            room={{
              name: message.userName,
              type: CHANNEL_TYPE.DIRECT,
              id: 0,
              isActiveRoom: false,
              numberOfUnreadMessages: null,
              isUnreadMessages: false,
              isOnline: false,
              profession: '',
              organisation: '',
              photo: '',
              userId: 0,
            }}
            isShowStatus={false}
          />
        )}
      </div>
      <div
        className={cx({
          [styles['message__msg-cont']]: true,
          [styles['message__msg-cont--my']]: isMyMessage,
          [styles['message__msg-cont--foreign']]: !isMyMessage,
        })}
      >
        {/* Name */}
        {!previousAuthorIsSame && (
          <div className={styles['message__name-cont']}>
            <div className={styles.message__name}>{message.userName}</div>
            <div className={styles.message__datetime}>{moment(message.createdAt).fromNow()}</div>
          </div>
        )}
        {/* Message */}
        <div
          className={cx({
            [styles['message__text-cont']]: true,
            [styles['message__text-cont--my']]: isMyMessage,
            [styles['message__text-cont--foreign']]: !isMyMessage,
          })}
        >
          <div
            className={cx({
              [styles.message__text]: true,
              [styles['message__text--my']]: isMyMessage,
            })}
          >
            {message.text}
            {message.createdAt !== message.updatedAt && (
              <span className={styles.message__edited}>{`(${edited})`}</span>
            )}
          </div>
          {/* Action */}
          {isMyMessage && (
            <div className={styles['message__action-cont']}>
              <div
                className={styles['message__delete-icon']}
                onClick={(): void => {
                  removeMessage(message);
                  setEditMode(true);
                }}
                aria-hidden
              >
                <DeleteIcon />
              </div>
              <div className={styles['message__delete-icon']} onClick={editMessage} aria-hidden>
                <MessageEdit />
              </div>
            </div>
          )}
          <div
            className={cx({
              [styles.message__triangle]: true,
              [styles['message__triangle--my']]: isMyMessage,
              [styles['message__triangle--foreign']]: !isMyMessage,
            })}
          />
        </div>
      </div>

      {/* Edit message modal */}
      {isEditMessage && (
        <Modal onClose={onclose}>
          <div className="btn-close">
            <CloseIcon onClick={onclose} />
          </div>
          <div className={styles['message__edit-header']}>{edit_post}</div>
          <textarea
            rows={4}
            id="Edit message"
            name="Edit message"
            value={text}
            placeholder={`${message_t}...`}
            onChange={(e: any): void => setText(e.target.value)}
            className={styles['message__edit-textarea']}
          />
          <ButtonsBlock
            buttonNameSubmit={save}
            buttonNameCancel={cancel}
            onSubmit={onSubmitEditedMsg}
            onCancel={onclose}
            disabledSubmitButton={!text}
          />
        </Modal>
      )}
    </li>
  );
};
export default MessageItem;
