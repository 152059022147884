export const RegExrNumber = /\d/i;
export const RegExrLetter = /[a-z]/i;
export const RegExrSpecialCharacter = /[!"#$%&'()*+.\-./:<=>?@[\\\]^_`{|}~]/i;
export const RegExrComma = /[.]/i;
export const ReqExrStingHasNumber = /\d+/i;

export const RegExrOnOneComma = /(,*){2,}/i;
export const validation = /^[1-9][1-3]/g;

export const RegFindAllCommas = /,/gi;

// PHONE NUMBERS
export const cellPhoneNumberUS = /^(\+?1\s?[2-9]{1}\d{2}\s?[2-9]{1}\d{2}\s?\d{4})?$/;
export const cellPhoneNumberGB = /^(\+?44\s?7\d{3}\s?\d{3}\s?\d{3})?$/;
export const cellPhoneNumberDE = /^(\+?49\s?[0-9]+)?$/;

export const alphanumeric = /^([A-Za-z0-9]+)$/g;
export const onlyDigits = /^([0-9]+)$/gi;
export const maxValueIsSix = /^([0-6])$/gi;
export const valueFrom0To100 = /^(0|[1-9][0-9]?|100)$/;
export const valueFrom0To10 = /^(0|[1-9]?|10)$/;
export const notZero = /^[1-9]\d*$/;
export const normalizePhoneNumber = /-/gi;

// Email
export const validationEmailDomain = /^[-a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[-a-zA-Z0-9]+\.+(org|com|net|int|edu|gov|mil)+(\.[a-zA-Z]{2,})?$/;
export const validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
