/* eslint-disable no-console */
// Core
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import storage from '../../../utils/storage';
import { parseJwt } from '../../../utils/parseJwt';
import { PropTypes, UseRefreshToken } from '../types';
// Mutations
const mutationRefreshToken = loader('./gql/mutationRefreshToken.graphql');

export function useRefreshToken(): UseRefreshToken {
  const [_refresh, { data, error, loading }] = useMutation(mutationRefreshToken);

  const _refreshUser = async (token: PropTypes): Promise<void> => {
    _refresh({
      variables: {
        refreshToken: token,
      },
    }).catch((err) => console.error('error:', err && err.message));

    if (data && !error) {
      const access_token = data && data.access_token;

      const {
        email, id, role, lang, refresh_token,
      } = parseJwt(access_token);
      const setUser = {
        email,
        id,
        role,
        access_token,
        refresh_token,
      };
      storage.save('user', setUser);
      storage.save('lang', lang);
    }
  };
  return {
    loading,
    _refreshUser,
    error,
    data,
  };
}
