import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import style from './style.module.css';
import { GetState, getCurrenLang, getMessengerState } from '../../redux/selector';
import { CHANNEL_TYPE } from '../../utils/enums';
import { MessengerState } from '../../redux/messenger/types';
import { Room } from '../../components/Messenger';
import ChannelPreferences from '../../components/Messenger/Room/ChannelPreferences/ChannelPreferences';
import { ContentConfigType } from './types';
import useChat from '../../hooks/useChat';
import ManageMembers from '../../components/Messenger/Room/ManageMembers/ManageMembers';
import { WarnNotifModal } from '../../common/NotificationModal';
import { useSocket } from '../../ SocketContext';

const MessengerPage = (): ReactElement => {
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const direct_message_preferences =
    t?.chat?.direct_message_preferences || 'Direct Message Preferences';
  const channel_preferences = t?.chat?.channel_preferences || 'Channel Preferences';
  const close_direct_message = t?.chat?.close_direct_message || 'Close direct message';
  const leave_channel = t?.chat?.leave_channel || 'Leave channel';
  const sure_want_leave_channel =
    t?.chat?.sure_want_leave_channel || 'Are you sure you want to leave the channel?';
  const if_you_want_rejoin =
    t?.chat?.if_you_want_rejoin ||
    'If you want to rejoin, just create a new channel and search for the patient.';

  const { socket }: any = useSocket();
  const { closeConversation, leaveChannel } = useChat(socket);

  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoom } = messengerState;

  // Local state
  const [isWarningModal, setWarningModal] = useState(false);

  const { prefTitle, contentConfig } = useMemo(() => {
    let title = '';
    const config: ContentConfigType[] = [];
    if (activeRoom) {
      const activeRoomId = activeRoom.id;
      if (activeRoom.type === CHANNEL_TYPE.GROUP) {
        title = channel_preferences;
        config.push({
          action: (): void => setWarningModal(true),
          description: leave_channel,
        });
      } else if (activeRoom.type === CHANNEL_TYPE.DIRECT) {
        title = direct_message_preferences;
        config.push({
          action: () => closeConversation(activeRoomId),
          description: close_direct_message,
        });
      }
    }
    return {
      prefTitle: title,
      contentConfig: config,
    };
  }, [activeRoom]);

  // Leave from channel
  const leaveFromChannel = (): void => {
    if (activeRoom) {
      leaveChannel(activeRoom.id);
      setWarningModal(false);
    }
  };

  const warnContent = (
    <>
      <div className={style['msg__warning-content']}>{sure_want_leave_channel}</div>
      <div className={style['msg__warning-content']}>{if_you_want_rejoin}</div>
    </>
  );

  return (
    <div className={style['msg__flex-wrapper']}>
      <div className={style['msg__left-section']}>
        <Room />
      </div>
      {activeRoom && (
        <div className={style['msg__right-section']}>
          {/* Members */}
          {activeRoom?.type === CHANNEL_TYPE.GROUP && <ManageMembers />}
          {activeRoom?.type === CHANNEL_TYPE.GROUP && <div className={style.msg__dash} />}

          {/* Preferences */}
          <ChannelPreferences title={prefTitle} contentConfig={contentConfig} />
        </div>
      )}

      {/* Popups */}
      {isWarningModal && (
        <WarnNotifModal
          onClose={(): void => setWarningModal(false)}
          content={warnContent}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={leaveFromChannel}
        />
      )}
    </div>
  );
};

export default MessengerPage;
