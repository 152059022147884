import React, { ReactElement, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import style from './styles.module.css';
import { useChangeIsNotifSendingToPatients } from '../../graphql/hcp';

import {
  GetState,
  getCurrenLang,
  getEmailNotifSettingsData,
  getNotifToMeData,
} from '../../redux/selector';
import { setOpenSettingsData } from '../../redux/emailNotifications';
import { EMAIL_NOTIF_TYPE } from '../../utils/enums';
import { getReminderPeriodOptions } from '../../utils/share/options';
import { ReminderPeriodOptionsType } from '../../utils/model';
import { NotifToPatientsSettingsForm } from './NotifToPatientsSettingsForm';

const NotifToMyPatients = ({ updateData }: any): ReactElement => {
  const t: any = useSelector<any>((state: GetState) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const settings_t = t?.dashboard.hcp.profile_patient.video_bank.settings;
  const inactive_t = t?.dashboard.hcp.profile_patient.completion_and_steps.inactive;
  const wound_images = t?.menu.wound_images;
  const remind_patients = t?.hcp.manage.remind_patients;
  const send_reminder_for_my_patients = t?.hcp.manage.send_reminder_for_my_patients;

  // Endpoints
  const {
    _changeIsNotifSendingToPatients,
    changeIsNotifSendingToPatientsLoading,
    changeIsNotifSendingToPatientsError,
    changedIsNotifSendingToPatients,
  } = useChangeIsNotifSendingToPatients();

  // Redux
  const data: any = useSelector((state: GetState) => getNotifToMeData(state));
  const openSettingsData: any = useSelector((state: GetState) => getEmailNotifSettingsData(state));
  const isActive = openSettingsData?.type === EMAIL_NOTIF_TYPE.WOUND_IMAGES;

  // Variables
  const id = data?.id;
  const isEdit = !!id;
  const dispatch = useDispatch();
  const reminderPeriodOptions = useMemo(() => getReminderPeriodOptions(t), [t]);
  const getRemindersPeriodValue = (
    timePeriod: number | undefined | null,
    amount: number | undefined | null,
  ): ReminderPeriodOptionsType | any => {
    if (timePeriod && amount) {
      const result = reminderPeriodOptions.filter(
        (item: ReminderPeriodOptionsType) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          item.timePeriod === timePeriod && item.amount === amount,
      );
      return result?.[0] || '';
    }
    return '';
  };
  const remindersPeriodOption = getRemindersPeriodValue(data?.timePeriod, data?.amount);
  const send_reminder_for_my_patients_current = remindersPeriodOption
    ? send_reminder_for_my_patients?.replace('<period>', remindersPeriodOption.label)
    : '';

  // If update status Hcp Email Notification success then update data
  useEffect(() => {
    if (changedIsNotifSendingToPatients) {
      updateData();
    }
  }, [changedIsNotifSendingToPatients]);

  // If update status Hcp Email Notification error then show message
  useEffect(() => {
    if (changeIsNotifSendingToPatientsError) {
      toast.error(changeIsNotifSendingToPatientsError);
    }
  }, [changeIsNotifSendingToPatientsError]);

  // Turn on (off) notification
  const changeEmailNotifTypeStatus = (checked: boolean, sectionId: number): void => {
    _changeIsNotifSendingToPatients({
      notifData: {
        id: sectionId,
        isSendNotification: checked,
      },
    });
  };

  // Open settings
  const openSettings = (): void => {
    if (isActive && data) {
      dispatch(setOpenSettingsData(undefined));
    } else {
      dispatch(
        setOpenSettingsData({
          ...data,
          type: EMAIL_NOTIF_TYPE.WOUND_IMAGES,
          title: wound_images,
          description: remind_patients,
        }),
      );
    }
  };

  return (
    <div className={style.row__wrapper}>
      <div className={style.row}>
        <div className={style['email-notif__title-container']}>
          <div className={style['email-notif__title']}>{wound_images}</div>
          <div className={style['email-notif__description']}>{remind_patients}</div>
          {!isEdit ? <div className={style['email-notif__description']}>{inactive_t}</div> : <></>}
        </div>

        <div className={style['email-notif__switch-wrapper']}>
          <div
            className={cx({
              [style['email-notif__settings-btn']]: true,
              [style['email-notif__settings-btn--active']]: isActive,
            })}
            onClick={openSettings}
            aria-hidden
          >
            {settings_t}
          </div>
        </div>
      </div>

      {!!id && (
        <div className={style.row}>
          <div className={style['email-notif__title-container']}>
            <div className={style['email-notif__description']}>
              {send_reminder_for_my_patients_current}
            </div>
          </div>

          <div className={style['email-notif__switch-wrapper']}>
            <div className={style['email-notif__switch']}>
              <Switch
                checkedChildren={<span>{on}</span>}
                unCheckedChildren={<span>{off}</span>}
                loading={changeIsNotifSendingToPatientsLoading}
                checked={data?.isSendNotification}
                onChange={(checked: boolean): void => changeEmailNotifTypeStatus(checked, id)}
              />
            </div>
          </div>
        </div>
      )}
      <div className={style['email-notif__settings-wrapper--sm']}>
        {/* Settings */}
        {openSettingsData?.type === EMAIL_NOTIF_TYPE.WOUND_IMAGES && (
          <NotifToPatientsSettingsForm
            openSettingsData={openSettingsData}
            updateData={updateData}
          />
        )}
      </div>
    </div>
  );
};

export default NotifToMyPatients;
