import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import styles from '../styles.module.css';
import { GetState, getMessengerState } from '../../../../redux/selector';
import { CHANNEL_TYPE } from '../../../../utils/enums';
import { MessengerState } from '../../../../redux/messenger/types';
import ItemAvatar from '../../ItemAvatar/ItemAvatar';

const RoomHeader = (): ReactElement => {
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoom } = messengerState;

  const roomName = activeRoom?.name || '';

  // JSX
  const getAvatar = (): JSX.Element => {
    if (activeRoom) {
      if (activeRoom.type === CHANNEL_TYPE.DIRECT) {
        return <ItemAvatar room={activeRoom} isShowStatus />;
      }
      return <div className={styles['room-header__icon']}>#</div>;
    }

    return <></>;
  };

  return (
    <div className={styles['room-header']}>
      <div className={styles['room-header__name-container']}>
        {getAvatar()}
        <div className={styles['room-header__name']}>{roomName}</div>
      </div>
    </div>
  );
};

export default RoomHeader;
