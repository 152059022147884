import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { GetState, getCurrenLang, getHcpName } from '../../../../redux/selector';
import { Loading } from '../../../../common';
import { deepCopyObj, getExercisesCategories, getSelectOptions } from '../../../../utils/helper';

import { path } from '../../../../utils/routers/book';
import { Popup } from '../../../../common/Popup';
import { useCreateHospitalDocument, useGetHospitalNames } from '../../../../graphql/hospitals';
import { uploadVideoForPatientInfoByHcp } from '../../../../utils/validators';

import { config } from '../../../../utils/configs';
import useCheckRole from '../../../../hooks/useCheckRole';
import useGetFocuses from '../../../../hooks/useGetFocuses';
import MediaSettings from '../MediaSettings';
import { SuccessNotifModal, WarnNotifModalOneButton } from '../../../../common/NotificationModal';
import { imageTypes, pdfType, videoTypes, wordTypes } from '../../../../utils/variables';

const UploadPatientInformation = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const ok = t?.common.ok;
  const warn_message = t?.hcp.manage_patient_information?.warn_message;
  const large_file = t?.help.large_file;
  const large_file_50 = large_file?.replace('<size>', '50MB');
  const wrong_format_raw = t?.help.wrong_format;
  const wrong_format = wrong_format_raw?.split('.')[0];
  const video_successfully_uploaded = t?.hcp.manage_patient_information.video_successfully_uploaded;
  const allTypes = [...imageTypes, ...wordTypes, ...videoTypes, ...pdfType];
  const allowedTypeFile = allTypes.toString();

  const history = useHistory();
  const { isAdmin, token } = useCheckRole();
  const filesSizeLimit = 52428800; // max 50Mb
  const { uri, bucketName, videoServiceUrl } = config;
  const uriWithoutGraph = uri?.split('/api')[0];
  const { focusType1Arr, exercisesProcedureFocus, loadingFocuses } = useGetFocuses();
  const initErrState = {
    error: false,
    text: '',
  };
  const currentUser: any = useSelector((state: GetState) => getHcpName(state));

  // Endpoints
  // const {
  //   _createHospitalVideo,
  //   errorCreateHospitalVideo,
  //   loadingCreateHospitalVideo,
  //   hospitalVideoCreated,
  // } = useCreateHospitalVideo();
  const {
    _createHospitalDocument,
    errorCreateHospitalDocument,
    loadingCreateHospitalDocument,
    hospitalDocumentCreated,
  } = useCreateHospitalDocument();
  const { hospitalNames } = useGetHospitalNames(!isAdmin);
  const hospitalNameOptions = useMemo(() => getSelectOptions(hospitalNames), [hospitalNames]);

  // Local state
  const [fileInputKey, setFileInputKey] = useState<number>(Date.now());
  const [errorMessage, setErrorMessage] = useState(deepCopyObj(initErrState));
  const [isWarningModalOpen, setWarningModal] = useState(false);
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const focusByprocedures = useMemo(() => {
    const focusByproceduresMemo: any = {};
    focusType1Arr.map((procedure: any, i: number) => {
      focusByproceduresMemo[`procedure${i + 1}`] = false;
      return null;
    });
    return focusByproceduresMemo;
  }, [focusType1Arr]);

  // const initialValues = useMemo(
  //   () => ({
  //     isAllowDownloadVideos: true,
  //     file: '',
  //     fileName: '',
  //     description: '',
  //     name: '',
  //     organisationName: '',
  //     // ...focusByprocedures,
  //   }),
  //   [Object.keys(focusByprocedures)],
  // );
  const initialValues = {
    isAllowDownloadVideos: true,
    file: '',
    fileName: '',
    description: '',
    name: '',
    organisationName: '',
    procedure1: false,
    procedure2: false,
    procedure3: false,
    procedure4: false,
    procedure5: false,
    procedure6: false,
    procedure7: false,
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: () => uploadVideoForPatientInfoByHcp(t, isAdmin, false),
    onSubmit: (value: any): any => {
      const ext = value.fileName.split('.').pop();
      if (!ext) {
        setErrorMessage({
          error: true,
          text: wrong_format,
        });
        return;
      }
      const request = {
        procedureTypesId: getExercisesCategories(value, exercisesProcedureFocus, 'procedure'),
        documentData: {
          hospitalId: isAdmin ? value.organisationName.value : currentUser.hospitalId,
          name: value.name.trim(),
          fileName: value.fileName,
          description: value.description.trim(),
          isAllowDownloadVideos: value.isAllowDownloadVideos,
        },
      };
      _createHospitalDocument({
        variables: request,
      });

      // const request: any = {
      //   fileData: null,
      //   videoData: null,
      // };
      // if (videoTypes.includes(ext)) {
      //   request.videoData = {
      //     hospitalId: isAdmin ? value.organisationName.value : currentUser.hospitalId,
      //     name: value.name.trim(),
      //     fileName: value.fileName,
      //     description: value.description.trim(),
      //     isAllowDownloadVideos: value.isAllowDownloadVideos,
      //     procedureTypeIds: getExercisesCategories(value, exercisesProcedureFocus, 'procedure'),
      //   };
      // } else {
      //   request.fileData = {
      //     hospitalId: isAdmin ? value.organisationName.value : currentUser.hospitalId,
      //     name: value.name.trim(),
      //     fileName: value.fileName,
      //     description: value.description.trim(),
      //     procedureTypeIds: getExercisesCategories(value, exercisesProcedureFocus, 'procedure'),
      //   };
      // }
      console.log('777_req=', request);

      // _createHospitalVideo({
      //   variables: request,
      // });
    },
  });

  const { values } = formik;

  // Save video file to AWS
  const uploadDocument = async (documentId: number): Promise<void> => {
    setIsLoading(() => true);
    const formData = new FormData();
    formData.append('file', values.file);
    const ext = values.fileName.split('.').pop();

    if (!ext) {
      setErrorMessage({
        error: true,
        text: wrong_format,
      });
      return;
    }
    let urlSecondPart = `video/uploadFromHospital/${documentId}`;
    if (!videoTypes.includes(`.${ext}`)) {
      urlSecondPart = `pictures/upload-hospital-document-pic/${documentId}`;
    }
    let videoServUrl = `${uriWithoutGraph}/video_api/${urlSecondPart}`;
    switch (bucketName) {
      case 'otm-stage':
      case 'onthemend-en':
      case 'onthemend-us':
        videoServUrl = `${videoServiceUrl}${urlSecondPart}`;
        break;
      default:
        break;
    }
    axios
      .post(videoServUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setSuccessModal(() => true);
      })
      .catch((e) => {
        setWarningModal(() => true);
      })
      .finally(() => setIsLoading(() => false));
  };

  // If create video error show message
  useEffect(() => {
    if (errorCreateHospitalDocument) {
      toast.error(errorCreateHospitalDocument);
    }
  }, [errorCreateHospitalDocument]);

  // After save options to DB then save videofile to AWS
  useEffect(() => {
    if (hospitalDocumentCreated) {
      uploadDocument(hospitalDocumentCreated);
    }
  }, [hospitalDocumentCreated]);

  // Check incoming file
  const onCheckIncomingFile = async (e: any): Promise<void> => {
    setFileInputKey(Date.now());
    formik.setErrors({});
    try {
      const f = e.target.files[0];
      if (f) {
        const fileName = f.name.toLowerCase();
        const { type } = f;
        if (!isAdmin && f.size > filesSizeLimit) {
          setErrorMessage({
            error: true,
            text: large_file_50,
          });
        } else {
          const normalizeFile = new File([f], fileName, { type });
          formik.setFieldValue('fileName', fileName);
          formik.setFieldValue('file', normalizeFile);
        }
      }
    } catch (error) {
      setErrorMessage({
        error: true,
        text: wrong_format,
      });
    }
  };

  // Cancel changes and redirect to page Organisation PI
  const onCancel = (): void => {
    formik.resetForm();
    const pathname = isAdmin
      ? path.adminOrganisationPatientInformation
      : path.organisationPatientInformation;
    history.push({ pathname });
  };

  // Clouse modal window and redirect to Organisation PI page
  const oncloseModal = (): void => {
    setWarningModal(() => false);
    setSuccessModal(() => true);
    onCancel();
  };

  // JSX
  const loadingJSX = (isLoading || loadingCreateHospitalDocument || loadingFocuses) && <Loading />;

  return (
    <div>
      {loadingJSX}
      <div>
        {exercisesProcedureFocus && focusType1Arr.length && (
          <MediaSettings
            index={1}
            formik={formik}
            focusType1Arr={focusType1Arr}
            exercisesProcedureFocus={exercisesProcedureFocus}
            isAdmin={isAdmin}
            hospitalNameOptions={hospitalNameOptions}
            onCheckIncomingFile={onCheckIncomingFile}
            fileInputKey={fileInputKey}
            onCancel={onCancel}
            loadingJSX={loadingJSX}
            isEdit={false}
            allowedTypeFile={allowedTypeFile}
          />
        )}
      </div>

      {/* Popups */}
      {errorMessage.error && (
        <Popup
          content=""
          title={errorMessage.text}
          buttonName={ok}
          onClosePopup={(): void => setErrorMessage(JSON.parse(JSON.stringify(initErrState)))}
        />
      )}

      {/* Warning if downloading file may take a long time */}
      {isWarningModalOpen && (
        <WarnNotifModalOneButton onClose={oncloseModal} description={warn_message} btnName={ok} />
      )}

      {/* Success upload file */}
      {isSuccessModalOpen && (
        <SuccessNotifModal
          onClose={oncloseModal}
          description={video_successfully_uploaded}
          btnName={ok}
        />
      )}
    </div>
  );
};

export default UploadPatientInformation;
