import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { MessengerIcon } from '../../../theme/icons';
import { BellIconProps } from '../types';

const ChatIcon = (props: BellIconProps): ReactElement => {
  const { showNotifications, countUnread, notificationsList } = props;

  return (
    <div
      role="presentation"
      onClick={showNotifications}
      className={styles['notification-icon-container']}
    >
      <MessengerIcon />
      <div
        className={cx({
          [styles['notification-counter']]: true,
          [styles.visible]: notificationsList?.length && countUnread,
        })}
      >
        {countUnread > 9 ? '9+' : countUnread}
      </div>
    </div>
  );
};

export default ChatIcon;
