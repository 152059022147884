import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import useHandleGqlError from '../../hooks/useHandleGqlError';
import {
  setPatientTeamList,
  setPatientTeamListError,
  setPatientTeamListLoading,
  setPatientTeamTotalItems,
  setSearchPatientTeamItems,
  setSearchPatientTeamLoading,
  setSearchTeamItems,
  setSearchTeamLoading,
  setTeamList,
  setTeamListError,
  setTeamListLoading,
  setTotalItems,
} from '../../redux/rehabTeams';
import { config } from '../../utils/configs';
import {
  GetAllHospitalTeamsReq,
  GetPatientTeamReq,
  SaveTeamRequest,
} from '../../pages/Hcp/RehabTeams/types';

// Query
const queryGetAllHospitalTeams = loader('./gql/queryGetAllHospitalTeams.graphql');
const queryGetAllHospitalTeamsMatch = loader('./gql/queryGetAllHospitalTeamsMatch.graphql');
const queryGetHospitalTeamsForHcp = loader('./gql/queryGetHospitalTeamsForHcp.graphql');
const queryGetHospitalTeamsForHcpMatch = loader('./gql/queryGetHospitalTeamsForHcpMatch.graphql');
const queryGetHospitalPatients = loader('./gql/queryGetHospitalPatients.graphql');
const queryGetTeamsForHcpHospital = loader('./gql/queryGetTeamsForHcpHospital.graphql');
const queryGetPatientRehabTeam = loader('./gql/queryGetPatientRehabTeam.graphql');
const queryGetPatientRehabTeamMatch = loader('./gql/queryGetPatientRehabTeamMatch.graphql');
const queryHcpsForPatientRehabTeam = loader('./gql/queryGetHcpsForPatientRehabTeam.graphql');

// Mutation
const mutationSaveHospitalTeam = loader('./gql/mutationSaveHospitalTeam.graphql');
const mitationChangeAdminRightsForHcp = loader('./gql/mutationChangeAdminRightsForHcp.graphql');
const mutationDeleteHospitalTeam = loader('./gql/mutationDeleteHospitalTeam.graphql');
const mutationAddHcpsToPatient = loader('./gql/mutationAddHcpsToPatientRehabTeam.graphql');

// Get All teams for manadge teams page
export const useGetAllHospitalTeams = () => {
  const [_onGetAllHospitalTeams, { loading, error, data }] = useLazyQuery(
    queryGetAllHospitalTeams,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const dispatch = useDispatch();
  let teamsList = null;
  let totalItems = 0;
  const textErr = useHandleGqlError(error);

  useEffect(() => {
    if (data && !loading) {
      const response = data.getAllHospitalTeams;
      teamsList = response.list;
      totalItems = response.totalItems;
      dispatch(setTeamList(teamsList));
      dispatch(setTotalItems(totalItems));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setTeamListLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (textErr) {
      dispatch(setTeamListError(textErr));
    }
    dispatch(setTeamListError(undefined));
  }, [textErr]);

  const _getAllHospitalTeams = (req: GetAllHospitalTeamsReq): void => {
    _onGetAllHospitalTeams({ variables: req });
  };

  return {
    _getAllHospitalTeams,
    data,
    teamsList,
    totalItems,
    getTeamError: textErr,
    teamsLoading: loading,
  };
};

// Get the number of matches of a search for Manage Teams page
export const useGetAllHospitalTeamsMatch = () => {
  const [_onGetAllHospitalTeamsMatch, { loading, error, data }] = useLazyQuery(
    queryGetAllHospitalTeamsMatch,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let totalItemsMatch: any = null;

  useEffect(() => {
    if (data && !loading) {
      const response = data.getAllHospitalTeams;
      totalItemsMatch = response.totalItems;
      dispatch(setSearchTeamItems(totalItemsMatch));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setSearchTeamLoading(loading));
  }, [loading]);

  const _getAllHospitalTeamsMatch = (req: GetAllHospitalTeamsReq): void => {
    _onGetAllHospitalTeamsMatch({ variables: req });
  };

  return {
    _getAllHospitalTeamsMatch,
    totalItemsMatch,
    teamsLoadingMatch: loading,
    dataMatch: data,
  };
};

// Get teams for My Teams page
export const useGetHospitalTeamsForHcp = () => {
  const [_onGetHospitalTeamsForHcp, { loading, error, data }] = useLazyQuery(
    queryGetHospitalTeamsForHcp,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let teamsList = null;
  let totalItems = 0;
  const textErr = useHandleGqlError(error);

  useEffect(() => {
    if (data && !loading) {
      const response = data.getHospitalTeamsForHcp;
      teamsList = response.list;
      totalItems = response.totalItems;
      dispatch(setTeamList(teamsList));
      dispatch(setTotalItems(totalItems));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setTeamListLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (textErr) {
      dispatch(setTeamListError(textErr));
    }
    dispatch(setTeamListError(undefined));
  }, [textErr]);

  const _getHospitalTeamsForHcp = (req: GetAllHospitalTeamsReq): void => {
    _onGetHospitalTeamsForHcp({ variables: req });
  };

  return {
    _getHospitalTeamsForHcp,
    teamsList,
    data,
    totalItems,
    getTeamError: textErr,
    teamsLoading: loading,
  };
};

// Get the number of matches of a search for My Teams page
export const useGetHospitalTeamsForHcpMatch = () => {
  const [_onGetHospitalTeamsForHcpMatch, { loading, error, data }] = useLazyQuery(
    queryGetHospitalTeamsForHcpMatch,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const dispatch = useDispatch();
  let totalItemsMatch: any = null;

  useEffect(() => {
    if (data && !loading) {
      const response = data.getHospitalTeamsForHcp;
      totalItemsMatch = response.totalItems;
      dispatch(setSearchTeamItems(totalItemsMatch));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setSearchTeamLoading(loading));
  }, [loading]);

  const _getHospitalTeamsForHcpMatch = (req: GetAllHospitalTeamsReq): void => {
    _onGetHospitalTeamsForHcpMatch({ variables: req });
  };

  return {
    _getHospitalTeamsForHcpMatch,
    dataMatch: data,
    totalItemsMatch,
    teamsLoadingMatch: loading,
  };
};

export const useSaveHospitalTeam = () => {
  const [_onSaveHospitalTeam, { data, errorText, loading }] = useMutationRequest<{
    saveHospitalTeam: boolean;
  }>(mutationSaveHospitalTeam, { fetchPolicy: 'no-cache' });
  const _saveHospitalTeam = (req: SaveTeamRequest): void => {
    _onSaveHospitalTeam({
      variables: req,
    });
  };

  return {
    _saveHospitalTeam,
    hospitalTeamSaved: data && data.saveHospitalTeam,
    saveHospitalTeamError: errorText,
    saveHospitalTeamLoading: loading,
  };
};

// Change Admin right for HCP by HCP
export const useChangeAdminRightsForHcp = () => {
  const [_onChangeAdminRightsForHcp, { data, errorText, loading }] = useMutationRequest<{
    changeAdminRightsForHcp: boolean;
  }>(mitationChangeAdminRightsForHcp, {});

  const _changeAdminRightsForHcp = (req: { hcpId: number; solution: boolean }): void => {
    _onChangeAdminRightsForHcp({ variables: req });
  };

  return {
    _changeAdminRightsForHcp,
    adminRightsForHcpChanged: data?.changeAdminRightsForHcp,
    changeAdminRightsForHcpError: errorText,
    changeAdminRightsForHcpLoading: loading,
  };
};

// Delete hospital team
export const useDeleteHospitalTeam = () => {
  const [_onDeleteHospitalTeam, { data, errorText, loading }] = useMutationRequest<{
    deleteHospitalTeam: boolean;
  }>(mutationDeleteHospitalTeam, {});

  const _deleteHospitalTeam = (req: { hospitalTeamId: number }): void => {
    _onDeleteHospitalTeam({ variables: req });
  };

  return {
    _deleteHospitalTeam,
    hospitalTeamdeleted: data?.deleteHospitalTeam,
    deleteHospitalTeamError: errorText,
    deleteHospitalTeamLoading: loading,
  };
};

// Get patient list for search patients for teams
export const useGetHospitalPatientsForSearch = () => {
  const [_getPatientListForSearchHcp, { loading, data }] = useLazyQuery(queryGetHospitalPatients, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });
  const patients = data && data.getHospitalPatients;

  let patientList = [];
  let total = null;

  if (patients) {
    patientList = patients.list;
    total = patients.totalItems;
  }

  return {
    _getPatientListForSearchHcp,
    patientList,
    total,
    patientListLoading: loading,
    dataPatient: data,
  };
};

// Team list
export const useGetTeamNamesForHcpHospital = () => {
  const [_getTeamNames, { loading, error, data }] = useLazyQuery(queryGetTeamsForHcpHospital, {});

  return {
    _getTeamNames,
    teamNames: data ? data.getTeamsForHcpHospital : null,
    errorTeamNames: error,
    loadingTeamNames: loading,
  };
};

// Get teams for page Patient Rehab Team
export const useGetPatientRehabTeam = () => {
  const [_onGetPatientRehabTeam, { loading, error, data }] = useLazyQuery(
    queryGetPatientRehabTeam,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      // pollInterval: config.pollInterval,
    },
  );
  const dispatch = useDispatch();
  let teamsList = null;
  let totalItems = 0;
  const textErr = useHandleGqlError(error);

  useEffect(() => {
    if (data && !loading) {
      const response = data.getPatientRehabTeams;
      teamsList = response.list;
      totalItems = response.totalItems;
      dispatch(setPatientTeamList(teamsList));
      dispatch(setPatientTeamTotalItems(totalItems));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setPatientTeamListLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (textErr) {
      dispatch(setPatientTeamListError(textErr));
    }
    dispatch(setPatientTeamListError(undefined));
  }, [textErr]);

  const _getPatientRehabTeam = (req: GetPatientTeamReq): void => {
    _onGetPatientRehabTeam({ variables: req });
  };

  return {
    _getPatientRehabTeam,
  };
};

// Get the number of matches of a search for My Teams page
export const useGetPatientRehubTeamMatch = () => {
  const [_onGetPatientTeamMatch, { loading, error, data }] = useLazyQuery(
    queryGetPatientRehabTeamMatch,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const dispatch = useDispatch();
  let totalItemsMatch: any = null;

  useEffect(() => {
    if (data && !loading) {
      const response = data.getPatientRehabTeams;
      totalItemsMatch = response.totalItems;
      dispatch(setSearchPatientTeamItems(totalItemsMatch));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setSearchPatientTeamLoading(loading));
  }, [loading]);

  const _getPatientTeamMatch = (req: GetPatientTeamReq): void => {
    _onGetPatientTeamMatch({ variables: req });
  };

  return {
    _getPatientTeamMatch,
  };
};

// Add HCPs to patient rehab Team
export const useAddHcpsToPatient = () => {
  const [_onAddHcpsToPatient, { data, errorText, loading }] = useMutationRequest<{
    addHcpsToPatientRehabTeam: boolean;
  }>(mutationAddHcpsToPatient, { fetchPolicy: 'no-cache' });
  const _addHcpsToPatient = (req: { patientId: number; hcpIds: number[] }): void => {
    _onAddHcpsToPatient({
      variables: req,
    });
  };

  return {
    _addHcpsToPatient,
    addHcpsToPatientData: data && data.addHcpsToPatientRehabTeam,
    addHcpsToPatientError: errorText,
    addHcpsToPatientLoading: loading,
  };
};

// Get HCPs for patient rehab Team

// Get All teams for manadge teams page
export const useGetHcpsForPatientRehabTeam = () => {
  const [_onGetHcpsForPatient, { loading, error, data }] = useLazyQuery(
    queryHcpsForPatientRehabTeam,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: config.pollInterval,
    },
  );
  const textErr = useHandleGqlError(error);

  const _getHcpsForPatient = (
    patientId: number,
    searchValue: string,
    sortBy: string,
    sortDirection: string,
  ): void => {
    _onGetHcpsForPatient({
      variables: {
        patientId,
        searchProps: [
          {
            searchField: 'hcp.firstName',
            searchValue,
          },
          {
            searchField: 'hcp.lastName',
            searchValue,
          },
        ],
        sortProps: {
          sortBy,
          sortDirection,
        },
      },
    });
  };

  return {
    _getHcpsForPatient,
    data,
    hcpsForPatient: data && data.getHcpsForPatientRehabTeam,
    hcpsForPatientError: textErr,
    hcpsForPatientLoading: loading,
  };
};

// Find all HCPs for the current patient by condition
export const useGetHcpsForPatientMatch = () => {
  const [_onGetHcpsForPatientMatch, { loading, error, data }] = useLazyQuery(
    queryHcpsForPatientRehabTeam,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  const _getHcpsForPatientMatch = (patientId: number, searchValue: string): void => {
    _onGetHcpsForPatientMatch({
      variables: {
        patientId,
        searchProps: [
          {
            searchField: 'hcp.firstName',
            searchValue,
          },
          {
            searchField: 'hcp.lastName',
            searchValue,
          },
        ],
        sortProps: {
          sortBy: '',
          sortDirection: '',
        },
      },
    });
  };

  return {
    _getHcpsForPatientMatch,
    hcpsForPatientMatch: data && data.getHcpsForPatientRehabTeam,
    hcpsForPatientLoadingMatch: loading,
  };
};
