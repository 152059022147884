// @typescript-eslint/no-explicit-any
const save = (key: string, value: any): any => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    throw new Error();
  }
};
const get = (key: string): any => {
  try {
    const items = localStorage.getItem(key);
    return items ? JSON.parse(items) : null;
  } catch (err) {
    throw new Error();
  }
};
export default {
  save,
  get,
};
