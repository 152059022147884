import { lazy } from 'react';
import { path } from './book';
import {
  RehabTeamsPage,
  RewardsPage,
  PatientInvitationsPage,
  EmailNotificationsPage,
  OrganisationPatientInformationPage,
  OrganisationVideoBankPage,
  ExerciseFolderSettingPage,
  AddMediaPage,
  AddPhotoPage,
  AddVideoPage,
  UploadPatientInformationPage,
  NotificationPage,
  AnotherHcpProfilePage,
} from '../../pages/Hcp';
import DashboardListPage from '../../pages/DashboardList';
import ConnectPatientPage from '../../pages/Hcp/Connect';
import InviteHCPPage from '../../pages/Invite';
import ProfileUserPage from '../../pages/Hcp/ProfileUser';
import CreateTeamPage from '../../pages/Hcp/CreateTeam';
import RewardsAdminPage from '../../pages/Admin/Rewards';
import {
  AddPatientInformationPage,
  OverviewPage,
  PatientExerciseLibreryPage,
  PatientInformationPage,
  PatientRehabTeamPage,
  PrescriptionHistoryPage,
  ProfilePatientPage,
  RemoteMonitoringPage,
  WoundImagesPage,
} from '../../pages/Patient';
import ManageDepartmentsPage from '../../pages/Admin/ManageDepartments';
import ManagePromsPage from '../../pages/Admin/ManageProms';
import ManageOrganisationsPage from '../../pages/Admin/ManageOrganisations';
import ManageProfessionsPage from '../../pages/Admin/ManageProfessions';
import MessengerPage from '../../pages/Messenger';

export const privateRoutes = [
  {
    path: path.home,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/HomePage/hcp')),
    // component: lazy(() => import('../../components/Dashboard')),
  },
  {
    path: path.dashboard,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../components/Dashboard')),
  },
  {
    path: path.admin,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/HomePage/admin')),
  },
  {
    path: path.adminDashboard,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../components/Dashboard')),
  },

  /* New pathes */
  {
    path: path.notFoud,
    label: '404',
    exact: false,
    public: true,
    permissions: ['admin', 'hcp'],
    component: lazy(() => import('../../pages/NotFound')),
  },
  {
    path: path.organisationVideoBank,
    label: 'Organisation Video Bank',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: OrganisationVideoBankPage,
  },
  {
    path: path.rehabTeams,
    label: 'Rehab Teams',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: RehabTeamsPage,
  },
  {
    path: path.createTeam,
    label: 'Create Team',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: CreateTeamPage,
  },
  {
    path: path.organisationPatientInformation,
    label: 'Organisation Patient Information',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: OrganisationPatientInformationPage,
  },
  {
    path: path.adminOrganisationPatientInformation,
    label: 'Organisation Patient Information Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: OrganisationPatientInformationPage,
  },
  {
    path: path.rewards,
    label: 'Rewards',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: RewardsPage,
  },
  {
    path: path.rewardsAdmin,
    label: 'Rewards admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: RewardsAdminPage,
  },
  {
    path: path.patientInvitations,
    label: 'Patient Invitations',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: PatientInvitationsPage,
  },
  {
    path: path.emailNotifications,
    label: 'Email Notifications',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: EmailNotificationsPage,
  },
  {
    path: path.profile,
    label: 'My Profile',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: ProfileUserPage,
  },
  {
    path: path.all_notification,
    label: 'All Notification',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: NotificationPage,
  },
  {
    path: path.messenger,
    label: 'Messenger',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: MessengerPage,
  },
  {
    path: path.dashboardList,
    label: 'Dashboard List',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: DashboardListPage,
  },
  {
    path: path.adminDashboardList,
    label: 'Admin Dashboard List',
    exact: true,
    public: false,
    permissions: ['admin', 'hcp'],
    component: DashboardListPage,
  },
  {
    path: path.connectPatientAsHcp,
    label: 'Connect Patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: ConnectPatientPage,
  },
  {
    path: path.inviteHcpAsHcp,
    label: 'Invite HCP',
    exact: false,
    public: false,
    permissions: ['hcp'],
    component: InviteHCPPage,
  },
  {
    path: path.inviteHcpAsAdmin,
    label: 'Invite HCP',
    exact: false,
    public: false,
    permissions: ['admin'],
    component: InviteHCPPage,
  },
  {
    path: path.orgVideoBankFolder,
    label: 'Organisation video bank folder',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: ExerciseFolderSettingPage,
  },
  {
    path: path.addMedia,
    label: 'Add Media',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: AddMediaPage,
  },
  {
    path: path.addVideo,
    label: 'Add Video',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: AddVideoPage,
  },
  {
    path: path.addPhoto,
    label: 'Add Photo',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: AddPhotoPage,
  },
  {
    path: path.upload_patient_information,
    label: 'Upload patient information',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: UploadPatientInformationPage,
  },
  {
    path: path.upload_patient_information_admin,
    label: 'Upload patient information Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: UploadPatientInformationPage,
  },
  {
    path: path.patient_overview,
    label: 'Patient overview',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: OverviewPage,
  },
  {
    path: path.patient_overview_admin,
    label: 'Patient overview Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: OverviewPage,
  },
  {
    path: path.patient_profile,
    label: 'Profile patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: ProfilePatientPage,
  },
  {
    path: path.patient_profile_admin,
    label: 'Profile patient Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: ProfilePatientPage,
  },
  {
    path: path.patient_wound_images,
    label: 'Patient wound images',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: WoundImagesPage,
  },
  {
    path: path.patient_wound_images_admin,
    label: 'Patient wound images Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: WoundImagesPage,
  },
  {
    path: path.patient_remote_monitoring,
    label: 'Patient Remote Monitoring',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: RemoteMonitoringPage,
  },
  {
    path: path.patient_remote_monitoring_admin,
    label: 'Patient Remote Monitoring',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: RemoteMonitoringPage,
  },
  {
    path: path.patient_information,
    label: 'Patient information',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: PatientInformationPage,
  },
  {
    path: path.patient_information_add_info,
    label: 'Patient information Add Information',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: AddPatientInformationPage,
  },
  {
    path: path.patient_information_admin,
    label: 'Patient information',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: PatientInformationPage,
  },
  {
    path: path.patient_wound_images_admin,
    label: 'Patient information Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: PatientInformationPage,
  },
  {
    path: path.patient_rehab_team,
    label: 'Patient Rehab Team',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: PatientRehabTeamPage,
  },
  // {
  //   path: path.patient_rehab_team_admin,
  //   label: 'Patient Rehab Team Admin',
  //   exact: true,
  //   public: false,
  //   permissions: ['admin'],
  //   component: PatientRehabTeamPage,
  // },
  {
    path: path.patient_exercise_library,
    label: 'Patient Exercise Library',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: PatientExerciseLibreryPage,
  },
  {
    path: path.patient_prescription_history,
    label: 'Patient Prescription History',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: PrescriptionHistoryPage,
  },
  {
    path: path.hcp_profile,
    label: 'Hcp profile',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: AnotherHcpProfilePage,
  },
  {
    path: path.hcp_profile_admin,
    label: 'Hcp profile Admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: AnotherHcpProfilePage,
  },
  {
    path: path.manage_departments,
    label: 'Manage Deportmants',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: ManageDepartmentsPage,
  },
  {
    path: path.manage_proms,
    label: 'Manage proms',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: ManagePromsPage,
  },
  {
    path: path.manage_organisations,
    label: 'Manage organisations',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: ManageOrganisationsPage,
  },
  {
    path: path.manage_professions,
    label: 'Manage professions',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: ManageProfessionsPage,
  },

  /* *********************** */
  {
    path: path.manage_exercises,
    label: 'Manage exercises',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageExercises')),
  },
  {
    path: path.upload_video_for_patient,
    label: 'Upload video for patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/UploadMyVideo')),
  },
  {
    path: path.record_video_for_patient,
    label: 'Record video for patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/RecordMyVideo')),
  },
  {
    path: path.from_patient,
    label: 'From patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/FromPatient')),
  },
  {
    path: path.success_delete_hcp_account,
    label: 'Success delete hcp account',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SuccessMessage/SuccessDeletedHcpAccount')),
  },
];

export const publicRouters = [
  // Sign In
  {
    path: path.signIn,
    label: 'Sign In',
    exact: true,
    public: true,
    permissions: ['hcp'],
    componentProps: {
      isAdmin: false,
    },
    component: lazy(() => import('../../pages/SignIn')),
  },
  {
    path: path.adminSignIn,
    label: 'Sign In',
    exact: true,
    public: true,
    permissions: ['admin'],
    componentProps: {
      isAdmin: true,
    },
    component: lazy(() => import('../../pages/SignIn')),
  },

  // Reset password via email
  {
    path: path.resetEmail,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ResetPassword/Email')),
  },
  {
    path: path.resetPassword,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ResetPassword/Password')),
  },
  {
    path: path.resetAdminEmail,
    label: 'Reset password Admin',
    exact: true,
    public: true,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ResetPassword/Email')),
  },
  {
    path: path.resetAdminPassword,
    label: 'Reset password Admin',
    exact: true,
    public: true,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ResetPassword/Password')),
  },

  // Sign Up
  {
    path: path.signUp,
    label: 'Sign Up',
    exact: false,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp')),
  },

  // To Mobile App
  {
    path: path.signUpForMobile,
    label: 'Sign In',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.questionnaireForMobile,
    label: 'questionnaire reminder',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.resetPasswordMobile,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.sharingPatientResult,
    label: 'Sharing result',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.resetPinMobile,
    label: 'Reset pin',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.connectPatientWithCodeMobile,
    label: 'Connect with code',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.loginPatientWithCodeMobile,
    label: 'Patient login  with code',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToQuestionnairesMobile,
    label: 'Patients questionnaire',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToAchievementsMobile,
    label: 'Patients Achievements',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToExerciseLibraryMobile,
    label: 'Patients Exercise Library',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToRewardsMobile,
    label: 'Patients Rewards',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToProcedureAndTeamMobile,
    label: 'Patients Procedure And Team',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.patientVerifyEmail,
    label: 'Patient Verify Email',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.signinPageMobile,
    label: 'Patient Sign In',
    exact: true,
    public: true,
    permissions: ['patient'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.signupPageMobile,
    label: 'Patient Sign Up',
    exact: true,
    public: true,
    permissions: ['patient'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },

  // Garmin integration
  {
    path: path.garminConnection,
    label: 'Garmin integration',
    exact: true,
    public: true,
    permissions: ['patient'],
    component: lazy(() => import('../../pages/GarminConnection/GarminConnection')),
  },
  {
    path: path.garminVerified,
    label: 'Garmin integration',
    exact: true,
    public: true,
    permissions: ['patient'],
    component: lazy(() => import('../../pages/GarminConnection/GarminVerified')),
  },
];

// OLD
