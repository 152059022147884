import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/react-hooks';
import storage from '../../utils/storage';
import { useRefreshToken } from '../../graphql/auth/useUserSignIn/refreshToken';
import { getCurrenLang } from '../../redux/selector';

export function useMutationRequest<TData = any, TVariables = Record<string, any>>(
  qraph: any,
  options: MutationHookOptions<TData, TVariables>,
) {
  const [errorText, setErrorText] = useState<string>();
  const [_method, mutationData] = useMutation<TData, TVariables>(qraph, options);
  const { _refreshUser } = useRefreshToken();
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const error_occurred_try_later = t?.dashboard.hcp.profile_patient.error_occurred_try_later;

  const method: MutationTuple<TData, TVariables>[0] = useCallback(
    async (options) => {
      try {
        setErrorText('');
        const data = await _method(options);
        return data;
      } catch (error) {
        if (
          error
          && error.graphQLErrors
          && error.graphQLErrors[0]
          && error.graphQLErrors[0].extensions
          && error.graphQLErrors[0].extensions.code
          && error.graphQLErrors[0].extensions.code === 'VALIDATION_ERROR'
        ) {
          setErrorText(error.graphQLErrors[0].extensions.message);
        } else if (
          error
          && error.graphQLErrors
          && error.graphQLErrors[0]
          && error.graphQLErrors[0].message
        ) {
          setErrorText(error.graphQLErrors[0].message);
        } else if (
          error
          && error.graphQLErrors
          && error.graphQLErrors[0]
          && error.graphQLErrors[0].extensions
          && error.graphQLErrors[0].extensions.exception
          && error.graphQLErrors[0].extensions.exception.status === 'UNAUTHORIZED'
        ) {
          try {
            const token = storage.get('user');
            await _refreshUser(token && token.refresh_token);

            if (error) {
              // user with issues
              setErrorText(error && error.message);
            }
          } catch (error) {
            // connect to the server
            if (error) {
              setErrorText(error);
            }
          }
        } else {
          setErrorText(error_occurred_try_later);
        }
      }
      return {};
    },
    [_method],
  );
  return [method, { data: mutationData.data, errorText, loading: mutationData.loading }] as [
    typeof method,
    { data: TData; errorText: string; loading: boolean },
  ];
}
