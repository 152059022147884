import { loader } from 'graphql.macro';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { UseLogoutProps } from '../types';
import { path, storage } from '../../utils';
import useChat from '../../hooks/useChat';
import { useSocket } from '../../ SocketContext';
import { clearMessengerState } from '../../redux/messenger';
import { useEffect } from 'react';

// Mutations
const mutationLogoutAdmin = loader('./gql/mutationAdminLogout.graphql');
const mutationLogoutHcp = loader('./gql/mutationLogout.graphql');

// Logout
export const useLogout = (isAdmin: boolean): UseLogoutProps => {
  const [logout, { errorText, data, loading }] = useMutationRequest<any>(
    isAdmin ? mutationLogoutAdmin : mutationLogoutHcp,
    {},
  );
  const history = useHistory();
  const today = moment().format('DD-MM-YYYY');
  const fromLS = storage.get('user');
  const currentHcpEmail = fromLS && fromLS.email;
  const { socket }: any = useSocket();
  const { onDisconnect } = useChat(socket);
  const dispatch = useDispatch();

  const onLogout = (): void => {
    logout();
  };
  useEffect(() => {
    if (data?.adminLogout || data?.hcpLogout) {
      const redirectTo = isAdmin ? path.adminSignIn : path.signIn;
      if (!isAdmin) {
        onDisconnect();
        dispatch(clearMessengerState());
      }

      history.push(redirectTo);
      storage.save('user', '');
      storage.save(currentHcpEmail, today);
    }
  }, [data]);
  return {
    error: errorText,
    onLogout,
    loadingLog: loading,
  };
};
