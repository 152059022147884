import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';

// Query
const queryGetHospitalPatientsForChat = loader('./gql/queryGetHospitalPatientsForChat.graphql');

// Mutation
const mutationChangeIsChatAvailable = loader('./gql/mutationChangeIsChatAvailable.graphql');

// Change chat status (offline/online)
export const useChangeIsChatAvailable = () => {
  const [_onChangeIsChatAvailable, { data, errorText, loading }] = useMutationRequest<{
    changeIsChatAvailable: boolean;
  }>(mutationChangeIsChatAvailable, { fetchPolicy: 'no-cache' });
  const _changeIsChatAvailable = (checked: boolean): void => {
    _onChangeIsChatAvailable({
      variables: {
        solution: checked,
      },
    });
  };
  return {
    _changeIsChatAvailable,
    changeIsChatAvailableData: data?.changeIsChatAvailable,
    changeIsChatAvailableError: errorText,
    changeIsChatAvailableLoading: loading,
  };
};

// Get patient list for create channel for messenger
export const useGetHospitalPatientsForChat = () => {
  const [_getHospitalPatientsForChat, { loading, data, error }] = useLazyQuery(
    queryGetHospitalPatientsForChat,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  const patients = data && data.getHospitalPatientsForChat;

  let patientList = [];
  let total = null;

  if (patients) {
    patientList = patients.list;
    total = patients.totalItems;
  }

  return {
    _getHospitalPatientsForChat,
    patientList,
    total,
    patientListLoading: loading,
    dataPatient: data,
    errorPatient: error,
  };
};
