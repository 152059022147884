import React, { createContext, useContext, useState } from 'react';
import io from 'socket.io-client';
import { reconnectionDelay } from './utils/variables';
import { config } from './utils/configs';

const { socketIoUri = '', uri = '' } = config;
const uriWithoutGraph = uri?.split('/api')[0]; // TODO add URI for websocket to .env
const websocketUri = uriWithoutGraph?.replace('https://', '').replace('http://', '');

const SocketContext: any = createContext(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }: any) => {
  const [socket, setSocket] = useState<any>(null);

  const initializeSocket = (token: string) => {
    const newSocket: any = io(`${websocketUri}/chat`, {
      reconnection: true,
      timeout: reconnectionDelay,
      path: '/socket.io',
      transports: ['websocket', 'xhr-polling', 'htmlfile', 'jsonp-polling'],
      autoConnect: false,
      query: {
        token,
      },
    });
    setSocket(newSocket);

    // newSocket.on('connect', () => {
    //   console.log('Socket connect');
    // });

    // newSocket.on('disconnect', () => {
    //   console.log('Socket disconnect');
    // });

    return newSocket;
  };

  return (
    <SocketContext.Provider value={{ socket, initializeSocket }}>{children}</SocketContext.Provider>
  );
};
