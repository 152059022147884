import React, { Fragment, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Tooltip } from 'antd';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import {
  getCurrenLang,
  getHcpName,
  getHcpNameLoading,
  getMessengerState,
  GetState,
} from '../../redux/selector';
import useCheckRole from '../../hooks/useCheckRole';
import {
  adminSideBar,
  hcpSideBar,
  logoutSideBar,
  patientSideBar,
  patientSideBarAdmin,
} from './SideBar.config';
import { useLogout } from '../../graphql/auth';
import { MenuProps, SideBarConfigType } from './types';
import { CHANNEL_TYPE, SIDEBAR_LABELS } from '../../utils/enums';
import { getQueryParams, initialPage } from '../../utils/helper';
import { HcpName, toggleMenu } from '../../redux/common';
import { Loading } from '../../common';
import { useChangeIsChatAvailable, useGetHospitalPatientsForChat } from '../../graphql/messenger';
import { clearMessengerState, setAddNewChannelModal } from '../../redux/messenger';
import { useQueryUserName } from '../../graphql/hcpProfile';
import { MessengerState } from '../../redux/messenger/types';
import { RoomType } from '../../pages/Messenger/types';
import { ChannelItem, DirectItem } from '../Messenger/ChannelItem/ChannelItem';
import { useHcpListForSearchHcp } from '../../graphql/hcp';
import useDebounce from '../../hooks/useDebounce';
import { CreateChannel, SearchHcpForm } from '../Messenger';
import AddMembers from '../Messenger/Room/AddMembers/AddMembers';
import AddNewItem from './AddNewItem';

const Menu = ({ isResponsive }: MenuProps): JSX.Element => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const on = t?.common.on;
  const off = t?.common.off;
  const online_t = t?.chat?.online || 'Online';
  const offline_t = t?.chat?.Offline || 'Offline';
  const channels_t = t?.chat?.channels || 'Channels';
  const direct_messages_t = t?.chat?.direct_messages || 'Direct Messages';

  const { isAdmin, isHcp } = useCheckRole();
  const location = useLocation();
  const dispatch = useDispatch();

  const isPatientArea = location.pathname.includes('/dashboard/patient/');
  const isMessenger = location.pathname.includes('/dashboard/messenger');
  const { userStatus } = getQueryParams(location.search);

  // Endpoints
  const { onLogout, error, loadingLog } = useLogout(isAdmin);
  const { _getUserName } = useQueryUserName(false);
  const {
    _changeIsChatAvailable,
    changeIsChatAvailableData,
    changeIsChatAvailableLoading,
    changeIsChatAvailableError,
  } = useChangeIsChatAvailable();
  const {
    _getHcpListForSearchHcp,
    hcpListLoading,
    hcpList,
    totalLength,
    dataHcp,
  } = useHcpListForSearchHcp();
  const {
    _getHospitalPatientsForChat,
    patientList,
    total,
    patientListLoading,
    dataPatient,
  } = useGetHospitalPatientsForChat();

  const collapsed = useSelector((state: GetState) => state.common.collapsed);
  const hcp: HcpName = useSelector((state: GetState) => getHcpName(state));
  const hcpNameLoading: boolean = useSelector((state: GetState) => getHcpNameLoading(state));
  const { isChatAvailable } = hcp;
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const {
    rooms,
    isAddNewChannelModal,
    activeRoomId,
    isAddMembersModal,
    listPatientIDsWithWhomHcpHasChannel,
  } = messengerState;
  const isAddNewDirectChannel = isAddNewChannelModal === CHANNEL_TYPE.DIRECT;
  const isAddNewGroupChannel = isAddNewChannelModal === CHANNEL_TYPE.GROUP;
  const isAddNewMembersModalOpen =
    isAddMembersModal.isOpen && isAddMembersModal.type === CHANNEL_TYPE.GROUP;

  console.log('777-rooms', rooms);

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [itemsTotal, setItemsTotal] = useState(0);

  // If logout error then show message
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // If change messenger connection status success
  useEffect(() => {
    if (changeIsChatAvailableData) {
      _getUserName();
    }
  }, [changeIsChatAvailableData]);

  // If there was an error changing the messenger connection status, display a message.
  useEffect(() => {
    if (changeIsChatAvailableError) {
      toast.error(changeIsChatAvailableError);
    }
  }, [changeIsChatAvailableError]);

  useEffect(() => {
    if (!hcpListLoading) {
      if (totalLength) {
        setItemsTotal(totalLength);
      }
    }
  }, [dataHcp]);

  // Update Patient list after change search
  useEffect(() => {
    if (!patientListLoading) {
      if (total) {
        setItemsTotal(total);
      }
    }
  }, [dataPatient]);

  const logoutJsx = logoutSideBar(t);

  const sideBarConfig = useMemo(() => {
    const sideBarMenu: SideBarConfigType[] = [];
    if (isAdmin && !isPatientArea) {
      sideBarMenu.push(...adminSideBar(t));
    }
    if (isHcp && !isPatientArea) {
      sideBarMenu.push(...hcpSideBar(t));
    }
    if (isHcp && isPatientArea) {
      sideBarMenu.push(...patientSideBar(t, userStatus));
    }
    if (isAdmin && isPatientArea) {
      sideBarMenu.push(...patientSideBarAdmin(t, userStatus));
    }
    sideBarMenu.push(logoutJsx);
    return sideBarMenu;
  }, [t, isPatientArea, isAdmin, isHcp]);

  // Close menu
  const closeMenu = (): void => {
    dispatch(toggleMenu(false));
  };

  // Turn on (off) messenger status
  const changeConnectedStatus = (checked: boolean): void => {
    _changeIsChatAvailable(checked);
    if (!checked) {
      dispatch(clearMessengerState());
    }
  };

  // Clear state after success created channel
  useEffect(() => {
    if (!isAddNewDirectChannel || !isAddNewGroupChannel || !isAddMembersModal) {
      setItemsTotal(0);
      setCurrentPage(initialPage);
      setSearchQuery('');
      setSearch('');
    }
  }, [isAddNewDirectChannel, isAddNewGroupChannel, isAddMembersModal]);

  // Open modal to create new channel
  const openAddNewChannelModal = (type: CHANNEL_TYPE): void => {
    dispatch(setAddNewChannelModal(type));
    closeMenu();
  };

  // Query get hcp list for search
  const getList = (page: number, searchValue: string): void => {
    if (isAddNewDirectChannel || isAddNewMembersModalOpen) {
      _getHcpListForSearchHcp({
        variables: {
          listProps: {
            page,
            itemsPerPage: pageSize,
          },
          searchProps: [
            {
              searchField: 'hcp.firstName',
              searchValue,
            },
            {
              searchField: 'hcp.lastName',
              searchValue,
            },
            {
              searchField: 'hcp.email',
              searchValue,
            },
          ],
        },
      });
    }
    if (isAddNewGroupChannel) {
      _getHospitalPatientsForChat({
        variables: {
          listProps: {
            page,
            itemsPerPage: pageSize,
          },
          searchProps: [
            {
              searchField: 'patient.firstName',
              searchValue,
            },
            {
              searchField: 'patient.lastName',
              searchValue,
            },
            {
              searchField: 'patient.email',
              searchValue,
            },
          ],
        },
      });
    }
  };

  // Update HCP list after change search
  useEffect(() => {
    if (searchQuery) {
      getList(initialPage, searchQuery);
    }
  }, [searchQuery]);

  const debounceValue = useDebounce(search, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setSearchQuery(debounceValue);
    } else {
      setSearchQuery('');
    }
  }, [debounceValue]);

  // Search member
  const changeSearch = ({ target: { value } }: any): void => {
    setSearch(value);
  };

  // Close modal
  const _oncloseAddNewChannelModal = (): void => {
    dispatch(setAddNewChannelModal(''));
    setItemsTotal(0);
    setCurrentPage(initialPage);
    setSearchQuery('');
  };

  // Move to corespond page
  const onChangePagination = (page: number): void => {
    getList(page, searchQuery);
    setCurrentPage(page);
  };

  // JSX
  const loadingJSX = loadingLog && <Loading />;
  let isShowLabel = true;
  if (!isResponsive && collapsed) {
    isShowLabel = false;
  }

  return (
    <>
      {loadingJSX}
      {!isMessenger &&
        sideBarConfig.map((item: SideBarConfigType, index: number) => {
          if (item.label === SIDEBAR_LABELS.SECTION_LABEL) {
            return (
              <div key={`sideBar${String(index)}`} className={styles['side-bar__section-title']}>
                {isShowLabel ? item.title : ''}
              </div>
            );
          }
          if (item.label === SIDEBAR_LABELS.SECTION_END) {
            return (
              <div key={`sideBar${String(index)}`} className={styles['side-bar__section-dash']} />
            );
          }
          if (item.label === SIDEBAR_LABELS.LOGOUT) {
            return (
              <Tooltip
                key={`sideBar${String(index)}`}
                placement="right"
                title={!isShowLabel ? item.title : ''}
              >
                <li
                  key={`sideBarElement${String(index)}`}
                  className={cx({
                    [styles.side_bar_li]: true,
                    [styles.side_bar__logout]: true,
                  })}
                  onClick={onLogout}
                  aria-hidden
                >
                  <div className={styles.side_bar_div}>
                    <div className={styles.side_bar_icon}>{item.icon}</div>
                    {isShowLabel && <div className={styles.side_bar_title}>{item.title}</div>}
                  </div>
                </li>
              </Tooltip>
            );
          }

          return (
            <Tooltip
              key={`sideBar${String(index)}`}
              placement="right"
              title={!isShowLabel ? item.title : ''}
            >
              <li className={styles.side_bar_li} onClick={closeMenu} aria-hidden>
                <NavLink
                  to={`${item.path}${isPatientArea ? location.search : ''}`}
                  exact={item.exact}
                  className={styles.side_bar_div}
                  activeClassName={styles.active}
                >
                  <div className={styles.side_bar_icon}>{item.icon}</div>
                  {isShowLabel && <div className={styles.side_bar_title}>{item.title}</div>}
                </NavLink>
              </li>
            </Tooltip>
          );
        })}

      {isMessenger && (
        <>
          {/* Switch (online/offline) */}
          <li className={styles.side_bar_li}>
            <div className={styles.side_bar_div}>
              <div className={styles.side_bar_switch}>
                <Switch
                  checkedChildren={<span>{on}</span>}
                  unCheckedChildren={<span>{off}</span>}
                  loading={changeIsChatAvailableLoading || hcpNameLoading}
                  checked={isChatAvailable}
                  onChange={(checked: boolean): void => changeConnectedStatus(checked)}
                />
              </div>
              {isShowLabel && (
                <div className={styles.side_bar_switch_title}>
                  {isChatAvailable ? online_t : offline_t}
                </div>
              )}
            </div>
          </li>
          {/* Channels */}
          <AddNewItem
            isShowLabel={isShowLabel}
            title={channels_t}
            action={(): void => openAddNewChannelModal(CHANNEL_TYPE.GROUP)}
          />

          {/* List Channels */}
          {rooms?.length > 0 &&
            rooms.map((room: RoomType, i: number) => {
              if (room.type === CHANNEL_TYPE.GROUP) {
                return (
                  <ChannelItem
                    key={`DirectChannel${String(i)}`}
                    room={room}
                    isShowLabel={isShowLabel}
                    closeMenu={closeMenu}
                  />
                );
              }
              return <Fragment key={`GroupChannel${String(i)}`} />;
            })}

          <div className={styles['side-bar__section-dash']} />

          {/* Direct messages */}
          <AddNewItem
            isShowLabel={isShowLabel}
            title={direct_messages_t}
            action={(): void => openAddNewChannelModal(CHANNEL_TYPE.DIRECT)}
          />

          {/* List Direct Messages */}
          {rooms?.length > 0 &&
            rooms.map((room: RoomType, i: number) => {
              if (room.type === CHANNEL_TYPE.DIRECT) {
                return (
                  <DirectItem
                    key={`DirectChannel${String(i)}`}
                    room={room}
                    isShowLabel={isShowLabel}
                    closeMenu={closeMenu}
                  />
                );
              }
              return <Fragment key={`DirectChannel${String(i)}`} />;
            })}

          {/* Logout */}
          <Tooltip
            // key={`sideBar${String(index)}`}
            placement="right"
            title={!isShowLabel ? logoutJsx?.title : ''}
          >
            <li
              // key={`sideBarElement${String(index)}`}
              className={cx({
                [styles.side_bar_li]: true,
                [styles.side_bar__logout]: true,
              })}
              onClick={onLogout}
              aria-hidden
            >
              <div className={styles.side_bar_div}>
                <div className={styles.side_bar_icon}>{logoutJsx?.icon}</div>
                {isShowLabel && <div className={styles.side_bar_title}>{logoutJsx?.title}</div>}
              </div>
            </li>
          </Tooltip>
        </>
      )}

      {/* Popups */}
      {isAddNewGroupChannel && !isResponsive && (
        <CreateChannel
          t={t}
          _oncloseAddNewChannelModal={_oncloseAddNewChannelModal}
          search={search}
          changeSearch={changeSearch}
          list={patientList}
          listLoading={patientListLoading}
          current={currentPage}
          pageSize={pageSize}
          total={itemsTotal}
          onChangePagination={onChangePagination}
          type={CHANNEL_TYPE.GROUP}
          activeRoomId={activeRoomId}
          listPatientIDsWithWhomHcpHasChannel={listPatientIDsWithWhomHcpHasChannel}
        />
      )}
      {isAddNewDirectChannel && !isResponsive && (
        <SearchHcpForm
          t={t}
          _oncloseAddNewChannelModal={_oncloseAddNewChannelModal}
          search={search}
          changeSearch={changeSearch}
          list={hcpList}
          listLoading={hcpListLoading}
          current={currentPage}
          pageSize={pageSize}
          total={itemsTotal}
          onChangePagination={onChangePagination}
          type={CHANNEL_TYPE.DIRECT}
          activeRoomId={activeRoomId}
        />
      )}
      {/* Add new members */}
      {isAddNewMembersModalOpen && !isResponsive && (
        <AddMembers
          search={search}
          changeSearch={changeSearch}
          list={hcpList}
          listLoading={hcpListLoading}
          current={currentPage}
          pageSize={pageSize}
          total={itemsTotal}
          onChangePagination={onChangePagination}
        />
      )}
    </>
  );
};

export default Menu;
