import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import styles from './styles.module.css';
import { Button } from '../../../../../common';
import { getCurrenLang } from '../../../../../redux/selector';
import { ClockBlackIcon } from '../../../../../theme/icons';
import { InputFloatLabel, TextareaFeildFloatLabel } from '../../../../../common/Input';
import { getUnitScheduleText } from '../../../../../utils/helper';

const FolderDetails = ({
  dataFolder,
  unitOptions,
  numberOptions,
  formik,
  loadingJSX,
  isOpenScheduleForm,
  setOpenScheduleForm,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const no_schedule = t?.dashboard.hcp.profile_patient.no_schedule;
  const folder_is_scheduled_t = t?.dashboard.hcp.profile_patient.folder_is_scheduled;
  const exerc_folder_name_label = t?.dashboard.hcp.profile_patient.exerc_folder_name_label;
  const exerc_folder_descr_label = t?.dashboard.hcp.profile_patient.exerc_folder_descr_label;
  const folder_details = t?.hcp.org_video_bank?.folder_details;
  const edit_folder_schedule = t?.hcp.org_video_bank?.edit_folder_schedule;
  const schedule_folder = t?.hcp.org_video_bank?.schedule_folder;

  const { values, errors, touched } = formik;

  const scheduledValue = {
    amount: dataFolder.hcpFolderTimer?.amount
      ? numberOptions[dataFolder.hcpFolderTimer.amount - 1]
      : null,
    timePeriod: dataFolder.hcpFolderTimer?.timePeriod
      ? unitOptions[dataFolder.hcpFolderTimer.timePeriod - 1]
      : null,
  };
  const isScheduled = dataFolder.hcpFolderTimer?.amount && dataFolder.hcpFolderTimer?.timePeriod;

  // JSX
  const EditBtnJsx = (
    <div className={styles['exercise-folder-settings__edit-btn-children']}>
      <div className={styles['exercise-folder-settings__clock-icon']}>
        <ClockBlackIcon />
      </div>
      <div className={styles['exercise-folder-settings__edit-btn-name']}>
        {isScheduled ? edit_folder_schedule : schedule_folder}
      </div>
    </div>
  );

  return (
    <div className={styles['exercise-folder-settings__section']}>
      <div className={styles['exercise-folder-settings__title']}>{folder_details}</div>
      <InputFloatLabel
        inputId="ExFolderInput"
        inputName="name"
        inputType="text"
        placeholder={exerc_folder_name_label}
        hasErrors={errors.name}
        isTouched={touched.name}
        inputValue={values.name}
        onChangeMethod={formik.handleChange}
      />

      <TextareaFeildFloatLabel
        id="folderDescription"
        inputName="description"
        placeholder={exerc_folder_descr_label}
        inputValue={values.description}
        hasErrors={errors.description}
        isTouched={touched.description}
        onChecngeMethod={formik.handleChange}
        rows={2}
      />
      <div className={styles['exercise-folder-settings__edit-container']}>
        <div
          className={cx({
            [styles['exercise-folder-settings__edit-text']]: true,
            [styles['exercise-folder-settings__edit-text--scheduled']]: isScheduled,
            [styles['exercise-folder-settings__edit-text--noscheduled']]: !isScheduled,
          })}
        >
          {isScheduled
            ? getUnitScheduleText(t, folder_is_scheduled_t, scheduledValue)
            : no_schedule}
        </div>
        <Button
          buttonClass={styles['exercise-folder-settings__edit-btn']}
          buttonType="button"
          buttonName=""
          buttonMethod={(): void => setOpenScheduleForm(() => !isOpenScheduleForm)}
          colorStyle="black"
          disabledButton={!!loadingJSX}
        >
          {EditBtnJsx}
        </Button>
      </div>
    </div>
  );
};

export default FolderDetails;
