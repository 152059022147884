import { useFormik } from 'formik';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import styles from './styles.module.css';
import { Button, Loading } from '../../common';
import {
  GetState,
  getCurrenLang,
  getCurrentFormatDate,
  getProcedures,
  getUserCountry,
  getUserLang,
} from '../../redux/selector';
import {
  convertInCm,
  convertInGram,
  heightConverted,
  validationConnectPatient,
  validationForTheConverterProfileByAdmin,
  validationSignUpPatient,
  weightConverted,
} from '../../utils/validators';
import { ShowErrorNotification } from '../Form/ErrorMessage';
import { useConnectPatientByHcp, useSignUpPatientByHcp } from '../../graphql/invite';
import { CustomCheckbox, InputFloatLabel, RadioConverter } from '../../common/Input';
import {
  dataPickerOfBirthday,
  dataPickerSurgery,
  getInsurancePolicyLabel,
  getSelectOptions,
  maxDate,
  minDate,
  minYear,
  nhsValueHandler,
  otherClaimIdHandler,
  scrollPageTo,
} from '../../utils/helper';
import { HEIGHT, PERMISSIONS, UserCountryEnum, Weight } from '../../utils/enums';
import { ConnectPatientType } from './types';
import { useQueryPatientProfileByEmail, useQueryClaimOptions } from '../../graphql/patients';
import { Options, TPatient } from '../../utils/model';
import { useGetTeamNamesForHcpHospital } from '../../graphql/hospitalTeam';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { InputPhoneNumberFloatLabel } from '../../common/Input/PhoneNumber';
import { SelectFloatLabel } from '../../common/Input/Select';
import { DatePickerFloatLabel } from '../../common/DatePicker';
import { Info } from '../../common/Hint';
import { initValuesUSProfile } from './initValue';

export const ConnectPatient = ({ setViewCreateTeamPage, createdNewTeam }: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const please_enter_patients_email = t?.connect_patient.please_enter_patients_email;
  const havent_registered = t?.connect_patient.havent_registered;
  const have_registered = t?.connect_patient.have_registered;
  const patients_email = t?.connect_patient.patients_email;
  const tooltip_email = t?.connect_patient.tooltip_email;
  const connect_patient = t?.common.connect_patient;
  const connect_with_code = t?.connect_patient.connect_with_code;
  const you_have_sent = t?.connect_patient.you_have_sent;
  const a_request_to_connect = t?.connect_patient.a_request_to_connect;
  const email_invitation_has_been_sent = t?.connect_patient.email_invitation_has_been_sent;
  const email_benefits = t?.connect_patient.email_benefits;
  const first_name = t?.dashboard.admin.profile_patient.first_name;
  const last_name = t?.dashboard.admin.profile_patient.last_name;
  const number_phone = t?.profile_hcp.number_phone;
  const following_information = t?.connect_patient.following_information;
  const label_dob = t?.dashboard.admin.profile_patient.label_dob;
  const dos = t?.dashboard.hcp.profile_patient.completion_and_steps.date_of_surgery;
  const procedure = t?.invite_patient.procedure;
  const type_of_claim = t?.dashboard.admin.profile_patient.type_of_claim;
  const insured_ID_number = t?.dashboard.admin.profile_patient.insured_ID_number;
  const policy_group = t?.dashboard.admin.profile_patient.policy_group;
  const other_claim_id = t?.dashboard.admin.profile_patient.other_claim_id;
  const insurance_plan_name = t?.dashboard.admin.profile_patient.insurance_plan_name;
  const weight_label = t?.invite_patient.weight;
  const tip_kg = t?.validation.tip_kg;
  const tip_stone = t?.validation.tip_stone;
  const tip_lbs = t?.validation.tip_lbs;
  const height_label = t?.invite_patient.height;
  const kg = t?.dashboard.admin.profile_patient.kg;
  const stone = t?.dashboard.admin.profile_patient.stone;
  const lbs = t?.dashboard.admin.profile_patient.lbs;
  const cm = t?.dashboard.admin.profile_patient.cm;
  const ft_inches = t?.dashboard.admin.profile_patient.ft_inches;
  const tip_cm = t?.validation.tip_cm;
  const tip_ft = t?.validation.tip_ft;
  const success_signup = t?.connect_patient.success_signup;
  const team_t = t?.common.team;
  const create_team = t?.hcp.manage.create_team;
  const you_dont_have_permission = t?.common.you_dont_have_permission;
  const connect_another_patient = t?.invite_patient.connect_another_patient;

  // Global state
  const userCountry: any = useSelector<GetState>((state) => getUserCountry(state));
  const hcpLang: any = useSelector<GetState>((state) => getUserLang(state));
  const formatDate = useSelector((state: GetState) => getCurrentFormatDate(state));

  // Local state
  const [patientIsNotFound, setPatientIsNotFound] = useState('');
  const [weightToBack, setWeightToBack] = useState<string | number>(0);
  const [heightToBack, setHeightToBack] = useState<string | number>(0);
  const [isSignUpPatient, setIsSignUpPatient] = useState(false);
  const [currentCandidat, setCandidat] = useState<any>(null);
  const [inviteError, setInviteError] = useState('');
  const [invitationIsSent, setInvitationSent] = useState(false);
  const [invitationWithCodeIsSent, setInvitationWithCodeSent] = useState(false);

  const isPermissionCreateTeam = useCheckPermissions(PERMISSIONS.CREATE_TEAM_OTM);
  const procedures: any = useSelector<GetState>((state) => getProcedures(state));

  // Queries
  const { _getClaimOptions, claimOptions } = useQueryClaimOptions();
  const { _getTeamNames, teamNames } = useGetTeamNamesForHcpHospital();
  const { _getProfileByEmail, patient, loading, error } = useQueryPatientProfileByEmail();
  // Mutations
  const { _connectPatientByHcp, isConnect, connectError, connecting } = useConnectPatientByHcp();
  const {
    _signUpPatientByHcp,
    isSignUpPatientSent,
    signUpError,
    signUpLoading,
  } = useSignUpPatientByHcp();

  const initialValues: any = {
    email: '',
    term: false,
    additionalInfo: false,
    dob: null,
    firstName: '',
    lastName: '',
    phone: '+',
    procedure: null, // { value: '', label: '' },
    surgeryDate: null,
    nhsNumber: '',
    weight: '',
    weightDim: Weight.Kg,
    height: '',
    heightDim: HEIGHT.cm,
    team: null,

    // For US
    ...initValuesUSProfile(userCountry, patient),
  };

  // Only For US
  useEffect(() => {
    if (UserCountryEnum.US) {
      _getClaimOptions();
    }
  }, [userCountry]);

  useEffect(() => {
    _getTeamNames();
  }, []);

  useEffect(() => {
    if (isConnect) {
      setInvitationSent(() => true);
      setViewCreateTeamPage(() => false);
    }
  }, [isConnect]);
  useEffect(() => {
    if (isSignUpPatientSent) {
      setInvitationWithCodeSent(() => true);
      setViewCreateTeamPage(() => false);
    }
  }, [isSignUpPatientSent]);

  useEffect(() => {
    if (connectError && !connecting) setPatientIsNotFound(connectError);
  }, [connectError, connecting]);

  useEffect(() => {
    if (signUpError && !signUpLoading) setPatientIsNotFound(signUpError);
  }, [signUpError, signUpLoading]);

  const onConnect = (candidat: ConnectPatientType): void => {
    const emailPatient = candidat.email;

    if (!candidat.additionalInfo) {
      _connectPatientByHcp(emailPatient);
    } else {
      const data: any = {
        email: candidat.email,
        firstName: candidat.firstName,
        lastName: candidat.lastName,
        weight: Number(weightToBack),
        height: Number(heightToBack),
        surgeryDate: candidat.surgeryDate,
        dob: candidat.dob,
        heightDim: candidat.heightDim,
        weightDim: candidat.weightDim,
        nhsNumber: candidat.nhsNumber ? candidat.nhsNumber.replace(/\D/g, '') : null,
        phone: candidat.phone[0] !== '+' ? `+${candidat.phone}` : candidat.phone,
        procedureTypeId: Number(candidat.procedure.value),
      };
      if (userCountry === UserCountryEnum.US) {
        if (candidat.typeOfClaim?.value) {
          data.claimId = Number(candidat.typeOfClaim.value);
        } else {
          data.claimId = null;
        }
        data.insuredIdNumber = candidat.insuredIdNumber || null;
        data.policyGroup = candidat.policyGroup || null;
        if (candidat.otherClaimId) {
          data.otherClaimId = candidat.otherClaimId.replace(/┆/g, '').replace(/\s/g, '');
        } else {
          data.otherClaimId = null;
        }
        data.insurancePlanName = candidat.insurancePlanName || null;
      }
      if (candidat.team) {
        data.teamId = candidat.team.value;
      }
      _signUpPatientByHcp(data);
    }
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => validationForTheConverterProfileByAdmin(values, t),
    validationSchema: isSignUpPatient
      ? (): any => validationSignUpPatient(t, userCountry)
      : (): any => validationConnectPatient(t),
    onSubmit: (values: any) => onConnect(values),
  });

  const { values, errors, touched, isValid } = formik;
  const isAdditionalForm = values.additionalInfo;

  const claimOptionsNormalize = useMemo(() => getSelectOptions(claimOptions), [claimOptions]);
  const options = useMemo(() => getSelectOptions(procedures), [procedures]);
  const teamOptions: Options[] = useMemo(() => getSelectOptions(teamNames), [teamNames]);

  // Fill out the form fields
  const fillForm = (patientFromDB: TPatient | null): void => {
    formik.setFieldValue('firstName', patientFromDB?.firstName || '');
    formik.setFieldValue('lastName', patientFromDB?.lastName || '');
    formik.setFieldValue(
      'surgeryDate',
      (patientFromDB?.procedure?.date && new Date(patientFromDB.procedure.date)) || null,
    );
    formik.setFieldValue('dob', (patientFromDB?.dob && new Date(patientFromDB.dob)) || null);
    formik.setFieldValue('weightDim', patientFromDB?.weightDim || Weight.Kg);
    formik.setFieldValue('heightDim', patientFromDB?.heightDim || HEIGHT.cm);
    formik.setFieldValue('weight', weightConverted(patientFromDB));
    formik.setFieldValue('height', heightConverted(patientFromDB));
    formik.setFieldValue(
      'nhsNumber',
      (patientFromDB?.nhsNumber && nhsValueHandler(patientFromDB.nhsNumber, userCountry)) || '',
    );
    formik.setFieldValue('phone', patientFromDB?.phone || '');
    formik.setFieldValue(
      'procedure',
      patientFromDB?.procedure && patientFromDB.procedure.type
        ? { value: patientFromDB.procedure.type.id, label: patientFromDB.procedure.type.name }
        : null,
    );

    // For US
    if (userCountry === UserCountryEnum.US) {
      formik.setFieldValue(
        'typeOfClaim',
        patientFromDB?.patientInsurance?.claim
          ? {
              value: patientFromDB.patientInsurance.claim.id,
              label: patientFromDB.patientInsurance.claim.name,
            }
          : '',
      );
      formik.setFieldValue(
        'insuredIdNumber',
        patientFromDB?.patientInsurance?.insuredIdNumber || '',
      );
      formik.setFieldValue('policyGroup', patientFromDB?.patientInsurance?.policyGroup || '');
      formik.setFieldValue(
        'otherClaimId',
        otherClaimIdHandler(patientFromDB?.patientInsurance?.otherClaimId) || '',
      );
      formik.setFieldValue(
        'insurancePlanName',
        patientFromDB?.patientInsurance?.insurancePlanName || '',
      );
    }
  };

  // If created new team add to corespond field and options
  useEffect(() => {
    if (createdNewTeam) {
      formik.setFieldValue('team', createdNewTeam);
      teamOptions.unshift(createdNewTeam);
    }
  }, [createdNewTeam]);

  useEffect(() => {
    if (values.weight) {
      const value = convertInGram(values.weight, values.weightDim);
      setWeightToBack(value);
    }
  }, [values.weight]);

  useEffect(() => {
    if (values.height) {
      const value = convertInCm(values.height, values.heightDim);
      setHeightToBack(value);
    }
  }, [values.height]);

  useEffect(() => {
    setIsSignUpPatient(isAdditionalForm);
  }, [isAdditionalForm]);

  // If the patient already exists, fill in the fields
  useEffect(() => {
    if (!loading) {
      if (patient || patient === null) {
        setCandidat(patient);
        if (values.additionalInfo) {
          fillForm(patient);
        }
        setInviteError('');
      } else if (error) {
        setCandidat(null);
        setInviteError(error);
        if (values.additionalInfo) {
          fillForm(null);
        }
      }
    }
  }, [patient, error]);

  // Check email field
  useEffect(() => {
    // Looking for a patient by email
    const currentEmail = values.email;
    if (currentEmail?.length > 1 && !errors.email) {
      _getProfileByEmail({
        variables: {
          patientEmail: currentEmail,
        },
      });
    }
  }, [values.email, errors.email]);

  // Email field handler
  const onChangeConnectInput = (e: any): void => {
    formik.setFieldValue('email', e.target.value);
    setPatientIsNotFound('');
    if (inviteError) {
      setInviteError('');
    }
  };

  // Additional info checkbox handler
  const onChangeAdditionalField = (e: any): void => {
    const currentVal = e.target.checked;
    formik.setFieldValue('additionalInfo', currentVal);
    if (currentVal && currentCandidat) {
      fillForm(currentCandidat);
    }
  };
  // Nhs field handler
  const nhsChangeHandler = (e: any): void => {
    const value = nhsValueHandler(e.target.value, userCountry);
    formik.setFieldValue('nhsNumber', value);
  };

  // OtherClaimId field handler
  const otherClaimIdChangeHandler = (e: any): void => {
    const value = otherClaimIdHandler(e.target.value);
    formik.setFieldValue('otherClaimId', value);
  };

  // RadioButton handler
  const onRadioChange = (dimProp: string, dimValue: string, prop: string): void => {
    formik.setFieldValue(dimProp, dimValue);
    formik.setFieldValue(prop, '');
  };

  // JSX
  const loaderJSX = (connecting || signUpLoading || loading) && <Loading />;
  const getTooltipText = (): string => {
    if (isAdditionalForm) {
      if (!isPermissionCreateTeam) {
        return you_dont_have_permission;
      }
    }
    return '';
  };
  const selectTeamTooltipText = getTooltipText();

  const clearForm = (): void => {
    setInvitationSent(() => false);
    setInvitationWithCodeSent(() => false);
    _getTeamNames();
    formik.resetForm();
  };
  const openTeamPage = (): void => {
    setViewCreateTeamPage(true);
    // scrollPageTo(0);
  };

  const connectAnotherPatient = (
    <Button
      buttonType="button"
      buttonName={connect_another_patient}
      buttonClass={styles['form__btn--connect']}
      buttonMethod={clearForm}
    />
  );

  return (
    <>
      {loaderJSX}
      {!(invitationIsSent || invitationWithCodeIsSent) && (
        <form onSubmit={formik.handleSubmit} className={styles.form_container}>
          <p>{please_enter_patients_email}</p>
          <p>{havent_registered}</p>
          <p>{have_registered}</p>
          <div className={styles['connect-patient__email-container']}>
            <InputFloatLabel
              inputId="email"
              inputName="email"
              inputType="text"
              placeholder={patients_email}
              hasErrors={errors.email}
              inputValue={values.email}
              isTouched={touched.email}
              onChangeMethod={onChangeConnectInput}
              isRedStar
            />
            <div className={styles['connect-patient__email-info']}>
              <Info tip={tooltip_email || ''} />
            </div>
          </div>
          {/* Connect with code */}
          <div className={styles['connect-patient__subtitle']}>{connect_with_code}</div>
          <div className={styles.container_error}>
            {touched.term && errors.term ? (
              <div className={styles.error_checkbox}>{errors.term}</div>
            ) : null}
          </div>
          <div className={styles.confirm}>
            <CustomCheckbox
              htmlId="additionalInfo"
              name="additionalInfo"
              checked={isAdditionalForm}
              onChangeMethod={onChangeAdditionalField}
              isTouched={touched.additionalInfo}
              hasErrors={errors.additionalInfo}
              bigSize
            />
            <p className={styles.confirm_checkbox}>{following_information}</p>
          </div>

          {/* Additional information */}
          <div className={styles.row}>
            <div className={styles['cell-50']}>
              <InputFloatLabel
                inputId="connect_firstName"
                inputName="firstName"
                placeholder={first_name}
                inputType="text"
                inputValue={values.firstName}
                hasErrors={isAdditionalForm && errors.firstName}
                isTouched={touched.firstName}
                onChangeMethod={formik.handleChange}
                disabled={!isAdditionalForm}
                isRedStar
              />
            </div>
            <div className={styles['cell-50']}>
              <InputFloatLabel
                inputId="connect_last"
                inputName="lastName"
                placeholder={last_name}
                inputType="text"
                inputValue={values.lastName}
                hasErrors={isAdditionalForm && errors.lastName}
                isTouched={touched.lastName}
                onChangeMethod={formik.handleChange}
                disabled={!isAdditionalForm}
                isRedStar
              />
            </div>
          </div>

          <DatePickerFloatLabel
            autocomplete="off"
            name="dob"
            labelName={label_dob}
            placeholderText=" "
            carrentValue={values.dob}
            selected={values.dob}
            onChange={formik.setFieldValue}
            onBlur={(val: React.ChangeEvent<HTMLInputElement>): void => {
              dataPickerOfBirthday(val, t, formik, formatDate);
            }}
            minDate={new Date(new Date(minYear).getFullYear(), 0, 1)}
            maxDate={new Date()}
            currentLang={hcpLang}
            hasErrors={isAdditionalForm && errors.dob}
            isTouched={touched.dob}
            disabled={!isAdditionalForm}
            isRedStar
          />
          <InputPhoneNumberFloatLabel
            inputId="phoneNumber"
            isValid={isValid}
            hasErrors={isAdditionalForm && errors.phone}
            value={values.phone}
            isTouched={touched.phone}
            onChange={(date: string): any => formik.setFieldValue('phone', `+${date}`)}
            placeholder={number_phone}
            userCountry={userCountry}
            disabled={!isAdditionalForm}
            isRedStar
          />
          <SelectFloatLabel
            inputId="procedure"
            name="procedure"
            placeholder={procedure}
            onChange={formik.setFieldValue}
            options={options}
            hasErrors={isAdditionalForm && errors.procedure}
            isTouched={touched.procedure}
            selected={values.procedure}
            inputValue={values.procedure}
            disabledField={!isAdditionalForm}
            type="connect patient"
            isRedStar
          />
          <DatePickerFloatLabel
            autocomplete="off"
            name="surgeryDate"
            placeholderText=""
            labelName={dos}
            carrentValue={values.surgeryDate}
            selected={values.surgeryDate}
            onChange={formik.setFieldValue}
            onBlur={(val: React.ChangeEvent<HTMLInputElement>): void => {
              dataPickerSurgery(val, t, formik, formatDate);
            }}
            isNextYear
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            currentLang={hcpLang}
            disabled={!isAdditionalForm}
          />
          {/* NHS */}
          <InputFloatLabel
            inputId="connect_nhsNumber"
            inputName="nhsNumber"
            hasErrors={errors.nhsNumber}
            isTouched={touched.nhsNumber}
            onChangeMethod={nhsChangeHandler}
            inputValue={values.nhsNumber}
            placeholder={getInsurancePolicyLabel(t, userCountry)}
            inputType="text"
            disabled={!isAdditionalForm}
          />
          {/* Policy accurance US */}
          {userCountry === UserCountryEnum.US && (
            <>
              <SelectFloatLabel
                inputId="typeOfClaim"
                name="typeOfClaim"
                placeholder={type_of_claim}
                options={claimOptionsNormalize}
                onChange={formik.setFieldValue}
                inputValue={values.typeOfClaim}
                isClearable
                type="user profile"
                disabledField={!isAdditionalForm}
              />
              <InputFloatLabel
                inputId="patientProfile_insuredIdNumber"
                inputName="insuredIdNumber"
                placeholder={insured_ID_number}
                hasErrors={errors.insuredIdNumber}
                isTouched={touched.insuredIdNumber}
                onChangeMethod={formik.handleChange}
                inputValue={values.insuredIdNumber}
                inputType="text"
                disabled={!isAdditionalForm}
              />
              <InputFloatLabel
                inputId="patientProfile_policyGroup"
                inputName="policyGroup"
                placeholder={policy_group}
                hasErrors={errors.policyGroup}
                isTouched={touched.policyGroup}
                onChangeMethod={formik.handleChange}
                inputValue={values.policyGroup}
                inputType="text"
                disabled={!isAdditionalForm}
              />
              <InputFloatLabel
                inputId="patientProfile_otherClaimId"
                inputName="otherClaimId"
                placeholder={other_claim_id}
                hasErrors={errors.otherClaimId}
                isTouched={touched.otherClaimId}
                onChangeMethod={otherClaimIdChangeHandler}
                inputValue={values.otherClaimId}
                inputType="text"
                disabled={!isAdditionalForm}
              />
              <InputFloatLabel
                inputId="patientProfile_insurancePlanName"
                inputName="insurancePlanName"
                placeholder={insurance_plan_name}
                hasErrors={errors.insurancePlanName}
                isTouched={touched.insurancePlanName}
                onChangeMethod={formik.handleChange}
                inputValue={values.insurancePlanName}
                inputType="text"
                disabled={!isAdditionalForm}
              />
            </>
          )}
          <div className={styles.row}>
            <div className={styles['cell-50']}>
              {/* HEIGHT */}
              <div className={styles.container_radio}>
                <div>
                  <div className={styles.converter_container}>
                    <RadioConverter
                      id="cm"
                      tip={tip_cm}
                      value={values.heightDim}
                      inputName={values.heightDim}
                      handleChange={(): void => onRadioChange('heightDim', HEIGHT.cm, 'height')}
                      labelName={cm}
                      checkedRadio={values.heightDim === HEIGHT.cm}
                      bigSize
                    />
                    <RadioConverter
                      id="ft_inches"
                      tip={tip_ft}
                      value={values.heightDim}
                      inputName={values.heightDim}
                      handleChange={(): void =>
                        onRadioChange('heightDim', HEIGHT.ftinches, 'height')
                      }
                      labelName={ft_inches}
                      checkedRadio={values.heightDim === HEIGHT.ftinches}
                      bigSize
                    />
                  </div>

                  <InputFloatLabel
                    inputId="heightId"
                    inputName="height"
                    inputValue={values.height}
                    hasErrors={errors.height}
                    onChangeMethod={formik.handleChange}
                    disabled={!(isAdditionalForm && values.heightDim)}
                    placeholder={height_label}
                    inputType="text"
                    isTouched={touched.height}
                  />
                </div>
              </div>
            </div>
            <div className={styles['cell-50']}>
              {/* WEIGHT */}
              <div className={styles.container_radio}>
                <div className={styles.form_radio}>
                  <div>
                    <RadioConverter
                      id="kg"
                      value={values.weightDim}
                      inputName={values.weightDim}
                      handleChange={(): void => onRadioChange('weightDim', Weight.Kg, 'weight')}
                      labelName={kg}
                      tip={tip_kg}
                      checkedRadio={values.weightDim === Weight.Kg}
                      bigSize
                    />
                    <RadioConverter
                      id="stone"
                      value={values.weightDim}
                      inputName={values.weightDim}
                      handleChange={(): void => onRadioChange('weightDim', Weight.Stone, 'weight')}
                      labelName={stone}
                      tip={tip_stone}
                      checkedRadio={values.weightDim === Weight.Stone}
                      bigSize
                    />

                    <RadioConverter
                      id="lbs"
                      value={values.weightDim}
                      inputName={values.weightDim}
                      handleChange={(): void => onRadioChange('weightDim', Weight.Lbs, 'weight')}
                      labelName={lbs}
                      tip={tip_lbs}
                      checkedRadio={values.weightDim === Weight.Lbs}
                      bigSize
                    />
                  </div>

                  <InputFloatLabel
                    inputId="weightId"
                    inputName="weight"
                    inputValue={values.weight}
                    hasErrors={errors.weight}
                    onChangeMethod={formik.handleChange}
                    disabled={!(isAdditionalForm && values.weightDim)}
                    inputType="text"
                    isTouched={touched.weight}
                    placeholder={weight_label}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Team */}
          <SelectFloatLabel
            inputId="team"
            name="team"
            placeholder={team_t}
            onChange={formik.setFieldValue}
            options={teamOptions}
            hasErrors={isAdditionalForm && errors.team}
            isTouched={touched.team}
            selected={values.team}
            inputValue={values.team}
            disabledField={!isAdditionalForm || !isPermissionCreateTeam}
            type="connect patient"
            isClearable
            tooltipText={selectTeamTooltipText}
          />
          <Tooltip title={selectTeamTooltipText}>
            <div className={styles['create-team-btn-container']}>
              <Button
                buttonType="button"
                buttonName={create_team}
                disabledButton={
                  connecting || signUpLoading || !isAdditionalForm || !isPermissionCreateTeam
                }
                colorStyle="green"
                buttonClass={styles['create-team-btn']}
                buttonMethod={openTeamPage}
              />
            </div>
          </Tooltip>
          <div className={styles.confirm}>
            <CustomCheckbox
              htmlId="term"
              name="term"
              checked={values.term}
              onChangeMethod={formik.handleChange}
              isTouched={touched.term}
              hasErrors={errors.term}
              bigSize
            />
            <p className={styles.confirm_checkbox}>{email_benefits}</p>
          </div>
          <div className={styles.content}>
            {patientIsNotFound && values.email && (
              <div className={styles.errorMessages}>
                <ShowErrorNotification
                  errorMessage={typeof patientIsNotFound === 'string' && patientIsNotFound}
                />
              </div>
            )}
            {inviteError && values.additionalInfo && (
              <div className={styles.errorMessages}>
                <ShowErrorNotification errorMessage={inviteError} />
              </div>
            )}
            <Button
              buttonType="submit"
              buttonName={connect_patient}
              disabledButton={connecting || signUpLoading || !formik.dirty}
              buttonClass={styles.btn_submit}
            />
          </div>
        </form>
      )}

      {isConnect && invitationIsSent && (
        <div className={styles['form__success-msg-container']}>
          <div className={styles['form__success-msg']}>
            {isConnect === values.email
              ? email_invitation_has_been_sent
              : `${you_have_sent} ${isConnect} ${a_request_to_connect}`}
          </div>
          {connectAnotherPatient}
        </div>
      )}
      {isSignUpPatientSent && invitationWithCodeIsSent && (
        <div className={styles['form__success-msg-container']}>
          <div className={styles['form__success-msg']}>{success_signup}</div>
          {connectAnotherPatient}
        </div>
      )}
    </>
  );
};
