import React, { ReactElement } from 'react';

import styles from './styles.module.css';
import MessageInput from './MessageInput/MessageInput';
import MessageList from './MessageList/MessageList';
import RoomHeader from './RoomHeader/RoomHeader';

const Room = (): ReactElement => (
  <div className={styles.room__container}>
    <div className={styles.room__conversation}>
      <RoomHeader />
      <MessageList />
    </div>
    <MessageInput />
  </div>
);
export default Room;
