/* eslint-disable react/button-has-type */
import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { ButtonProps, ButtonWithContentProps } from './types';
import { CrossIcon, Plus, PlusGray, PlusGreySmIcon, PlusSmIcon } from '../../theme/icons';

export const Button = ({
  buttonName,
  buttonMethod,
  disabledButton,
  buttonType,
  children,
  active = false,
  buttonClass,
  colorStyle = 'orange',
  dataQa,
}: ButtonProps): ReactElement => (
  <button
    className={cx({
      [styles.button]: true,
      [styles.disabled]: disabledButton,
      [styles[`active_${colorStyle}`]]: active,
      [buttonClass]: buttonClass,
      [styles[colorStyle]]: true,
    })}
    type={buttonType}
    disabled={disabledButton && disabledButton}
    onClick={buttonMethod && buttonMethod}
    data-qa={dataQa}
  >
    {buttonName && <div className={styles.button__name}>{buttonName}</div>}
    {children}
  </button>
);

export const CommonButton = (props: any): ReactElement => {
  const { children, dataQa, ...rest } = props;
  return (
    <button data-qa={dataQa} {...rest}>
      {children}
    </button>
  );
};

export const PlusNewItem = ({
  description,
  buttonMethod,
  disable = false,
  dataQa,
}: any): ReactElement => (
  <div
    className={cx({
      [styles.edit__icon]: true,
      [styles['edit__descr--disable']]: disable,
    })}
    onClick={disable ? (): null => null : buttonMethod}
    role="presentation"
    data-qa={dataQa}
  >
    <div className={styles['edit__icon-plus']}>{disable ? <PlusGray /> : <Plus />}</div>
    <span
      className={cx({
        [styles.edit__descr]: true,
        [styles['edit__descr--disable']]: disable,
      })}
    >
      {description}
    </span>
  </div>
);

export const PlusNewElem = ({
  description,
  buttonMethod,
  disable = false,
  dataQa,
}: any): ReactElement => (
  <div
    className={cx({
      [styles.newelem__container]: true,
      [styles['newelem__descr--disable']]: disable,
    })}
    onClick={disable ? (): null => null : buttonMethod}
    role="presentation"
    data-qa={dataQa}
  >
    {disable ? <PlusGreySmIcon /> : <PlusSmIcon />}
    <span
      className={cx({
        [styles.newelem__descr]: true,
        [styles['newelem__descr--disable']]: disable,
      })}
    >
      {description}
    </span>
  </div>
);

export const CloseBtn = ({ close, dataQa }: { close: any; dataQa?: string }): ReactElement => (
  <div className={styles.button__close} data-qa={dataQa} onClick={close} aria-hidden>
    <CrossIcon />
  </div>
);

export const ButtonWithContent = ({
  buttonName,
  buttonMethod,
  disabledButton,
  buttonType,
  active = false,
  buttonClass,
  colorStyle = 'orange',
  icon,
  dataQa,
}: ButtonWithContentProps): ReactElement => (
  <button
    className={cx({
      [styles.button]: true,
      [styles.disabled]: disabledButton,
      [styles[`active_${colorStyle}`]]: active,
      [buttonClass]: buttonClass,
      [styles[colorStyle]]: true,
    })}
    type={buttonType}
    disabled={disabledButton && disabledButton}
    onClick={buttonMethod && buttonMethod}
    data-qa={dataQa}
  >
    <div className={styles['button__content-wrapper']}>
      {icon}
      <div className={styles.button__content}>{buttonName}</div>
    </div>
  </button>
);

export const ButtonsBlock = ({
  buttonNameSubmit,
  buttonNameCancel,
  onCancel,
  onSubmit = (): null => null,
  disabledSubmitButton = false,
  dataQa,
}: any): ReactElement => (
  <div className={styles.btns__container}>
    <Button
      buttonName={buttonNameSubmit}
      buttonType="submit"
      buttonClass={styles.btn__save}
      disabledButton={disabledSubmitButton}
      buttonMethod={onSubmit}
    />
    <Button
      buttonName={buttonNameCancel}
      buttonType="button"
      buttonClass={styles.btn__save}
      buttonMethod={onCancel}
    />
  </div>
);
