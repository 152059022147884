import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { ItemAvatarProps } from '../../../pages/Messenger/types';

const ItemAvatar = ({ room, isShowStatus }: ItemAvatarProps): JSX.Element => {
  const getInitials = (fullName: string): string => {
    // Split the name by spaces
    const nameParts = fullName.trim().split(/[\s]/);

    let initials = '';

    // check every word in the first and last name
    nameParts.forEach((part) => {
      // Add the first letter of each word to the initials
      initials += part.charAt(0).toUpperCase();
    });
    return initials;
  };

  return (
    <div className={styles['msg__room-name-avatar-icon']}>
      {room.photo ? (
        <img
          className={styles['direct-item__photo']}
          src={`data:image/jpeg;charset=utf-8;base64,${room.photo}`}
          alt="avatar"
        />
      ) : (
        getInitials(room.name)
      )}
      {isShowStatus && (
        <div
          className={cx({
            [styles['msg__user-status']]: true,
            [styles['msg__user-status--online']]: room.isOnline,
            [styles['msg__user-status--offline']]: !room.isOnline,
          })}
        />
      )}
    </div>
  );
};

export default ItemAvatar;
