// Core
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loader } from 'graphql.macro';
import { QueryLazyOptions, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError, OperationVariables } from 'apollo-client';
import { config } from '../../utils/configs';

import { setLang } from '../../redux/localization';
import { storage } from '../../utils';

interface UseTranslate {
  data: any;
  error: ApolloError | undefined;
  loading: boolean;
  _getTranslation: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
}

// Query
const gqlTranslate = loader('./gql/queryGetTranslation.graphql');

export const useTranslate = (): UseTranslate => {
  const [_getTranslation, { loading, error, data }] = useLazyQuery(gqlTranslate);

  const lang = storage.get('lang');
  const langLS = lang || config.language;
  const temp = data && data.getTranslation && JSON.parse(data.getTranslation.translation);
  const translate = langLS === temp && temp.web;

  if (translate) {
    storage.save('t', translate);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.getTranslation && !loading) {
      const res = JSON.parse(data.getTranslation.translation);

      dispatch(setLang(res.web));
    }
  }, [dispatch, data]);

  return {
    data: translate,
    error,
    loading,
    _getTranslation,
  };
};
