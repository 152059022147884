import { createSelector } from '@reduxjs/toolkit';

import { calculateForCount } from '../../utils/helper_charts';
import { PatientState } from '../patient';
import { QuestionnaireState } from '../questionnaires/types';
import { SelectedPeriodType } from '../dropdown';
import { PeriodDropDownOptions } from '../../utils/model';
import { ChartsDataType, ChartValuesType, MotivationState } from '../motivations';
import {
  ExercComplDataType,
  ExercisesType,
  ExercPerPeriodType,
  NotComplExercDataType,
  NotComplExercValuesType,
} from '../completions/types';
import { CommonType, HcpName } from '../common';
import { RehabTeamsType } from '../rehabTeams';
import { RewardsStoreType } from '../rewards';
import { EmailNotifStoreType } from '../emailNotifications';
import { PatientsInvitationStoreType } from '../patientInvitation';
import { HcpNotificationsStateType } from '../notifications';
import { BodyDataState, SleepChartDataType, SleepChartValuesType } from '../bodyData';
import { PERIOD_FOR_CHART } from '../../utils/enums';
import { chartItemsCount } from '../../utils/share/options';
import { AvailablePatientPromsType } from '../../components/Charts/types';
import { FormatDates } from '../types';
import { MessengerState } from '../messenger/types';

export interface GetState {
  t: { local: object };
  completed: ExercisesType;
  motivation: MotivationState;
  patient: PatientState;
  common: CommonType;
  proms: QuestionnaireState;
  selectedPeriod: SelectedPeriodType;
  rehabTeams: RehabTeamsType;
  rewards: RewardsStoreType;
  emailNotification: EmailNotifStoreType;
  patientsInvitation: PatientsInvitationStoreType;
  notifications: HcpNotificationsStateType;
  bodyData: BodyDataState;
  messenger: MessengerState;
}

// Common
export const getCurrenLang = (state: GetState): any => state.t.local;
export const getCurrentFormatDate = (state: GetState): FormatDates => state.common.formatDates;
export const getUserLang = (state: GetState): string => state.common.userLang;
export const getUserCountry = (state: GetState): string => state.common.userCountry;
export const getHcpName = (state: GetState): HcpName => state.common.hcp;
export const getHcpAvatarPic = (state: GetState): string => state.common.hcp.photoPic;
export const getHcpAvatarUuid = (state: GetState): string => state.common.hcp.photoUuid;
export const getHcpNameLoading = (state: GetState): boolean => state.common.hcpLoading;
export const getPermissions = (state: GetState): any[] => state.common.hcp.permissions;
export const getProcedureKeys = (state: GetState) => state.common.listProcedureKeys;
export const getLoadingProcedureKeys = (state: GetState) => state.common.loadingKeysOfProcedures;
export const getProcedures = (state: GetState) => state.common.procedures;
export const getLoadingProcedures = (state: GetState): boolean => state.common.loadingProcedures;
export const getExerciseCategories = (state: GetState) => state.common.exerciseCategoriesList;
export const getLoadingExerciseCategories = (state: GetState) => {
  return state.common.loadingExerciseCategoriesList;
};
export const getMenuPosition = (state: GetState): boolean => state.common.isShowMenu;

// Patient profile
export const getPatientProfile = (state: GetState): any => state.patient.profile;
export const getLoadingPatientProfile = (state: GetState): boolean => state.patient.loading;
export const getErrorPatientProfile = (state: GetState): any => state.patient.error;
export const getIsNotSavedPatientProfil = (state: GetState) => state.patient.isNotSavedProfileData;
export const getIsNotSavedPatientInfo = (state: GetState) => state.patient.isNotSavedPatientInfo;
export const getExerciseLibrary = (state: GetState): any => state.patient.exerciseLibrary;
export const getExercLibTotalItems = (state: GetState) => state.patient.exerciseLibraryTotalItems;
export const getDraggedList = (state: GetState): any => state.patient.draggedList;
export const getStartUpd = (state: GetState): any => state.patient.isStartUpdate;

// Chart state
// --motivation
export const getMotivation = (state: GetState) => state.motivation.motivation;
export const getMotivationLoading = (state: GetState) => state.motivation.loading;
export const getMotivationError = (state: GetState) => state.motivation.error;

// --bodyData
export const getPatientSleepData = (state: GetState) => state.bodyData.sleep;
export const getPatientSleepLoading = (state: GetState) => state.bodyData.sleepLoading;
export const getPatientSleepError = (state: GetState) => state.bodyData.sleepError;
export const getPatientHeartRateData = (state: GetState) => state.bodyData.heartRate;
export const getPatientHeartRateLoading = (state: GetState) => state.bodyData.heartRateLoading;
export const getPatientHeartRateError = (state: GetState) => state.bodyData.heartRateError;

// --pain
export const getPainLevelData = (state: GetState) => state.motivation.painLevel;
export const getPainLevelLoading = (state: GetState) => state.motivation.painLevelLoading;
export const getPainLevelError = (state: GetState) => state.motivation.painLevelError;

// --reasons
export const getNotComplExerc = (state: GetState) => state.completed.notCompletedExercises;
export const getNotComplExercLoading = (state: GetState): any => {
  return state.completed.notCompletedExercisesLoading;
};
export const getNotComplExercError = (state: GetState): any => {
  return state.completed.notCompletedExercisesError;
};

// --exercises
export const getExercComplData = (state: GetState) => state.completed.exerciseComletion;
export const getExercComplLoading = (state: GetState) => state.completed.exerciseComletionLoading;
export const getExercComplError = (state: GetState) => state.completed.exerciseComletionError;
export const getExercPerPeriodData = (state: GetState) => state.completed.exercisesPerPeriod;
export const getExPerPeriodLoadin = (state: GetState) => state.completed.exercisesPerPeriodLoading;

// --by periods
export const getPeriodsOfExercises = (state: GetState): any => state.completed.exercisesPerPeriod;

// --steps
export const getPatientsSteps = (state: GetState): any => state.completed.steps;
export const getStepsLoading = (state: GetState): boolean => state.completed.stepsLoading;

// Selected periods
export const getSelectedPeriodOverview = (state: GetState) => state.selectedPeriod.overview;
export const getSelectedPerRemoteMonit = (state: GetState) => state.selectedPeriod.remoteMonitoring;

// PROMs state
export const getAllPatientProms = (state: GetState) => state.proms.allPatientQuestionnaires;
export const getPatientPromsLoading = (state: GetState) => state.proms.loadingQuestionnaires;
export const getAllProms = (state: GetState) => state.proms.questionnairesList.allQuestionnaires;
export const getLoadingAllProms = (state: GetState): boolean => {
  return state.proms.questionnairesList.loadingAllQuestionnaires;
};
export const getPainDetectPics = (state: GetState) => state.proms.painDetectPics.pics;
export const getLoadingPainDetecPics = (state: GetState) => state.proms.painDetectPics.loadingPics;
export const getEqValueForLegend = (state: GetState) => state.proms.eqLegendValue;
export const getAvailablePatientProms = (state: GetState) => {
  return state.proms.availablePatientQuestionnaires;
};
export const getLoadingAvailablePatientProms = (state: GetState): boolean => {
  return state.proms.loadingAvailablePatientQuestionnaires;
};

// Rehab Teams
export const getRehabTeamList = (state: GetState) => state.rehabTeams.teamList;
export const getRehabTeamsLoading = (state: GetState) => state.rehabTeams.teamListLoading;
export const getRehabTeamsError = (state: GetState): string => state.rehabTeams.teamListError;
export const getRehabTeamsTotalItems = (state: GetState) => state.rehabTeams.totalItems;
export const getSearchTeamLoading = (state: GetState) => state.rehabTeams.searchTeamLoading;
export const getTotalItemsSearchTeam = (state: GetState) => state.rehabTeams.totalItemsSearchTeam;
export const getTeamSettingsData = (state: GetState) => state.rehabTeams.openTeamSettingData;

export const getPatientTeamList = (state: GetState) => state.rehabTeams.patientTeamList;
export const getPatientTeamsLoading = (state: GetState) => state.rehabTeams.patientTeamListLoading;
export const getPatientTeamsError = (state: GetState) => state.rehabTeams.patientTeamListError;
export const getPatientTeamsTotalItems = (state: GetState) => {
  return state.rehabTeams.patientTeamTotalItems;
};
export const getSearchPatientTeamLoading = (state: GetState) => {
  return state.rehabTeams.searchPatientTeamLoading;
};
export const getTotalItemsSearchPatientTeam = (state: GetState) => {
  return state.rehabTeams.totalItemsSearchPatientTeam;
};

// Rewards
export const getRewardList = (state: GetState) => state.rewards.rewardList;
export const getRewardListLoading = (state: GetState) => state.rewards.rewardListLoading;
export const getRewardListError = (state: GetState): string => state.rewards.rewardListError;
export const getRewardSettingsData = (state: GetState) => state.rewards.openRewardSettingData;

// Email Notification
export const getNotifToMeData = (state: GetState) => state.emailNotification.notifToMeData;
export const getNotifToMeLoading = (state: GetState) => state.emailNotification.notifToMeLoading;
export const getNotifToMeError = (state: GetState): string =>
  state.emailNotification.notifToMeError;
export const getEmailNotifSettingsData = (state: GetState) =>
  state.emailNotification.openSettingData;

// Patient Invitation
export const getPatientsInvitationData = (state: GetState): any =>
  state.patientsInvitation.patientsInvitations;
export const getPatientsInvitationLoading = (state: GetState): boolean =>
  state.patientsInvitation.patientsInvitationsLoading;
export const getPatientsInvitationError = (state: GetState): any =>
  state.patientsInvitation.patientsInvitationsError;
export const getPatientsInvitationTotal = (state: GetState): any => state.patientsInvitation.total;

// Messenger
export const getMessengerState = (state: GetState): MessengerState => state.messenger;

export const getAllowedPatientProms = createSelector(
  getAvailablePatientProms,
  (availablePatientProms: AvailablePatientPromsType[]) => {
    const normilizeData: any = {};
    if (availablePatientProms?.length > 0) {
      availablePatientProms.map((questionnaiere: AvailablePatientPromsType) => {
        normilizeData[questionnaiere.id] = questionnaiere.status;
        return null;
      });
    }
    return normilizeData;
  },
);

// ** NEW selectors******************************************

// Calculate date for Exercise Completion chart
const calculateChartData = (selectedPeriod: PeriodDropDownOptions | null, data: any[]) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const dataPeriod = data ? data.find((el: ChartsDataType) => el.graph === periodKey) : [];
  return dataPeriod;
};
// Pain level chart
export const getPainData = createSelector(
  getSelectedPeriodOverview,
  getPainLevelData,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Motivation chart
export const getMotivationData = createSelector(
  getSelectedPeriodOverview,
  getMotivation,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Steps chart
export const getStepsData = createSelector(
  getSelectedPeriodOverview,
  getPatientsSteps,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Heart rate chart
export const getHeartRateData = createSelector(
  getSelectedPeriodOverview,
  getPatientHeartRateData,
  (selectedPeriod, data) => {
    const calcResult = calculateChartData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate date for Exercise Completion chart
const calculateReasonsData = (
  selectedPeriod: PeriodDropDownOptions | null,
  data: NotComplExercDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const dataPeriod: any = data
    ? data.find((el: NotComplExercDataType) => el.graph === periodKey)
    : [];
  const chartData = {
    graph: '',
    title: '',
    description: '',
    axis: '',
    values: null,
    yAxisDomain: [0, 10],
  };
  if (dataPeriod) {
    const dataValues = dataPeriod.values;
    const values: any = [];
    let maxYAxis = 10;
    if (dataValues) {
      dataPeriod.values.map((period: NotComplExercValuesType) => {
        const allReasonsValues = Object.values(period.reasons);
        const sumAllReasPerPer = allReasonsValues.reduce((accumulator: any, currentValue: any) => {
          if (typeof currentValue !== 'string') {
            return accumulator + currentValue;
          }
          return accumulator;
        }, 0);
        values.push(sumAllReasPerPer);
        return null;
      });
      maxYAxis = Math.max(...values);
      if (maxYAxis % 10 !== 0) {
        let fraction = maxYAxis / 10;
        fraction = Math.ceil(fraction);
        maxYAxis = fraction * 10;
      }
    }
    chartData.graph = dataPeriod.graph;
    chartData.title = dataPeriod.title;
    chartData.description = dataPeriod.description;
    chartData.axis = dataPeriod.axisReasons;
    chartData.values = dataPeriod.values;
    chartData.yAxisDomain = [0, maxYAxis];
  }
  return chartData;
};

// Reasons for not completed exercises chart
export const getReasonsForNotComplExercData = createSelector(
  getSelectedPeriodOverview,
  getNotComplExerc,
  (selectedPeriod, data) => {
    const calcResult = calculateReasonsData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate data for skip stop Exercises chart
const calculateSkipStopData = (
  selectedPeriod: PeriodDropDownOptions | null,
  data: NotComplExercDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const dataPeriod: any = data
    ? data.find((el: NotComplExercDataType) => el.graph === periodKey)
    : [];
  const chartData = {
    graph: '',
    title: '',
    description: '',
    axis: '',
    values: null,
    yAxisDomain: [0, 10],
  };
  if (dataPeriod) {
    const dataValues: NotComplExercValuesType[] = dataPeriod.values;
    const values: any = [];
    let maxYAxis = 10;
    if (dataValues) {
      dataValues.map((period: NotComplExercValuesType) => {
        values.push(period.averageNumberOfReps);
        values.push(period.numberOfSkippedExercises);
        values.push(period.numberOfStoppedExercises);
        return null;
      });
      maxYAxis = Math.max(...values);
      if (maxYAxis % 10 !== 0) {
        let fraction = maxYAxis / 10;
        fraction = Math.ceil(fraction);
        maxYAxis = fraction * 10;
      }
    }
    chartData.graph = dataPeriod.graph;
    chartData.title = dataPeriod.title;
    chartData.description = dataPeriod.description;
    chartData.axis = dataPeriod.axisNumber;
    chartData.values = dataPeriod.values;
    chartData.yAxisDomain = [0, maxYAxis];
  }
  return chartData;
};

// Skip Stop Exercises for not completed chart
export const getSkipStopData = createSelector(
  getSelectedPeriodOverview,
  getNotComplExerc,
  (selectedPeriod, data) => {
    const calcResult = calculateSkipStopData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate date for Exercise Completion chart
const calculateExerciseCompletionData = (
  selectedPeriod: PeriodDropDownOptions | null,
  data: ExercComplDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const init = {
    graph: '',
    title: '',
    description: '',
    values: [],
    axis: '',
    averagePercent: '',
    yDomen: [0, 100],
  };
  const dataPeriod: any = data
    ? data.find((el: ExercComplDataType) => el.graph === periodKey)
    : init;

  if (dataPeriod && dataPeriod.values.length) {
    // let maxYAxis = 100;
    const dataValues = dataPeriod.values;
    if (dataValues) {
      const values: any = [];
      dataPeriod.values.map((period: ChartValuesType) => {
        values.push(period.value);
        return null;
      });

      // maxYAxis = Math.max(...values);
      // if (maxYAxis > 100) {
      //   maxYAxis = 100;
      // }

      // if (maxYAxis % 10 !== 0) {
      //   let fraction = maxYAxis / 10;
      //   fraction = Math.ceil(fraction);
      //   maxYAxis = fraction * 10;
      // }
    }
    const newData = {
      graph: dataPeriod.graph,
      title: dataPeriod.title,
      description: dataPeriod.description,
      axis: dataPeriod.axis,
      averagePercent: dataPeriod.averagePercent,
      values: dataPeriod.values,
      // yDomen: [0, maxYAxis || 100],
      yDomen: [0, 100],
    };
    return newData;
  }
  return dataPeriod;
};

// Exercise completion chart
export const getExerciseCompletionData = createSelector(
  getSelectedPeriodOverview,
  getExercComplData,
  (selectedPeriod, data) => {
    const calcResult = calculateExerciseCompletionData(selectedPeriod, data);
    return calcResult;
  },
);

// Calculate date for Individual Exercises Summary chart
const calculateExercisesSummaryData = (
  selectedPeriod: PeriodDropDownOptions | null,
  exercisesRawData: ExercPerPeriodType[],
  exercComplData: ExercComplDataType[],
) => {
  const periodKey = selectedPeriod?.value || PERIOD_FOR_CHART.W;

  const newExercises: any = [];
  if (exercisesRawData && exercisesRawData.length > 0) {
    exercisesRawData.forEach((exercise: ExercPerPeriodType) => {
      const dataForCalculation: any = [];
      const { exerciseDone } = exercise;
      if (exerciseDone) {
        const exerciseDoneParsed = JSON.parse(exerciseDone);
        exerciseDoneParsed.forEach((item: any) => {
          const date = Object.keys(item)[0];
          const data = item[date];
          dataForCalculation.push({
            date,
            exerciseCompleted: data?.exerciseCompleted || 0,
            exerciseSkipped: data?.exerciseSkipped || 0,
            exerciseStopped: data?.exerciseStopped || 0,
          });
        });
        const completed = calculateForCount(
          dataForCalculation,
          chartItemsCount[periodKey],
          'exerciseCompleted',
        )
          .slice()
          .reverse();
        const skipped = calculateForCount(
          dataForCalculation,
          chartItemsCount[periodKey],
          'exerciseSkipped',
        )
          .slice()
          .reverse();
        const stopped = calculateForCount(
          dataForCalculation,
          chartItemsCount[periodKey],
          'exerciseStopped',
        )
          .slice()
          .reverse();

        const totalCells = exercise.timesPerDay * completed.length;
        const avarage = Math.round(
          (completed.reduce((acc: any, value: any) => acc + value, 0) / totalCells) * 100,
        );
        newExercises.push({
          id: exercise.id,
          name: exercise.name,
          timesPerDay: exercise.timesPerDay,
          completed,
          skipped,
          stopped,
          avarage,
        });
      }
    });

    // Labels
    const periodsLabel: string[] = [];
    if (exercComplData) {
      const dataComplPeriod: any = exercComplData.find(
        (el: ExercComplDataType) => el.graph === periodKey,
      );
      const valuesLength = dataComplPeriod?.values?.length;
      const currentPeriodNumber = chartItemsCount[periodKey];
      if (valuesLength && valuesLength === currentPeriodNumber) {
        dataComplPeriod.values.map((item: ChartValuesType) => {
          periodsLabel.push(item.name);
          return null;
        });
      }
    }
    const result = {
      exercises: newExercises,
      chartLabels: periodsLabel?.length ? periodsLabel.reverse() : [],
    };
    return result;
  }
  return null;
};

// Overview - Exercises Per Period (Individual Exercise summary charts)
export const getExercisesPerData = createSelector(
  getSelectedPeriodOverview,
  getExercPerPeriodData,
  getExercComplData,
  (selectedPeriod, exercisesRawData, exercComplData) => {
    const calcResult = calculateExercisesSummaryData(
      selectedPeriod,
      exercisesRawData,
      exercComplData,
    );
    return calcResult;
  },
);

// Calculate sleep date for Sleep chart
// const calculateSleepData = (
//   selectedPeriod: PeriodDropDownOptions | null,
//   sleepRawData: SleepChartDataType[],
// ) => {
//   const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
//   const defaultObj = {
//     title: '',
//     description: '',
//     values: [],
//     axis: '',
//   };
//   const dataPeriod: any = sleepRawData
//     ? sleepRawData.find((el: SleepChartDataType) => el.graph === periodKey)
//     : defaultObj;
//   const startDatesInMinutes: number[] = [];
//   const endDatesInMinutes: number[] = [];

//   const convertDateToMinutes = (timeInMinutes: number, type: 'start' | 'end'): number => {
//     let hours: number | string = Math.trunc(timeInMinutes / 60);
//     const minutes: number | string = timeInMinutes - hours * 60;
//     if (hours >= 12) {
//       hours -= 24;
//     }
//     const result = minutes + hours * 60;
//     if (type === 'start') {
//       startDatesInMinutes.push(result);
//     }
//     if (type === 'end') {
//       endDatesInMinutes.push(result);
//     }
//     return result;
//   };

//   if (dataPeriod && dataPeriod.values.length > 0) {
//     const newValues: any = [];
//     dataPeriod.values.map((period: SleepChartValuesType) => {
//       const dateStart = period.startTimeInMinutes;
//       const dateEnd = period.endTimeInMinutes;

//       const dateStartConverted = dateStart ? convertDateToMinutes(dateStart, 'start') : 0;
//       const dateEndConverted = dateEnd ? convertDateToMinutes(dateEnd, 'end') : 0;

//       const res = {
//         ...period,
//         bar: [dateStartConverted, dateEndConverted],
//       };
//       newValues.push(res);
//       return null;
//     });

//     const newData = {
//       title: dataPeriod.title,
//       description: dataPeriod.description,
//       values: newValues,
//       axis: dataPeriod.axis,
//       yDomen: [0, 0],
//     };
//     if (startDatesInMinutes.length && endDatesInMinutes.length) {
//       const minYAxis = Math.min(...startDatesInMinutes);
//       const minHoursSign = Math.sign(minYAxis);
//       const minYAxisHours = Math.floor(Math.abs(minYAxis / 60));
//       const minYDomen = (minYAxisHours * minHoursSign - 1) * 60;

//       const maxYAxis = Math.max(...endDatesInMinutes);
//       const maxHoursSign = Math.sign(maxYAxis);
//       const maxYAxisHours = Math.floor(Math.abs(maxYAxis / 60));
//       const maxYDomen = (maxYAxisHours * maxHoursSign + 1) * 60;
//       newData.yDomen = [minYDomen, maxYDomen];
//     }
//     console.log('777_SEL_DATA=', newData);

//     return newData;
//   }
//   return dataPeriod;
// };

// Calculate sleep date for Sleep chart
const calculateSleepData = (
  selectedPeriod: PeriodDropDownOptions | null,
  sleepRawData: SleepChartDataType[],
) => {
  const periodKey = selectedPeriod ? selectedPeriod.value : PERIOD_FOR_CHART.W;
  const defaultObj = {
    title: '',
    description: '',
    values: [],
    axis: '',
    graph: '',
  };
  const dataPeriod: any = sleepRawData
    ? sleepRawData.find((el: SleepChartDataType) => el.graph === periodKey)
    : defaultObj;
  const startDatesInMinutes: number[] = [];
  const endDatesInMinutes: number[] = [];

  const convertDateToMinutes = (timeInMinutes: number, type: 'start' | 'end'): number => {
    let normalizeMinutes = timeInMinutes;
    if (timeInMinutes >= 720) {
      normalizeMinutes -= 1440;
    }
    if (type === 'start') {
      startDatesInMinutes.push(normalizeMinutes);
    }
    if (type === 'end') {
      endDatesInMinutes.push(normalizeMinutes);
    }
    return normalizeMinutes;
  };

  if (dataPeriod && dataPeriod.values.length > 0) {
    const newValues: any = [];
    dataPeriod.values.map((period: SleepChartValuesType) => {
      const dateStart = period.startTimeInMinutes;
      const dateEnd = period.endTimeInMinutes;

      const dateStartConverted = dateStart ? convertDateToMinutes(dateStart, 'start') : 0;
      const dateEndConverted = dateEnd ? convertDateToMinutes(dateEnd, 'end') : 0;

      const res = {
        ...period,
        bar: [dateStartConverted, dateEndConverted],
      };
      newValues.push(res);
      return null;
    });

    const newData = {
      title: dataPeriod.title,
      graph: dataPeriod.graph,
      description: dataPeriod.description,
      values: newValues,
      axis: dataPeriod.axis,
      yDomen: [0, 0],
    };
    if (startDatesInMinutes.length && endDatesInMinutes.length) {
      const minYAxis = Math.min(...startDatesInMinutes);
      const minHoursSign = Math.sign(minYAxis);
      const minYAxisHours = Math.floor(Math.abs(minYAxis / 60));
      const minYDomen = (minYAxisHours * minHoursSign - 1) * 60;

      const maxYAxis = Math.max(...endDatesInMinutes);
      const maxHoursSign = Math.sign(maxYAxis);
      const maxYAxisHours = Math.floor(Math.abs(maxYAxis / 60));
      const maxYDomen = (maxYAxisHours * maxHoursSign + 1) * 60;
      newData.yDomen = [minYDomen, maxYDomen];
    }
    return newData;
  }
  return dataPeriod;
};

// Sleep chart
export const getSleepData = createSelector(
  getSelectedPeriodOverview,
  getPatientSleepData,
  (selectedPeriod, data) => {
    const calcResult = calculateSleepData(selectedPeriod, data);
    return calcResult;
  },
);

// Remote monitoring page
export const getRemoteMonitoringData = createSelector(
  getSelectedPerRemoteMonit,
  getExercPerPeriodData,
  getExercComplData,
  getNotComplExerc,
  getPainLevelData,
  getMotivation,
  getPatientsSteps,
  getPatientSleepData,
  getPatientHeartRateData,
  (
    selectedPeriod,
    exercisesSummaryData,
    exerciseComplData,
    notComplExercData,
    painLevelRawData,
    motivationRawData,
    stepsRawData,
    sleepRawData,
    heartRateRawData,
  ) => ({
    exerciseSummary: calculateExercisesSummaryData(
      selectedPeriod,
      exercisesSummaryData,
      exerciseComplData,
    ),
    exerciseCompletionData: calculateExerciseCompletionData(selectedPeriod, exerciseComplData),
    reasonsNotComletionData: calculateReasonsData(selectedPeriod, notComplExercData),
    skipStopExercData: calculateSkipStopData(selectedPeriod, notComplExercData),
    painLevelData: calculateChartData(selectedPeriod, painLevelRawData),
    motivationData: calculateChartData(selectedPeriod, motivationRawData),
    stepsData: calculateChartData(selectedPeriod, stepsRawData),
    sleepData: calculateSleepData(selectedPeriod, sleepRawData),
    heartRateData: calculateChartData(selectedPeriod, heartRateRawData),
  }),
);
