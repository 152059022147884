import React, { Component, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { modalElement } from '../../utils/helper';
import styles from './styles.module.css';

interface ModalPropsType {
  children: any;
  style?: any;
  onClose: (user?: any) => void;
}

export class Modal extends Component<ModalPropsType> {
  componentDidMount(): void {
    window.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (e: KeyboardEvent): void => {
    if (e.code === 'Escape') {
      const { onClose } = this.props;
      onClose();
    }
  };

  render(): ReactNode {
    const { children, style } = this.props;
    return createPortal(
      <div className={styles.backDrop} role="presentation">
        <div className={style || styles.modal}>{children}</div>
      </div>,
      modalElement,
    );
  }
}

export default Modal;
