import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import cx from 'classnames';
import moment from 'moment';
import * as html2pdf from 'html2pdf.js';

import styles from './styles.module.css';
import stylePage from '../../../pages/styles.module.css';
import { Loading, MainTitle } from '../../../common';
import { getCurrenLang } from '../../../redux/selector';
import { FilterByFocus } from '../../Filter/ExerciseFilter';
import { getOwnerAction, initialPage } from '../../../utils/helper';
import { PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE } from '../../../utils/variables';
import useGetFocuses from '../../../hooks/useGetFocuses';
import useCheckRole from '../../../hooks/useCheckRole';
import SearchClient from '../../Search/SearchClient';
import ExercisePrescriptionsTableHeader from './ExercisePrescriptionsTableHeader';
import useDebounce from '../../../hooks/useDebounce';
import { ExercisePrescriptionsProps, PatientExercisesHistoryList } from './types';
import { useGetPatientExerciseHistory } from '../../../graphql/history';
import { TSortdirectionProps } from '../../../utils/model';
import Prescription from './Prescription';
import { CsvIcon, PdfIcon } from '../../../theme/icons';
import { downloadFile } from '../../../utils/helper_charts';
import { RegFindAllCommas } from '../../../utils/regx';

const ExercisePrescriptions = ({
  patientId,
  isInvitee,
  patient,
}: ExercisePrescriptionsProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const search_exercise = t?.dashboard.hcp.profile_patient.search_exercise;
  const no_data = t?.common.no_data;
  const exercise_history = t?.dashboard.hcp.profile_patient.exercise_history;
  const export_to_pdf = t?.common.export_to_pdf;
  const export_to_csv = t?.common.export_to_csv;
  const exercise_prescription_history_report =
    t?.dashboard.hcp.profile_patient.exercise_prescription_history_report;
  const last_action_date = t?.dashboard.hcp.profile_patient.last_action_date;
  const action_taken = t?.dashboard.hcp.profile_patient.action_taken;
  const exercise_name = t?.dashboard.hcp.profile_patient.video_bank.exercise_name;
  const status_t = t?.dashboard.hcp.table.status;
  const change_by_hcp = t?.dashboard.hcp.profile_patient.video_bank.change_by_hcp;
  const change_by_patient = t?.dashboard.hcp.profile_patient.video_bank.change_by_patient;

  const searchQueryInit = {
    searchField: isInvitee ? 'invitee_exercise.name' : 'exercise.name',
    searchValue: '',
  };

  // Queris
  const {
    _getPatientExercisesHistory,
    loading,
    error,
    patientExercisesHistory,
    totalElements,
  } = useGetPatientExerciseHistory(isInvitee);

  const { focusType1Arr, exercisesProcedureFocus } = useGetFocuses();

  // Local state
  const [exerciseHistoryList, setExercisesHistoryList] = useState<PatientExercisesHistoryList[]>();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(15);
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState<any>({ ...searchQueryInit });
  const [sortBy, setSortby] = useState<string>('name');
  const [sortDirectionName, setSortDirectionName] = useState<string>('');
  const [focusFilter, setFocusFilter] = useState<number[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchByDate, setSearchByDate] = useState<string>('');
  const [hideDatePicker, setHideDatePicker] = useState<boolean>(false);
  const [isDatePickerSearch, setDatePickerSearch] = useState<boolean>(false);
  const [queryPicker, setQueryPicker] = useState(new Date());
  const [hideMenuByHistoryStatus, setHideMenuByHistoryStatus] = useState<boolean>(false);
  const [filteringHistoryStatus, setFilteringHistoryStatus] = useState<string>('');
  const [isFilterStatus, setIsFilterStatus] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState(false);

  // If an error occurred while loading the list, display a message
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // Fill totalElements for Pagination for connected patient
  useEffect(() => {
    if (!loading) {
      setTotalItems(totalElements);
    }
  }, [totalElements]);

  // Request to DB
  const getListPrescritions = (
    page: number,
    itemsPerPage: number,
    sortName: string,
    sortdirection: string,
    searchByName: string,
    filterFocus: number[],
    searchByActionDate: string,
    searchByStatus: string,
  ): void => {
    const listProps: TSortdirectionProps = {
      page,
      itemsPerPage,
    };
    const searchProps: any = [];
    if (searchByName) {
      searchProps.push(searchByName);
    }
    if (searchByActionDate) {
      searchProps.push({
        searchField: isInvitee
          ? 'invitee_exercise_history.updateDate'
          : 'exercise_history.updateDate',
        searchValue: searchByActionDate,
      });
    }
    if (searchByStatus) {
      searchProps.push({
        searchField: isInvitee
          ? 'invitee_exercise_history.roleWhoChanged'
          : 'exercise_history.roleWhoChanged',
        searchValue: searchByStatus,
      });
    }
    if (sortdirection) {
      listProps.sortBy = sortName;
      listProps.sortDirection = sortdirection;
    }

    _getPatientExercisesHistory({
      variables: {
        patientId,
        listProps,
        filterFocus,
        searchProps,
        searchWithAllMatches: true,
      },
    });
  };

  // Fill exercise prescription history report for connected patient
  useEffect(() => {
    if (!loading && !error) {
      setExercisesHistoryList(patientExercisesHistory);
    }
  }, [patientExercisesHistory]);

  // Get exercise list
  useEffect(() => {
    if (patientId) {
      getListPrescritions(
        currentPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        focusFilter,
        searchByDate,
        filteringHistoryStatus,
      );
    }
  }, [patientId]);

  const debounceValue = useDebounce(search, 300);

  // Get search result from DB after delay
  useEffect(() => {
    if (debounceValue.length >= 2) {
      setSearchQuery({
        searchField: isInvitee ? 'invitee_exercise.name' : 'exercise.name',
        searchValue: debounceValue,
      });
    } else {
      setSearchQuery({ ...searchQueryInit });
    }
  }, [debounceValue]);

  // Search exercise
  const changeSearch = ({ target: { value } }: any): void => {
    setSearch(value);
  };

  // Get data after search
  useEffect(() => {
    if (patientId) {
      setCurrentPage(initialPage);
      getListPrescritions(
        currentPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        focusFilter,
        searchByDate,
        filteringHistoryStatus,
      );
    }
  }, [searchQuery, focusFilter]);

  // Sort exercise list by name
  const sortByColumnName = (name: string, sortdirection: string): void => {
    setSortby(name);
    setSortDirectionName(sortdirection);
    getListPrescritions(
      currentPage,
      pageSize,
      name,
      sortdirection,
      searchQuery,
      focusFilter,
      searchByDate,
      filteringHistoryStatus,
    );
  };

  // Search by last action date
  const handleSearchByLastActionDate = (val: string): void => {
    const dateValue = moment(val).format('YYYY-MM-DD');
    setSearchByDate(dateValue);
    setHideDatePicker(!hideDatePicker);
    if (val) {
      setCurrentPage(initialPage);
      getListPrescritions(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        focusFilter,
        dateValue,
        filteringHistoryStatus,
      );
      setDatePickerSearch(() => true);
    }
  };

  // Reset search by last action date
  const handleResetLastActiondate = (): void => {
    setSearchByDate('');
    setQueryPicker(new Date());
    setHideDatePicker(!hideDatePicker);
    if (searchByDate) {
      setCurrentPage(initialPage);
      getListPrescritions(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        focusFilter,
        '',
        filteringHistoryStatus,
      );
      setDatePickerSearch(() => false);
    }
  };

  // Search by history status
  const getFilteredData = (): void => {
    setHideMenuByHistoryStatus(!hideMenuByHistoryStatus);
    if (filteringHistoryStatus) {
      setCurrentPage(initialPage);
      getListPrescritions(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        focusFilter,
        searchByDate,
        filteringHistoryStatus,
      );
      setIsFilterStatus(true);
    }
  };

  // Reset search by history status
  const resetFilter = (): void => {
    setFilteringHistoryStatus('');
    setHideMenuByHistoryStatus(!hideMenuByHistoryStatus);
    if (filteringHistoryStatus) {
      setCurrentPage(initialPage);
      getListPrescritions(
        initialPage,
        pageSize,
        sortBy,
        sortDirectionName,
        searchQuery,
        focusFilter,
        searchByDate,
        '',
      );
    }
    setIsFilterStatus(false);
  };

  // Go to the next page
  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    getListPrescritions(
      page,
      itemsPerPage,
      sortBy,
      sortDirectionName,
      searchQuery,
      focusFilter,
      searchByDate,
      filteringHistoryStatus,
    );
  };

  const isDesableIcon = fileLoading || loading || !exerciseHistoryList?.length;
  const patientName = useMemo(() => {
    if (patient) {
      if (patient.firstName || patient.lastName) {
        return `${patient.firstName || ''}_${patient.lastName || ''}`;
      }
      return `${patient.email}`;
    }
    return '';
  }, [patient]);

  // Create PDF image and download PDF file
  const downloadPDFHandler = async (): Promise<void> => {
    if (!isDesableIcon) {
      const element = document.getElementById('history-to-print');
      setFileLoading(true);
      const opt = {
        filename: `${patientName}_${moment().format('D.M.YYYY-HH:mm:ss')}.pdf`,
        image: { type: 'jpeg', quality: 0.9 },
        margin: 5,
        pagebreak: { mode: ['css', 'legacy'], avoid: ['section'] },
      };
      await html2pdf().from(element).set(opt).save();
      setFileLoading(false);
    }
  };

  // Convert Data to CSV file
  const convertDataToCSV = (): string => {
    if (exerciseHistoryList?.length) {
      let csvContent = `data:text/csv;charset=utf-8,Patient:,${patientName},\r\n`;
      exerciseHistoryList.map((item: PatientExercisesHistoryList) => {
        csvContent += `${exercise_name}:,${item.exerciseName},\r\n`;
        csvContent += `${last_action_date}:,${item.dateOfLastAction},\r\n`;
        csvContent += `${action_taken}:,${item.actionTaken.replace(RegFindAllCommas, ' ')},\r\n`;
        csvContent += `${status_t}:,${getOwnerAction(
          item.roleWhoChanged,
          change_by_hcp,
          change_by_patient,
        )},\r\n`;
        return null;
      });
      return csvContent;
    }
    return '';
  };

  // Download CSV file
  const downloadCSVHandler = (): void => {
    if (!isDesableIcon) {
      setFileLoading(true);
      const csvFile = convertDataToCSV();
      const patientNorm = {
        firstName: patient?.firstName || patient?.email || '',
        lastName: patient?.lastName,
      };
      downloadFile(csvFile, patientNorm, exercise_prescription_history_report, 'csv');
      setFileLoading(false);
    }
  };

  // JSX
  const loadingJSX = loading && <Loading />;
  const noData = exerciseHistoryList?.length === 0 && !loading && (
    <div className={stylePage.noData}>{no_data}</div>
  );

  return (
    <div className={styles.prescrebed__container}>
      {loadingJSX}
      <MainTitle title={exercise_history} />
      <div>
        <div className={styles['prescrebed__icon-container']}>
          <Tooltip mouseEnterDelay={0.3} placement="top" title={export_to_pdf}>
            <div
              className={cx({
                [styles['icon-pdf']]: true,
                [styles.disabled]: isDesableIcon,
                // 'pdf-icon': true,
              })}
              onClick={downloadPDFHandler}
              role="presentation"
            >
              <img src={PdfIcon} alt="PDF" />
            </div>
          </Tooltip>
          <Tooltip mouseEnterDelay={0.3} placement="bottom" title={export_to_csv}>
            <div
              className={cx({
                [styles['icon-pdf']]: true,
                [styles.disabled]: isDesableIcon,
                // 'pdf-icon': true,
              })}
              onClick={downloadCSVHandler}
              role="presentation"
            >
              <img src={CsvIcon} alt="CSV" />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className={styles['prescrebed__search-wrapper']}>
        <SearchClient
          inputId="searchExercise"
          name="searchExercise"
          placeholder={search_exercise}
          inputValue={search}
          changeFilter={changeSearch}
        />
        <FilterByFocus
          focusProcedureTypeArr={focusType1Arr}
          focusFilter={focusFilter}
          setFocusFilter={setFocusFilter}
          exercisesProcedureFocus={exercisesProcedureFocus}
        />
      </div>

      {/* Header */}
      <ExercisePrescriptionsTableHeader
        t={t}
        sortByColumnName={sortByColumnName}
        sortBy={sortBy}
        isInvitee={isInvitee}
        handleSearchByLastActionDate={handleSearchByLastActionDate}
        handleResetLastActiondate={handleResetLastActiondate}
        hideDatePicker={hideDatePicker}
        setHideDatePicker={setHideDatePicker}
        queryPicker={queryPicker}
        setQueryPicker={setQueryPicker}
        isDatePickerSearch={isDatePickerSearch}
        hideMenuByHistoryStatus={hideMenuByHistoryStatus}
        setHideMenuByHistoryStatus={setHideMenuByHistoryStatus}
        isFilterStatus={isFilterStatus}
        setFilteringHistoryStatus={setFilteringHistoryStatus}
        filteringHistoryStatus={filteringHistoryStatus}
        getFilteredData={getFilteredData}
        resetFilter={resetFilter}
      />
      {noData}

      <div id="history-to-print">
        {exerciseHistoryList &&
          exerciseHistoryList.map(
            (prescriptionItem: PatientExercisesHistoryList, index: number) => (
              <section key={`${String(index)}${prescriptionItem.exerciseName}`}>
                <Prescription data={prescriptionItem} />
              </section>
            ),
          )}
      </div>
      {!noData && (
        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE}
            showSizeChanger
          />
        </div>
      )}
    </div>
  );
};

export default ExercisePrescriptions;
