import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import { Button, Loading, Modal } from '../../../../../common';
import { getCurrenLang } from '../../../../../redux/selector';
import { CheckIcon } from '../../../../../theme/icons';
import { InputFloatLabel, TextareaFeildFloatLabel } from '../../../../../common/Input';
import { getSelectOptions } from '../../../../../utils/helper';
import {
  validationAddExercToExistFolder,
  validationAddExercToNewFolder,
  validationScheduleFolder,
} from '../../../../../utils/validators';
import {
  useAddExercisesToFolder,
  useGetAllFolderNames,
  useSaveFolder,
} from '../../../../../graphql/videoBank';
import { getScheduleNumberOptions, getUnitOptions } from '../../../../../utils/share/options';
import ScheduleFolder from '../ScheduleFolder';
import { CloseBtn } from '../../../../../common/Button/Button';
import { SelectFloatLabel } from '../../../../../common/Input/Select';

const CreateFolder = ({
  setExerciseFolderDetails,
  selectExercise,
  setSelectExercise,
  updateList,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const cancel = t?.common.cancel;
  const save = t?.common.save;
  const ok = t?.common.ok;
  const folder_details = t?.hcp.org_video_bank?.folder_details;
  const exerc_folder_name_label = t?.dashboard.hcp.profile_patient.exerc_folder_name_label;
  const exerc_folder_descr_label = t?.dashboard.hcp.profile_patient.exerc_folder_descr_label;
  const add_to_exist_folder = t?.dashboard.hcp.profile_patient.add_to_exist_folder;
  const create_new_folder = t?.dashboard.hcp.profile_patient.create_new_folder;
  const exercises_added = t?.dashboard.hcp.profile_patient.exercises_added;

  const unitOptions = t ? getUnitOptions(t.dashboard.hcp.profile_patient.completion_and_steps) : [];
  const numberOptions = getScheduleNumberOptions();

  // Local state
  const [isNewFolder, setNewFolder] = useState(true);

  // Endpoints
  const { _saveFolder, errorSaveFolder, loadingSaveFolder, savedFolder } = useSaveFolder();
  const {
    _addExerciseToFolder,
    errorUpdateFolder,
    loadingUpdateFolder,
    updatedFolder,
  } = useAddExercisesToFolder();
  const { allFoldersList } = useGetAllFolderNames();

  const initialValues = {
    name: '',
    description: '',
    existFolder: null,
    amount: null,
    timePeriod: null,
  };
  const validationSchema = isNewFolder
    ? validationAddExercToNewFolder(t)
    : validationAddExercToExistFolder(t);

  const formik = useFormik({
    initialValues,
    validate: (values) => validationScheduleFolder(values, t),
    validationSchema: () => validationSchema,
    onSubmit: (values: any) => {
      if (isNewFolder) {
        _saveFolder({
          variables: {
            hcpFolderData: {
              name: values.name,
              description: values.description,
              amount: values.amount?.value || null,
              timePeriod: values.timePeriod?.value || null,
            },
            videobankIds: selectExercise,
          },
        });
        return;
      }

      // Add exercises to exist folder
      _addExerciseToFolder({
        variables: {
          hcpFolderId: values.existFolder.value,
          exerciseIds: selectExercise,
        },
      });
    },
  });

  const { values, errors, touched } = formik;
  const options = useMemo(() => getSelectOptions(allFoldersList), [allFoldersList]);

  // If there is an error when saving, we show the message
  useEffect(() => {
    if (errorSaveFolder) {
      toast.error(errorSaveFolder);
      return;
    }
    if (errorUpdateFolder) {
      toast.error(errorUpdateFolder);
    }
  }, [errorSaveFolder, errorUpdateFolder]);

  // Clouse popup (Exercise Folder Deteils)
  const clouseExerciseFolderDetails = (): void => {
    setExerciseFolderDetails(() => false);
    if (savedFolder || updatedFolder) {
      setSelectExercise([]);
      updateList();
    }
  };

  // JSX
  const loadingJSX = (loadingSaveFolder || loadingUpdateFolder) && <Loading />;

  return (
    <Modal onClose={clouseExerciseFolderDetails} style={styles['team__modal--folder-details']}>
      <CloseBtn close={clouseExerciseFolderDetails} />
      {((!savedFolder && !updatedFolder) || errorSaveFolder || errorUpdateFolder) && (
        <form onSubmit={formik.handleSubmit}>
          {loadingJSX}
          <div className={styles['folder-details__title']}>{folder_details}</div>

          {isNewFolder ? (
            <>
              <InputFloatLabel
                inputId="ExFolderInput"
                inputName="name"
                inputType="text"
                placeholder={exerc_folder_name_label}
                hasErrors={errors.name}
                isTouched={touched.name}
                inputValue={values.name}
                onChangeMethod={formik.handleChange}
              />

              <TextareaFeildFloatLabel
                id="folderDescription"
                inputName="description"
                placeholder={exerc_folder_descr_label}
                // placeholder={exerc_folder_descr_placeholder}
                inputValue={values.description}
                hasErrors={errors.description}
                isTouched={touched.description}
                onChecngeMethod={formik.handleChange}
                rows={2}
              />
              <ScheduleFolder
                t={t}
                formik={formik}
                unitOptions={unitOptions}
                numberOptions={numberOptions}
              />
            </>
          ) : (
            <SelectFloatLabel
              name="existFolder"
              // placeholder={select_t}
              placeholder={add_to_exist_folder}
              onChange={formik.setFieldValue}
              options={options}
              hasErrors={errors.existFolder}
              isTouched={touched.existFolder}
              selected={values.existFolder}
              inputValue={values.existFolder}
              type="exercise folder details"
              inputId="existFolder"
            />
          )}
          <div
            onClick={(): void => setNewFolder(!isNewFolder)}
            className={styles['team__add-to-exist-folder']}
            aria-hidden
          >
            {isNewFolder ? add_to_exist_folder : create_new_folder}
          </div>

          <div className={styles['folder-details__btn-container']}>
            <Button
              buttonType="submit"
              buttonName={save}
              buttonClass={styles['folder-details__btn']}
              disabledButton={!!loadingJSX || !formik.dirty}
            />
            <Button
              buttonType="button"
              buttonName={cancel}
              buttonMethod={(): void => setExerciseFolderDetails(() => false)}
              buttonClass={styles['folder-details__btn']}
              disabledButton={!!loadingJSX}
            />
          </div>
        </form>
      )}
      {((savedFolder && !errorSaveFolder) || (updatedFolder && !errorUpdateFolder)) && (
        <div>
          <div className={styles.icon}>
            <CheckIcon />
          </div>
          <div className={styles.contant}>{exercises_added}</div>
          <div className={styles.team__row}>
            <Button
              buttonType="button"
              buttonName={ok}
              buttonMethod={clouseExerciseFolderDetails}
              buttonClass={styles['folder-details__btn']}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CreateFolder;
