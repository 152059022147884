import React, { useState, useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

import styles from './styles.module.css';
import {
  GetState,
  getCurrenLang,
  getHcpName,
  getMessengerState,
  getPatientsInvitationData,
  getPatientsInvitationLoading,
  getPatientsInvitationTotal,
} from '../../redux/selector';
import { Button, ConfirmatioModal, Modal, WarningNotification } from '../../common';
import {
  useGetSharingNotification,
  useSetHcpInformedAboutSharing,
  useSharingTeamVideobank,
} from '../../graphql/hcp';
import { useQueryUserName, useQueryAdminCountry, useQueryUserPic } from '../../graphql/hcpProfile';
import { usePatientsPendingInvitations } from '../../graphql/patients';
import useCheckRole from '../../hooks/useCheckRole';
import { storage } from '../../utils';
import { CloseIcon } from '../../theme/icons';
import { NotificationSucceess } from '../../common/NotificationModal';
import PandingPatientInvitations from '../patientInvitations/patientInvitations';
import Feedback from '../Feedback';
import { useGetKeysOfProcedures, useGetProcedures } from '../../graphql/procedures';
import { useQueryExerciseCategories } from '../../graphql/videoBank';
import { CloseBtn } from '../../common/Button/Button';
import useCheckLocale from '../../hooks/useCheckLocale';
import { useGetHcpNotificationList } from '../../graphql/notifications';
import useChat from '../../hooks/useChat';
import { setisChatAvailable } from '../../redux/common';
import {
  addNewMessage,
  clearAddMembersState,
  removeMessage,
  setActiveRoomId,
  setAddNewChannelModal,
  setMessages,
  setMessengerError,
  setOpenAddMembersModal,
  setRoomMembers,
  setRooms,
  updateMessage,
} from '../../redux/messenger';
// import socket from '../../socket';
import { Message } from '../../pages/Messenger/types';
import { reconnectionDelay } from '../../utils/variables';
import { MessengerState } from '../../redux/messenger/types';
import { useSocket } from '../../ SocketContext';

const PrivateCommonComponent = (): ReactElement => {
  // Redux state
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const hcp = useSelector((state: GetState) => getHcpName(state));
  const patientsInvitations = useSelector((state: GetState) => getPatientsInvitationData(state));
  const total = useSelector((state: GetState) => getPatientsInvitationTotal(state));
  const listLoading = useSelector((state: GetState) => getPatientsInvitationLoading(state));
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { error: messengerError } = messengerState;

  // Local state
  const [isModalOpen, setModal] = useState(false);
  const [isShareWarning, setWarning] = useState(false);
  const [isSharedSuccess, setSharedSuccess] = useState(false);
  const [isModalShareVideo, setModalShareVideo] = useState(false);

  // Variables
  const { isAdmin, isHcp, token } = useCheckRole();
  const showModalDate = storage.get('show-modal-date');
  const today = moment().format('DD-MM-YYYY');
  const yes = t?.common.yes;
  const no = t?.common.no;
  const ok = t?.common.ok;
  const success = t?.profile_hcp.success;
  const video_successfully_shared = t?.hcp.manage.video_successfully_shared;
  const share = t?.common.share;
  const all_video_will_be_available = t?.hcp.manage.all_video_will_be_available;
  const { socket, initializeSocket }: any = useSocket();
  const { onConnect, onDisconnect } = useChat(socket);
  const { isChatAvailable } = hcp;
  const dispatch = useDispatch();

  // Socket connect/disconnect
  useEffect(() => {
    if (token) {
      initializeSocket(token);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [token]);

  // Endpoints
  // const {_getUserName, loading, hcp } = useQueryUserName(!isHcp);
  const { _getUserName, hcpPhotoUuid } = useQueryUserName(!hcp.recipients?.length);
  useQueryAdminCountry(!isAdmin);
  const {
    _getPatientInvitations,
    // patientsInvitations,
    // total,
    // listLoading,
  } = usePatientsPendingInvitations();
  const { _getSharingNotification, dataSharingNotif } = useGetSharingNotification();
  const {
    _setHcpInformedAboutSharing,
    setHcpInformedError,
    isSetHcpInformed,
  } = useSetHcpInformedAboutSharing();

  // Get and save to redux
  useGetKeysOfProcedures();
  useGetProcedures();
  const { _getExerciseCategoriesList } = useQueryExerciseCategories();

  // Share VideoBank to another HCP(Hospital)
  const { _shareVideobank, isSharingSuccess, shareError } = useSharingTeamVideobank();
  const { _getUserPic } = useQueryUserPic();

  // Get Hcp notifications
  const { _getHcpNotifications } = useGetHcpNotificationList();

  const locale = useCheckLocale();
  const { lng, lacaleArr } = locale;
  moment.locale(lng, lacaleArr);

  const { currentPage, searchQuery, itemsPerPage, notificationsStatus }: any = useSelector<any>(
    (state) => state.notifications,
  );

  useEffect(() => {
    _getExerciseCategoriesList();
    if (!isAdmin) {
      _getUserName();
      _getPatientInvitations({
        variables: {
          listProps: {
            page: 1,
            itemsPerPage: 5,
          },
          pending: true,
          searchByDate: [],
        },
      });
      _getHcpNotifications(notificationsStatus, currentPage, itemsPerPage, searchQuery);
    }
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      _getHcpNotifications(notificationsStatus, currentPage, itemsPerPage, searchQuery);
    }
  }, [notificationsStatus]);

  // Download and save to redux HCP (user) avatar
  useEffect(() => {
    if (!isAdmin && hcpPhotoUuid) {
      _getUserPic(hcpPhotoUuid);
    }
  }, [hcpPhotoUuid]);

  useEffect(() => {
    if (patientsInvitations && patientsInvitations.length > 0) {
      let isNewRequest = false;

      if (showModalDate.patientsIds) {
        patientsInvitations.map((patient: { id: number }) => {
          if (!showModalDate.patientsIds.includes(patient.id)) {
            isNewRequest = true;
          }
          return null;
        });
      }

      if (isNewRequest) {
        setModal(() => true);
        return;
      }

      if (showModalDate.today !== today) {
        setModal(() => true);
      }
    }
  }, [patientsInvitations]);

  const onclose = (): void => {
    const patientsIds = patientsInvitations.map((patient: { id: number }) => patient.id);
    storage.save('show-modal-date', {
      today,
      patientsIds,
    });
    setModal(() => false);
  };

  // If share error then show message.
  useEffect(() => {
    if (isSharingSuccess) {
      setWarning(() => false);
      setSharedSuccess(() => true);
      _setHcpInformedAboutSharing(hcp.recipients[0].id);
    }
  }, [isSharingSuccess]);

  // If share error then show message.
  useEffect(() => {
    if (shareError) {
      toast.error(shareError);
      _setHcpInformedAboutSharing(hcp.recipients[0].id);
    }
  }, [shareError]);

  // If success then update User params.
  useEffect(() => {
    if (isSetHcpInformed && !isAdmin) {
      _getUserName();
    }
  }, [isSetHcpInformed]);

  // If error show message.
  useEffect(() => {
    if (setHcpInformedError && !isAdmin) {
      toast.error(setHcpInformedError);
      _getUserName();
    }
  }, [setHcpInformedError]);

  // Check if there are any unread notifications about sharing TeamVideobank from another hospital.
  useEffect(() => {
    if (dataSharingNotif) {
      setModalShareVideo(() => true);
    }
  }, [dataSharingNotif]);

  // Check if there are any unread notifications about sharing TeamVideobank from another hospital.
  useEffect(() => {
    if (hcp?.recipients.length > 0) {
      _getSharingNotification({
        variables: {
          hcpId: hcp.recipients[0].initiatorId,
        },
      });
    }
  }, [hcp.recipients]);

  // Clouse shareModal and send to DB cancel
  const onCancelShare = (): void => {
    setModalShareVideo(() => false);
    setWarning(() => false);
    _setHcpInformedAboutSharing(hcp.recipients[0].id);
  };

  // Share team video bank to another organisation
  const showWarningToShareVideo = (): void => {
    setModalShareVideo(() => false);
    setWarning(() => true);
  };
  // Send request to DB
  const shareVideoBank = (): void => {
    _shareVideobank(hcp.recipients[0].initiatorId);
  };

  /* ************ Messenger **************** */
  useEffect(() => {
    if (messengerError) {
      toast.error(messengerError);
      dispatch(setMessengerError(''));
    }
  }, [messengerError]);

  // Connect or disconnect to messenger(chat).
  useEffect(() => {
    console.log('***hcp.isChatAvailable=', hcp.isChatAvailable);
    if (hcp.isChatAvailable) {
      onConnect();
      console.log('***PrivateCommonComponent_socket.onConnect');
    } else {
      onDisconnect();
    }

    // socket.connect();
    // Listeners
    if (socket) {
      console.log('***PrivateRoute_socket.on_useEffect');
      // Get rooms list
      socket.on('rooms', (_rooms: any) => {
        console.log('***useChat_socket.on_rooms=', _rooms);
        dispatch(setRooms(_rooms));
      });
      // Get rooms messages
      socket.on('roomMessages', (_messages: any) => {
        console.log('***useChat_roomMessages=', _messages);
        const recivedMsgs = _messages?.length > 0 ? _messages.reverse() : _messages;
        dispatch(setMessages(recivedMsgs));
      });
      socket.on('recieveMessage', (message: any) => {
        dispatch(addNewMessage(message));
        console.log('***useChat_recieveMessage_message=', message);
      });
      // Join Room
      // socket.on('joinedRoom', (roomId: number) => {
      //   dispatch(setActiveRoomId(roomId));
      //   console.log('***useChat_socket.on_joinedRoom=', roomId);
      // });
      // Created Room
      socket.on('createdRoom', (isCreated: boolean) => {
        dispatch(setAddNewChannelModal(''));
        dispatch(setOpenAddMembersModal(isCreated));
        console.log('***useChat_socket.on_createdRoom=', isCreated);
      });
      // Left Channel
      socket.on('leftChannel', (data: boolean) => {
        dispatch(setActiveRoomId(null));
        console.log('***useChat_leftChannel=', data);
      });
      // Left Channel
      socket.on('closedConversation', (data: boolean) => {
        dispatch(setActiveRoomId(null));
        console.log('***useChat_closedConversation=', data);
      });
      // Left Room
      // socket.on('leftRoom', (roomId: number) => {
      //   dispatch(setActiveRoomId(null));
      //   console.log('***useChat_leftRoom=', roomId);
      // });
      // RemovedRoom Room (for channel) or closed room for direct conversation
      socket.on('removedRoom', (roomId: number) => {
        dispatch(setActiveRoomId(null));
        console.log('***useChat_removedRoom=', roomId);
      });
      // Get roomMembers in current room
      socket.on('roomMembers', (members: any) => {
        dispatch(setRoomMembers(members));
        console.log('***useChat_roomMembers=', members);
      });
      // Response from beckend if added new member to channel
      // socket.on('memberAdded', (response: boolean) => {
      //   // dispatch(setAddNewMemberOpen(!response));
      //   dispatch(clearAddMembersState());
      //   console.log('***useChat_socket.on_memberAdded=', response);
      // });
      // Response from beckend if added new members to channel
      socket.on('membersAdded', (response: boolean) => {
        dispatch(clearAddMembersState());
        console.log('***useChat_socket.on_membersAdded=', response);
      });
      // Member removed from room
      socket.on('memberRemoved', (response: boolean) => {
        // dispatch(setAddNewMemberOpen(!response));
        console.log('***useChat_socket.on_memberRemoved=', response);
      });
      // Error from BE
      socket.on('throw_error', (msg: string) => {
        dispatch(setMessengerError(msg));
        console.log('***useChat_socket.on_throw_error=', msg);
      });
      // Remove message
      socket.on('removedMessage', (messageId: number) => {
        dispatch(removeMessage(messageId));
        console.log('***useChat_socket.on_removedMessage=', messageId);
      });
      // Updated message
      socket.on('updatedMessage', (message: Message) => {
        dispatch(updateMessage(message));
        console.log('***useChat_socket.on_updatedMessage=', message);
      });
      // On connect
      socket.on('connect', () => {
        dispatch(setisChatAvailable(true));
        console.log('***useChat_socket.on_connect=');

        /* ******************** INFO ************************ */
        const transport = socket.io.engine.transport.name; // in most cases, "polling"
        console.log('***transport_connect', transport);
        socket.io.engine.on('upgrade', () => {
          const upgradedTransport = socket.io.engine.transport.name; // in most cases, "websocket"
          console.log('***upgradedTransport_upgrade', upgradedTransport);
        });
      });
      // On disconnect
      socket.on('disconnect', (data: any) => {
        dispatch(setisChatAvailable(false));
        if (data !== 'io client disconnect') {
          // If error connection try reconnect
          setTimeout(() => {
            onConnect();
          }, reconnectionDelay);
        }
      });
      socket.on('connect_error', (error: any) => {
        dispatch(setisChatAvailable(false));
        console.log('***SOCKET_connect_error', error);
        // If error connection try reconnect
        setTimeout(() => {
          socket.connect();
        }, reconnectionDelay);
        if (socket.active) {
          console.log('***SOCKET_socket.active', socket.active);
          // temporary failure, the socket will automatically try to reconnect
        } else {
          // the connection was denied by the server
          // in that case, `socket.connect()` must be manually called in order to reconnect
          console.log('***SOCKET_error.message', error.message);
          console.log('***SOCKET_error.context', error.context);
          console.log('***SOCKET_error.code', error.code);
        }
      });
      // Remove all listeners when componentdidunmount
      return (): void => {
        socket.off('rooms');
        socket.off('roomMessages');
        socket.off('recieveMessage');
        socket.off('joinedRoom');
        socket.off('createdRoom');
        socket.off('leftRoom');
        socket.off('leftChannel');
        socket.off('closedConversation');
        socket.off('removedRoom');
        socket.off('roomMembers');
        // socket.off('memberAdded');
        socket.off('membersAdded');
        socket.off('memberRemoved');
        socket.off('throw_error');
        socket.off('removedMessage');
        socket.off('updatedMessage');
        socket.off('connect');
        socket.off('disconnect');
        socket.off('connect_error');
      };
    }
  }, [hcp?.isChatAvailable]);

  return (
    <>
      {/* Popup for pending patients */}
      {isModalOpen && !isModalShareVideo && !isShareWarning && !isSharedSuccess && (
        <Modal onClose={onclose} style={styles['pending-modal']}>
          <CloseBtn close={onclose} />
          <PandingPatientInvitations
            isModal
            _getPatientInvitations={_getPatientInvitations}
            patientsInvitations={patientsInvitations}
            total={total}
            listLoading={listLoading}
          />
        </Modal>
      )}

      {/* Popup if share team videobank anoter HCP */}
      {isModalShareVideo && !isModalOpen && !isSharedSuccess && (
        <Modal style={styles['share-modal']} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <div>
            <NotificationSucceess
              title={dataSharingNotif?.title || ''}
              contant={dataSharingNotif?.body || ''}
            />
            <div className={styles['share-modal__btn-wrapper']}>
              <Button
                buttonType="button"
                buttonName={dataSharingNotif.isTwoWaySharing ? ok : no}
                buttonMethod={onCancelShare}
              />
              {!dataSharingNotif.isTwoWaySharing && (
                <Button
                  buttonType="button"
                  buttonName={yes}
                  buttonMethod={showWarningToShareVideo}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
      {isShareWarning && (
        <Modal style={styles.modal} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <WarningNotification
            title=""
            contant={
              all_video_will_be_available?.replace(
                '<organisationName>',
                hcp.recipients?.[0]?.hcpInitiator?.hospital?.name || '',
              ) || ''
            }
            buttonName={share}
            onCancel={onCancelShare}
            onSubmit={shareVideoBank}
          />
        </Modal>
      )}
      {isSharedSuccess && (
        <Modal style={styles.modal__success} onClose={(): void => setSharedSuccess(() => false)}>
          <ConfirmatioModal
            title={success}
            contant={video_successfully_shared}
            buttonTitle={ok}
            buttonMethod={(): void => setSharedSuccess(() => false)}
          />
        </Modal>
      )}
      {isHcp ? <Feedback /> : <></>}
    </>
  );
};

export default PrivateCommonComponent;
